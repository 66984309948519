import { Grid } from "@mui/material";
import React from "react";
import TextInput from "../../../../common/TextInput";
import styles from "./styles.module.scss";
import { Field } from "formik";
import CountrySelect from "../../../../common/CountrySelect/CountrySelect";

export default function BackyCheckCommonForm({
  handleChange,
  values,
  errors,
  touched,
  data,
  isWWCForm,
}) {
  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Grid item md={6} xs={12}>
        <TextInput
          style={{ width: "100%" }}
          compolsory={true}
          customClass={styles.input}
          label="First Name"
          type="text"
          name="first_name"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="First Name"
          onChange={handleChange}
          value={values?.first_name}
        />
        <span>
          {errors.first_name && touched.first_name && errors.first_name}
        </span>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextInput
          style={{ width: "100%" }}
          customClass={styles.input}
          label="Middle Name"
          type="text"
          name="middle_name"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Middle Name"
          onChange={handleChange}
          value={values?.middle_name}
        />
        <span>
          {errors.middle_name && touched.middle_name && errors.middle_name}
        </span>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextInput
          style={{ width: "100%" }}
          compolsory={true}
          customClass={styles.input}
          label="Surname"
          type="text"
          name="last_name"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Surname"
          onChange={handleChange}
          value={values?.last_name}
        />
        <span>{errors.last_name && touched.last_name && errors.last_name}</span>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          compolsory={true}
          style={{ width: "100%" }}
          customClass={styles.input}
          label="Email"
          type="email"
          name="mail_id"
          placeholder={"Email"}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChange}
          value={values.mail_id}
        />
        <span>{errors.mail_id && touched.mail_id && errors.mail_id}</span>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          style={{minWidth: '96%'}}
          compolsory={true}
          label="Date of birth"
          type="date"
          name="dob"
          placeholder={"Date of birth"}
          max={new Date().toISOString().split("T")[0]}
          onChange={handleChange}
          value={values.dob}
          onKeyDown={handleKeyDown}
        />
        <span>{errors.dob && touched.dob && errors.dob}</span>
      </Grid>
      {(data?.backy_check_type?.toLowerCase() === "wwc" || isWWCForm) && (
        <>
          <Grid item xs={12}>
            <TextInput
              compolsory={true}
              label="Document Number"
              type="text"
              name="doc_id"
              placeholder={"Document Number"}
              onChange={handleChange}
              value={values.doc_id}
            />
            <span>{errors.doc_id && touched.doc_id && errors.doc_id}</span>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              style={{minWidth: '96%'}}
              compolsory={true}
              label="Card Expiry Date"
              type="date"
              name="expiry_date"
              placeholder={"Card Expiry Date"}
              onChange={handleChange}
              min={new Date().toISOString().split("T")[0]}
              value={values.expiry_date}
              onKeyDown={handleKeyDown}
            />
            <span>
              {errors.expiry_date && touched.expiry_date && errors.expiry_date}
            </span>
          </Grid>
          <Grid item xs={12}>
            {/* <TextInput
              compolsory={true}
              label="Card State Issue"
              type="text"
              name="state"
              placeholder={"Card State Issue"}
              onChange={handleChange}
              min={new Date().toISOString().split("T")[0]}
              value={values.state}
            /> */}
             <Field
              compolsory={true}
              customClass={styles.input}
              label="Card State Issue"
              name="state"
              component={CountrySelect}
              placeholder="Card State Issue"
              value={values.state}
              isStateList={true}
              style={{ marginTop: "19px", minHeight: "41px" }}
            />
            <span>{errors.state && touched.state && errors.state}</span>
          </Grid>
        </>
      )}
    </>
  );
}
