import React, { useState } from "react";

/****
 * checkbox input component in pdf editing page.
 */
function ReceiverCheckbox({ field }) {
  const [isFilled, setFilled] = useState(true);
  const [isRequired, setisRequired] = useState(field.isRequired);

  const handleCheckChange = (e) => {
    setFilled(false);

    if (!e.target.checked) {
      setFilled(true);
    }

    field.isChecked = e.target.checked;
    field.isFilled = true;
    if (field.isChecked === false) {
      field.isFilled = false;
    }
  };
  const changeRequiredStatus = (e) => {
    setisRequired(e.target.checked);
    field.isRequired = e.target.checked;
    field.isFilled = e.target.checked ? false : false;
  }

  return (
    <div
      style={{
        position: "absolute",
        left: field.positionFromParentLeft,
        top: field.positionFromParentTop,
        height: field.height,
        width: field.width,
        // border: "1px solid green",
      }}>
      {field.errorMessage && isFilled && !field.isCheckboxGroup && (
        <span
          style={{
            position: "absolute",
            top: -40,
            background: "red",
            borderRadius: 5,
            margin: 4,
            padding: 5,
            color: "white",
            display: "flex",
            gap: "0.5rem",
          }}>
          <span>Required</span>
          <span className="form-check">
            <input type="checkbox" className="form-check-input" checked={isRequired} onChange={changeRequiredStatus} />
          </span>
        </span>
      )}
      <input
        onChange={handleCheckChange}
        placeholder={field.placeholder}
        style={{
          width: "100%",
          height: "100%",
          fontSize: field.fontSize,
        }}
        type='checkbox'
      />
    </div>
  );
}

export default ReceiverCheckbox;
