import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  InputBase,
  Typography,
} from "@mui/material";
import { Modal } from "react-responsive-modal";
import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import "./style.css";
import Search from "../../../assets/icons/search.svg";
import Content_footer from "../content-footer/Content_footer";
import { useDispatch, useSelector } from "react-redux";
import { getHomeJobs } from "../../../store/actions/homeJobActions";
import {
  jobCateFunc,
  jobExpFunc,
  jobSalaryFunc,
  jobTypeFunc,
} from "../../../store/actions/recruiterJobsActions";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { FaFilter } from "react-icons/fa";

const Latest_job = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const homeJobs = useSelector((state) => state?.hjob);
  const reqJobs = useSelector((state) => state?.rjobs);
  const [showMore, setShowMore] = useState({
    cata: false,
    exp: false,
    salary: false,
    type: false,
  });
  const [openFilterModal, setOpenFilterModal] = useState(false);
  useEffect(() => {
    dispatch(getHomeJobs());
    dispatch(jobCateFunc());
    dispatch(jobExpFunc());
    dispatch(jobSalaryFunc());
    dispatch(jobTypeFunc());
  }, []);
  const [values, setValues] = useState({
    job_experience: [],
    job_category: [],
    job_types: [],
    job_salary_type: [],
  });
  const [search, setSearch] = useState("");
  console.log(
    "values",values, search
  );
  
  const handleChange = (e, item) => {
    const { value, type, checked, name } = e?.target;
    if (type === "checkbox") {
      return setValues({
        ...values,
        [name]: checked
          ? [...values[name], item?.id]
          : values[name]?.filter((e) => e !== item?.id),
      });
    }
    setSearch(value);
  };
  useEffect(() => {
    dispatch(getHomeJobs(values));
  }, [values]);

  const handleSubmit = (e) => {
    if (search?.trim() === "") return;
    dispatch(getHomeJobs({ search }));
  };

  const modelFilter = (isModal = false) => {
    return (
      <Box className="categories_box" style={{border: 'none'}}>
      <Box>
        <Box marginBottom={3}>
          <Typography variant="h4" className="checkbox_heading">
            Categories
          </Typography>
        </Box>
        {reqJobs?.jobCata?.results?.map((e, i) =>
          {
            return showMore?.cata ? (
              <Box key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="job_category"
                      checked={values.job_category.includes(e.id) ? true : false}
                      onChange={(el) => handleChange(el, e)}
                      sx={{
                        color: "#00CFC5",
                        "&.Mui-checked": {
                          color: "#00CFC5",
                        },
                      }}
                    />
                  }
                  label={e?.text}
                />
              </Box>
            ) : (
              i < 4 && (
                <Box key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="job_category"
                        checked={values.job_category.includes(e.id) ? true : false}
                        onChange={(el) => handleChange(el, e)}
                        sx={{
                          color: "#00CFC5",
                          "&.Mui-checked": {
                            color: "#00CFC5",
                          },
                        }}
                      />
                    }
                    label={e?.text}
                  />
                </Box>
              )
            )
          }
        )}
        <Box display="flex">
          <Box></Box>
          <Box paddingBottom={4}>
            <span
              onClick={() =>
                setShowMore({
                  ...showMore,
                  cata: !showMore?.cata,
                })
              }
              className="categories_box_namesee"
            >
              {showMore?.cata ? "See Less" : "     See More"}
            </span>
          </Box>
        </Box>
      </Box>
  
      <Box>
        <Box marginBottom={3}>
          <Typography variant="h4" className="checkbox_heading">
            Job Type
          </Typography>
        </Box>
        {reqJobs?.jobType?.results?.map((e, i) =>
          showMore?.type ? (
            <Box key={i}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="job_types"
                    checked={values.job_types.includes(e.id) ? true : false}
                    onChange={(el) => handleChange(el, e)}
                    sx={{
                      color: "#00CFC5",
                      "&.Mui-checked": {
                        color: "#00CFC5",
                      },
                    }}
                  />
                }
                label={e?.text}
              />
            </Box>
          ) : (
            i < 4 && (
              <Box key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="job_types"
                      checked={values.job_types.includes(e.id) ? true : false}
                      onChange={(el) => handleChange(el, e)}
                      sx={{
                        color: "#00CFC5",
                        "&.Mui-checked": {
                          color: "#00CFC5",
                        },
                      }}
                    />
                  }
                  label={e?.text}
                />
              </Box>
            )
          )
        )}
        {reqJobs?.jobType?.results?.length > 4 && (
          <Box display="flex">
            <Box></Box>
            <Box paddingBottom={4}>
              <span
                onClick={() =>
                  setShowMore({
                    ...showMore,
                    type: !showMore?.type,
                  })
                }
                className="categories_box_namesee"
              >
                {showMore?.type ? "See Less" : "      See More"}
              </span>
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        <Box marginBottom={3}>
          <Typography variant="h4" className="checkbox_heading">
            Job Salary
          </Typography>
        </Box>
        {reqJobs?.jobSalary?.results?.map((e, i) =>
          showMore?.salary ? (
            <Box key={i}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="job_salary_type"
                    checked={values.job_salary_type.includes(e.id) ? true : false}
                    onChange={(el) => handleChange(el, e)}
                    sx={{
                      color: "#00CFC5",
                      "&.Mui-checked": {
                        color: "#00CFC5",
                      },
                    }}
                  />
                }
                label={e?.text}
              />
            </Box>
          ) : (
            i < 4 && (
              <Box key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="job_salary_type"
                      checked={values.job_salary_type.includes(e.id) ? true : false}
                      onChange={(el) => handleChange(el, e)}
                      sx={{
                        color: "#00CFC5",
                        "&.Mui-checked": {
                          color: "#00CFC5",
                        },
                      }}
                    />
                  }
                  label={e?.text}
                />
              </Box>
            )
          )
        )}
        {reqJobs?.jobSalary?.results?.length > 4 && (
          <Box display="flex">
            <Box></Box>
            <Box paddingBottom={4}>
              <span
                onClick={() =>
                  setShowMore({
                    ...showMore,
                    salary: !showMore?.salary,
                  })
                }
                className="categories_box_namesee"
              >
                {showMore?.salary ? "See Less" : "      See More"}
              </span>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
    );
  }

  const openModalFilter = () => {
    setOpenFilterModal(true);
  }
  const closeModalFilter = () => {
    setOpenFilterModal(false);
  }
  return (
    <>
      <Navbar />
      <Box
        marginTop={14}
        className="latest_jobs_main_box_heading"
      >
        <Box className="latest_jobs_main_box">
          <Box>
            <Typography
              align="center"
              variant="h1"
              className="latest_jobs_main_box_search_headingh4"
            >
              Recent Job Openings
            </Typography>
          </Box>
        </Box>
      </Box>
      <Container>
        <Grid container marginTop={2} style={{flexDirection: isMobile ? 'column-reverse' : 'row'}}>
          {!isMobile && (<Grid item md={4} marginTop={3} paddingRight={5}>
            {modelFilter()}
          </Grid>)}
          <Grid item md={8}>
            <Box className="latestjobs_field_main_box">
              <Grid container style={{alignItems: 'center'}}>
                <Grid item xs={12} sm={8}>
                  <Box display="flex" style={{ alignItems: "center" }}>
                    <Box
                      paddingTop={2.7}
                      paddingLeft={1}
                      style={{ paddingTop: 0 }}
                    >
                      <img src={Search} alt="" style={{ width: "20px" }} />
                    </Box>
                    <Box style={{ width: "calc(100% - 20px)" }}>
                      <InputBase
                        name="search"
                        value={search}
                        onChange={(e) => handleChange(e)}
                        className="latestjobs_field"
                        sx={{ height: 80, width: "100%" }}
                        type="search"
                        placeholder="Search by company or Categories"
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box paddingY={isMobile ? 0 : 2.3} style={{marginRight: isMobile ? 0 : '20px'}} className="latestjob_btn_search">
                    <Button
                      onClick={(e) => handleSubmit(e)}
                      size="large"
                      className="latestjob_btn"
                      variant="contained"
                    >
                      Search
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {isMobile && (<Box className={"pb-2"}>
              <Button
                size="large"
                className="latestjob_btn"
                variant="contained"
                style={{width: "25%"}}
                onClick={openModalFilter}
              >
                <FaFilter />
              </Button>
            </Box>)}
            {homeJobs?.loading === true && homeJobs?.allJobs === null ? (
              <div
                style={{
                  postion: "relative",
                  minHeight: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <CircularProgress style={{ color: "black" }} />
                </div>
              </div>
            ) : homeJobs?.loading === false && homeJobs?.allJobs === null ? (
              <>{/* <Typography variant="h4">No Found Data</Typography> */}</>
            ) : (
              homeJobs?.allJobs?.results?.data?.map((e, i) => (
                <Box
                  className="titlename_link"
                  onClick={() => {
                    navigate(`/jobs/detail/${e?.slug}`, {
                      state: {
                        id: e.id,
                        uuid: e?.uuid,
                        title: e?.job_title,
                        slug: e?.slug,
                      },
                    });
                  }}
                >
                  <Grid container className="latest_box" key={i}>
                    <Grid item xs={12} sm={3}>
                      <Box className="sqr_box">
                        <img
                          className="sqr_box_icons"
                          src={`${process.env.REACT_APP_URL}${e?.logo}`}
                          alt=""
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Box className="fir_box_outer">
                        <Box className="fir_box_top">
                          <Box main className="fir_box">
                            <Box style={{ color: "#00CFC5" }}>
                              <Typography
                                className="fir_box_date_name_recrui"
                                variant="body1"
                              >
                                {e?.company_name}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                className="fir_box_date_name"
                                variant="body1"
                              >
                                {e?.created_at}
                              </Typography>
                            </Box>
                          </Box>
                          <Box paddingTop={1}>
                            <Typography
                              variant="h6"
                              className="fir_box_date_name_solut"
                            >
                              {e?.job_title}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="fir_box_bottom">
                          <Box paddingTop={1} main className="fir_box">
                            <Button
                              variant="contained"
                              className="fir_box_date_name_recrui_btn"
                            >
                              {e?.job_category?.name}
                            </Button>
                            <Box display="flex">
                              <Box>
                                <img src="" alt="" />
                              </Box>
                              <Button
                                variant="contained"
                                className="fir_box_date_name_recrui_btn"
                              >
                                {e?.job_type?.name}
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))
            )}
          </Grid>
        </Grid>
      </Container>
      <Modal
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={closeModalFilter}
        open={openFilterModal}
        showCloseIcon={true}
        styles={{modal: {maxHeight: '70vh', maxWidth: '80vw'}}}
      >
        <Box>
            {modelFilter(true)}
        </Box>
      </Modal>
      <Content_footer />
    </>
  );
};

export default Latest_job;
