import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setReceiverMetadata } from "../../store/actions/receiverMetadata.action";
import InitialsPopupModal from "./InitialsPopupModal.jsx";

/****
 * receiver initials field for signing flow
 */

function ReceiverInitials({ field }) {
  const [openSignatureBox, setOpenSignatureBox] = useState(false);
  const dispatch = useDispatch();
  const receiverMetadata = useSelector(
    (state) => state.receiverMetadataReducer
  );

  const [isFilled, setIsFilled] = useState(true);
  const [isRequired, setisRequired] = useState(field.isRequired);

  const onSignatureDone = (field, data) => {
    console.log("55");
    setIsFilled(false);

    if (data === "") {
      setIsFilled(true);
    }

    if (data !== "") {
      field.isFilled = true;
      field.base64Image = data;
      dispatch(setReceiverMetadata({ ...receiverMetadata }));
    } else {
      field.isFilled = false;
      field.base64Image = null;
      dispatch(setReceiverMetadata({ ...receiverMetadata }));
    }
    setOpenSignatureBox(false);
  };

  const changeRequiredStatus = (e) => {
    setisRequired(e.target.checked);
    field.isRequired = e.target.checked;
    field.isFilled = e.target.checked ? false : false;
  }

  return (
    <div>
      <div
        style={{
          position: "absolute",
          left: field.positionFromParentLeft,
          top: field.positionFromParentTop,
          height: field.height,
          width: field.width,
          // border: "1px solid green",
          border: "1px solid rgb(118, 118, 118)",
          background: "white",
          cursor: "pointer",
          display: "flex",
          gap: "0.5rem",
        }}
        title={field.isRequired && "Required"}>
        {field.errorMessage && isFilled && (
          <span
            style={{
              position: "absolute",
              top: -40,
              background: "red",
              borderRadius: 5,
              margin: 4,
              padding: 5,
              color: "white",
              display: "flex",
              gap: "0.5rem",
            }}>
            <span>Required</span>
            <span className="form-check">
              <input type="checkbox" className="form-check-input" checked={isRequired} onChange={changeRequiredStatus} />
            </span>
          </span>
        )}
        <span
          onClick={() => setOpenSignatureBox(true)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}>
          {field.base64Image ? (
            <img
              src={field.base64Image}
              style={{ width: "100%", height: "100%" }}
              alt=''
            />
          ) : (
            "Initials"
          )}
        </span>
      </div>

      <InitialsPopupModal
        field={field}
        onSignatureDone={onSignatureDone}
        openSignatureBox={openSignatureBox}
        setOpenSignatureBox={setOpenSignatureBox}
      />
    </div>
  );
}

export default ReceiverInitials;
