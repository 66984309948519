import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { singleLicenseOrder } from "../../store/actions/licenseOrderActions";

const AllCandidates = () => {
  const orders = useSelector(
    (state) => state?.licenseOrders?.singleOrder?.results
  );
  let { id } = useParams();
  const dispatch = useDispatch();
  useEffect(()=>{
    if(!orders){
      dispatch(singleLicenseOrder(id));
    }
  }, [orders, id, dispatch])

  const auth = useSelector((state) => state?.auth);
  // const total = ?.reduce((i, e) => i + Number(e?.price), 0);

  return (
    <Box className={styles.parent}>
      <Box className={styles.header}>
        <Typography variant="h4">Background Check Order Detail</Typography>

        {/* <Box className={styles.searchBox}>
          <BsSearch />
          <input type="text" placeholder="Search..." />
        </Box> */}
      </Box>
      {/* <Typography component={"h5"}>
        {orders?.singleOrder?.results?.orderDetail[0]?.plan_id}
      </Typography> */}
      <Box className={styles.candidateTable}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Box className={styles.boxTable2} style={{ marginTop: "10px" }}>
              <Box className={styles.rowHead}>
                <Box className={styles.rowBody}>Order Details</Box>
                {/* <Box className={styles.rowBody}>Items Summary</Box> */}
              </Box>
              <Grid container>
                <Grid item md={12} lg={6} className={styles.borderRight}>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}}>Background Check Name</Box>
                    <Box className={styles.rowBody}>
                      {orders?.license_data?.name}
                    </Box>
                  </Box>
                  {/* <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}>Industry</Box>
                    <Box className={styles.rowBody}>
                      {orders?.license_data?.industry}
                    </Box>
                  </Box> */}
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}}>Background Check Price</Box>
                    <Box className={styles.rowBody}>
                      ${orders?.license_data?.price}
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12} lg={6}>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}}>Candidate Name</Box>
                    <Box className={styles.rowBody}>
                      {orders?.candidate_details?.first_name +
                        " " +
                        orders?.candidate_details?.last_name}
                    </Box>
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}}>Email Address</Box>
                    <Box className={styles.rowBody}>
                      {orders?.candidate_details?.email}
                    </Box>
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}}>Phone Number</Box>
                    <Box className={styles.rowBody}>
                      {orders?.candidate_details?.contact_number}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item md={12} lg={12} sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={6} sx={{ width: "100%" }}>
                <Box className={styles.boxTable2}>
                  <Box className={styles.rowHead}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}}>Customer Order Details</Box>
                    {/* <Box className={styles.rowBody}>Items Summary</Box> */}
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}}>Customer Name</Box>
                    <Box className={styles.rowBody}>
                      {auth?.results?.first_name +
                        " " +
                        auth?.results?.last_name}
                    </Box>
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}} >Email Address</Box>
                    <Box className={styles.rowBody}>{auth?.results?.email}</Box>
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}}>Phone Number</Box>
                    <Box className={styles.rowBody}>
                      {auth?.results?.contact_number}
                    </Box>
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}style={{fontWeight: "bold"}} >Address Line</Box>
                    <Box className={styles.rowBody}>{auth?.results?.company_detail?.address}</Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}style={{fontWeight: "bold"}} >Suburb</Box>
                    <Box className={styles.rowBody}>{auth?.results?.company_detail?.suburb}</Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}style={{fontWeight: "bold"}} >State</Box>
                    <Box className={styles.rowBody}>{auth?.results?.company_detail?.state}</Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody}style={{fontWeight: "bold"}} >Postal Code</Box>
                    <Box className={styles.rowBody}>{auth?.results?.company_detail?.postcode}</Box>
                  </Box>

                  {/* <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                <Box className={styles.rowBody}>Bag Option</Box>
                <Box className={styles.rowBody}>No Bag</Box>
              </Box>
              <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                <Box className={styles.rowBody}>Note</Box>
                <Box className={styles.rowBody}>N /A</Box>
              </Box> */}
                </Box>
              </Grid>
              <Grid item md={12} lg={6} sx={{ width: "100%" }}>
                <Box className={styles.boxTable3}>
                  <Box className={styles.rowHead}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}} >Order Summary</Box>
                    {/* <Box className={styles.rowBody}>Items Summary</Box> */}
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}}>Order Created</Box>
                    <Box className={styles.rowBody}>
                      {moment(
                        orders?.created_at
                      ).format("dddd Do MMMM YYYY")}
                    </Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}}>Order Time</Box>
                    <Box className={styles.rowBody}>
                      {moment(
                        orders?.created_at
                      ).format("HH:mm A")}
                    </Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}}>Sub Total</Box>
                    <Box className={styles.rowBody}>
                      ${orders?.license_data?.price}
                    </Box>
                  </Box>

                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}}>GST(10%)</Box>
                    <Box className={styles.rowBody}>${orders?.gst}</Box>
                  </Box>
                  <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                    <Box className={styles.rowBody} style={{fontWeight: "bold"}}>Grand Total</Box>
                    <Box className={styles.rowBody}>${orders?.total_paid}</Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AllCandidates;
