import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as Cross } from "../../../assets/industries/model/x.svg";
import Modal from "@mui/material/Modal";
import styles from "./styles.module.scss";
import { Button, CircularProgress, Grid } from "@mui/material";
import { sendRequest } from "../../../api/candidate/candidate.class";
import MultiPersons from "./multiPersons";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ShowAlert } from "../../../store/actions/alertActions";

export default function EmailForm({
  openModal,
  setOpenModal,
  setUpdate,
  data,
}) {
  const handleClose = () => setOpenModal(false);
  const [loader, setLoader] = useState(false);
  const [formValues, setFormValues] = useState([
    { to_email: "", contact_number: "", country_code: "AU", emailError: "", contactNumberError: "" },
  ]);
  const auth = useSelector((state) => state.auth.results);
  const dispatch = useDispatch();

  // console.log(data);

  const handleSend = async () => {
    // console.log("handleSend", data);
    setLoader(true);
    let requests = {};
    let isEmailError = false;
    let isContactError = false;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (formValues?.length > 0) {
        const formData = formValues.map((record, index) => {
          if(!record.contact_number || record.contact_number.trim() === ''){
            record.contactNumberError = "Contact number is required";
            isContactError = true;
          }else{
            isContactError = false;
            record.contactNumberError = "";
          }
          if(!record.to_email || record.to_email === ''){
            record.emailError = "Email address is required";
            isEmailError = true;
          }else if(!emailRegex.test(record.to_email)){
            record.emailError = "Please enter valid email address";
            isEmailError = true;
          }else{
            record.emailError = "";
            isEmailError = false;
          }
          requests["sender_information_" + index] = record;
          return record;
        });
        setFormValues(formData)
        if(isEmailError || isContactError){
          setLoader(false);
          return;
        }
      }
      try {
        const payload = {
          recruiter_id: auth?.recruiter_id,
          workspace_id: data?.workspace_id || auth?.workspace_id,
          // candidate_id:
          form_builder_category_id: data?.form_builder_category?.id,
          uuid: data?.uuid,
          form_requests: requests,
        };
        // console.log("payload", payload);
        const response = await sendRequest(payload);
        // console.log(response);
        if (response.data.success) {
          dispatch(ShowAlert(response?.data?.message, "success"));
          handleClose();
          setUpdate((old) => old + 1);
          setFormValues([
            { to_email: "", contact_number: "", country_code: "AU" },
          ]);
        }else {
          if (typeof response.data.message === 'string' || response.data.message instanceof String){
            dispatch(ShowAlert(response?.data?.message, "error"));
          }else{
            const errorMessages = Object.values(response.data.message).flat();
            errorMessages.forEach((message) => {
              dispatch(ShowAlert(message, "error"));
            });
          }
          // handleClose();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoader(false);
      }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box class={styles.modalParent}>
          <Box className={styles.modalwapper}>
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
            <Typography variant="h4" className={styles.heading}>
              Resend Form
            </Typography>
            <form action="" style={{ width: "100%" }}>
              <Box className={styles.parent}>
                <MultiPersons
                  setFormValues={setFormValues}
                  formValues={formValues}
                />

                <Box className={`button-primary`} style={{ marginTop: "20px" }}>
                  <Button
                    className={`button-primary`}
                    type="button"
                    onClick={handleSend}
                    disabled={loader}
                  >
                    {loader ? (
                      <Box>
                        <CircularProgress />
                      </Box>
                    ) : (
                      "Send"
                    )}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
