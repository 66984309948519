import { Grid, Box, Button, Typography } from "@mui/material";
import t1 from "../../../assets/pricing/1.png";
import React, { useState } from "react";
import { BsChevronDoubleRight, BsChevronRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../common/TextInput";
import styles from "./styles.module.scss";
import CustomSelect from "../../common/Select";
import { ShowAlert } from "../../../store/actions/alertActions";
import { useEffect } from "react";
import { REGEX } from "../../../customHooks/utils";
import { addPrice } from "../../../store/actions/pricingActions";
const InfoSection = ({
  setOpen,
  setOpen2,
  values,
  setValues,
  setTotalPrice,
  totalPrice,
}) => {
  const pricing = useSelector((state) => state?.pricing);
  const dispatch = useDispatch();
  const [clickButton, setClickButton] = useState("");
  const auth = useSelector((state) => state?.auth);
  // const [values, setValues] = useState({});
  const subscriptionValue = useSelector(
    (state) => state?.pricing?.paymentList?.results[1]?.cost_range
  );
  let totalScore = Object.values(values)?.map((e) =>
    e?.price !== "0.00"
      ? Number(e?.count) * Number(e?.price)
      : e?.price === undefined
      ? Number(e?.count)
      : Number(e?.count)
  );

  let total = totalScore?.length > 0 && totalScore?.reduce((e, i) => e + i);

  useEffect(() => {
    setTotalPrice(Number(total));
  }, [totalScore]);
  let validateArray = [];
  const validate = pricing?.paymentList?.results?.map((e, i) =>
    ["question", "cost"].includes(e?.type) ? validateArray?.push(i) : null
  );

  const options = [
    {
      value: "1 Month ($15)",
      label: "1 Month ($15)",
      price: "15.00",
      interval_count: 1,
      name: "rangeduration",
      title: "rangeduration",
    },
    {
      value: "6 Months ($75)",
      label: "6 Months ($75)",
      interval_count: 6,
      price: "75.00",
      name: "rangeduration",
      title: "rangeduration",
    },
    {
      value: "12 Months ($150)",
      label: "12 Months ($150)",
      interval_count: 12,
      price: "150.00",
      name: "rangeduration",
      title: "rangeduration",
    },
  ];

  useEffect(() => {
    dispatch(addPrice(Number(totalPrice)));
  }, [totalPrice]);

  const validation = () => {
    let newError = {};

    for (let key in values) {
      const value = values[key];

      // If value exists but count is empty or just whitespace
      if (!value?.count || value.count.trim() === "") {
        newError[key] = "Please Add The Data";
      }
    }

    return newError;
  };

  const handleChangeoption = (e, item, i, el) => {
    const { price, name } = e;

    let cal_id = [];

    let array = item?.filter((e) => {
      if (el?.range === null) {
        //return cal_id.push(e);
      } else if (Number(el?.range) > Number(price)) {
        return cal_id.push(e);
      } else {
        //return cal_id.push(e);
      }
    });

    setValues({
      ...values,
      [i]: {
        ...values[i],

        count: i.toString(),
        cost_calculator_id: 5,
        name: el?.name,
        title: el?.title,
        price: price,
        interval_count: e?.interval_count,
        // name === "3"
        //   ? Number(price) <= 10
        //     ? 0
        //     : cal_id.slice(0, 1)?.pop()?.price === undefined
        //     ? 1
        //     : (cal_id.slice(0, 1)?.pop()?.price)
        //   : cal_id.slice(0, 1)?.pop()?.price === undefined
        //   ? 1
        //   : cal_id.slice(0, 1)?.pop()?.price,
      },
    });
  };

  const handleChange = (e, item, i, el) => {
    const { value, name } = e?.target;
    if (value && parseInt(value) < 1) {
      return;
    }
    let cal_id = [];
    let array = item?.filter((e) => {
      if (e?.range === null) {
        // return cal_id.push(item[0]);
        return cal_id.push(e);
      } else if (Number(e?.range) > Number(value)) {
        return cal_id.push(e);
      } else {
        // return cal_id.push(item[0]);
      }
    });

    setValues({
      ...values,
      [name]: {
        ...values[name],

        count: value,
        cost_calculator_id:
          cal_id.slice(0, 1)?.pop()?.cost_calculator_id === undefined
            ? 0
            : cal_id.slice(0, 1)?.pop()?.cost_calculator_id,
        name: el?.name,
        title: el?.title,
        qoute: el.qoute,
        price:
          cal_id.slice(0, 1)?.pop()?.price === undefined
            ? 1
            : cal_id.slice(0, 1)?.pop()?.price,
      },
    });
  };

  const yesButton = (i, item, el) => {
    let cal_id = [];
    let array = item?.filter((e) => {
      if (e?.range === null) {
        // return cal_id.push(item[0]);
        return cal_id.push(e);
      } else if (Number(e?.range) > Number(values[i]?.count)) {
        return cal_id.push(e);
      } else {
        // return cal_id.push(item[0]);
      }
    });

    setValues({
      ...values,
      [i]: {
        count: i === 2 ? "1" : "00",
        no: false,
        yes: true,
        cost_calculator_id:
          cal_id.slice(0, 1)?.pop()?.cost_calculator_id === undefined
            ? 0
            : cal_id.slice(0, 1)?.pop()?.cost_calculator_id,
        name: el?.name,
        title: el?.title,
        price:
          cal_id.slice(0, 1)?.pop()?.price === undefined
            ? 1
            : cal_id.slice(0, 1)?.pop()?.price,
        interval_count: "",
      },
    });
  };
  const noButton = (i, item, el) => {
    let cal_id = [];

    let array = item?.filter((e) => {
      if (e?.range === null) {
        // return cal_id.push(item[0]);
        return cal_id.push(e);
      } else if (Number(e?.range) > Number(values[i]?.count)) {
        return cal_id.push(e);
      } else {
        // return cal_id.push(item[0]);
      }
    });

    setValues({
      ...values,
      [i]: {
        count: "0",
        no: true,
        yes: false,
        cost_calculator_id:
          cal_id.slice(0, 1)?.pop()?.cost_calculator_id === undefined
            ? 0
            : cal_id.slice(0, 1)?.pop()?.cost_calculator_id,
        name: el?.name,
        title: el?.title,
        price:
          cal_id.slice(0, 1)?.pop()?.price === undefined
            ? 1
            : cal_id.slice(0, 1)?.pop()?.price,
      },
    });
  };

  // console.log("values", values);

  const checkOut = () => {
    const validateData = validation();
    console.log("here values ", values);

    // If there are no valid values or if validation fails
    if (
      Object.keys(values).length < 1 ||
      Object.keys(validateData).length > 0
    ) {
      console.log(validateData);
      return dispatch(ShowAlert("Quantity must be more than 1", "error"));
    }

    if (values["2"]?.yes === true && Number(values["2"]?.count) < 1) {
      return dispatch(ShowAlert("Your values is less than 1", "error"));
    }

    setOpen(false);
  };

  const clear = () => {
    setValues({});
  };

  const [price, setPrice] = useState("10.50");

  return (
    <Box className={styles.gridParrent}>
      {/* <PaymentElement /> */}
      <Box className={styles.content}>
        <Box className={styles.contentBox}>
          <Box className={styles.left}>
            <Box className={styles.heading}>Pricing</Box>
          </Box>
          <Box className={styles.right}>
            <Box className={styles.heading}></Box>
          </Box>
        </Box>
        {pricing?.paymentList?.results?.map((e, i) => [
          ["question", "cost"].includes(e?.type) &&
            (e?.qoute !== "aa8" ||
              (e?.qoute === "aa8" &&
                auth?.results?.workspace_type !== "member")) && (
              <Box className={styles.contentBox} key={i}>
                <Box className={`${styles.left} ${styles.left2}`}>
                  <Typography component="span">{e?.name}</Typography>
                  {e?.cost_range?.map(
                    (el) =>
                      el?.name !== null && (
                        <Typography className={styles?.idName}>
                          {el?.name}
                        </Typography>
                      )
                  )}
                </Box>

                <Box className={styles.right}>
                  {e?.type === "question" ? (
                    <Box className={styles.buttonSection}>
                      <Box className={`button-primary  ${styles.button} mt-1`}>
                        <Button
                          className="secondary-btn"
                          name={i}
                          style={{
                            backgroundColor:
                              values[i]?.no === false && values[i]?.yes === true
                                ? "#00CFC5"
                                : "",
                            color:
                              values[i]?.no === false && values[i]?.yes === true
                                ? "white"
                                : "",
                          }}
                          onClick={(el) => {
                            yesButton(i, e.cost_range, e);
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          className="secondary-btn"
                          name={i}
                          style={{
                            backgroundColor:
                              values[i]?.no === true && values[i]?.yes === false
                                ? "#00CFC5"
                                : "",
                            color:
                              values[i]?.no === true && values[i]?.yes === false
                                ? "white"
                                : "",
                          }}
                          onClick={(el) => {
                            noButton(i, e.cost_range, e);
                          }}
                        >
                          No
                        </Button>
                      </Box>

                      {e?.qoute === "aa5" &&
                        values[i]?.no === false &&
                        values[i]?.yes === true && (
                          <>
                            <CustomSelect
                              placeholder="Select subscription plan"
                              customClass={styles.select2}
                              options={options}
                              onChange={(el) =>
                                handleChangeoption(el, e?.cost_range, i, e)
                              }
                            />
                          </>
                        )}

                      <Box sx={{}}>
                        {e?.qoute === "aa6" &&
                          values[i]?.no === false &&
                          values[i]?.yes === true && (
                            <TextInput
                              name={i}
                              type={"number"}
                              min="1"
                              onKeyPress={(e) => {
                                if (
                                  e.code === "Minus" ||
                                  e.code === "NumpadSubtract" ||
                                  e.code === "Comma" ||
                                  e.code === "NumpadAdd" ||
                                  e.code === "Period" ||
                                  e.key === "e" ||
                                  e.key === "E"
                                ) {
                                  e.preventDefault();
                                }
                                if (
                                  values[i]?.count.length === 0 &&
                                  e.key === "0"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              value={values[i]?.count || ""}
                              onChange={(el) =>
                                handleChange(el, e?.cost_range, i, e)
                              }
                              customClass={`${styles.inputBox} ${styles.inputBox22} mt-2`}
                            />
                          )}
                      </Box>
                    </Box>
                  ) : e?.type === "cost" ? (
                    <TextInput
                      name={i}
                      type={"number"}
                      min="1"
                      max="99"
                      onKeyPress={(e) => {
                        if (
                          e.code === "Minus" ||
                          e.code === "NumpadSubtract" ||
                          e.code === "Comma" ||
                          e.code === "NumpadAdd" ||
                          e.code === "Period" ||
                          e.key === "e" ||
                          e.key === "E" ||
                          values[i]?.count?.length > 1
                        ) {
                          e.preventDefault();
                        }
                        if (values[i]?.count.length === 0 && e.key === "0") {
                          e.preventDefault();
                        }
                      }}
                      value={values[i]?.count || ""}
                      onChange={(el) => handleChange(el, e?.cost_range, i, e)}
                      customClass={styles.inputBox}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            ),
        ])}
        <Box className={styles.contentBox}>
          <Box className={styles.left}></Box>
          <Box className={styles.right} sx={{ marginTop: "20px" }}>
            <Box className={`button-primary ${styles.button}`}>
              <Button onClick={checkOut}>Add to cart </Button>
            </Box>
            <Box className={`button-primary ${styles.button}`}>
              <Button onClick={clear}>Clear </Button>
            </Box>

            <Typography>${pricing?.price.toFixed(2)}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InfoSection;
