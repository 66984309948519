/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import "./styles.scss";
import { Box, Typography, Button } from "@mui/material";
import CustomSelect from "../../common/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  createSingleCertificateFunc,
  resetSingleCandidateReff,
  updateSingleCertificateFunc,
} from "../../../store/actions/singleCandidateScreenActions";
import { useEffect } from "react";
import { getAllRecruiterCreatedIndustry } from "../../../store/actions/recruiterCandidateActions";
import CustomModel from "../model";
import CheckoutForm from "../checkout/checkout";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import { isMobile } from "react-device-detect";
import PoliceCheckForm from "./PoliceCheckForm";

const InterviewPopup = ({
  open,
  setOpen,
  backgroundCheck,
  setBackgroundCheck,
  backyCheckDetails,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const candidate = useSelector((state) => state?.rcandidate);
  const singleCandidate = useSelector((state) => state?.singleCandidate);
  const [open2, setOpen2] = useState(false);
  const [backycheckInfoModel, setBackycheckInfoModel] = useState(false);
  const [backycheckPopupContant, setBackycheckPopupContant] = useState({
    heading: "",
    description: "",
    isPoliceCheck: false,
  });
  const [data, setData] = useState({});
  const [updatedData, setUpdatedData] = useState({});
  const [formErrors, setFormErrors] = useState();
  const [typedValue, setTypedValue] = useState("");

  const [isPoliceCheck, setIsPoliceCheck] = useState(false);
  console.log(
    "here single candidate ",
    singleCandidate?.editCertificate?.results
  );
  const initialOrgInfo = {
    isCurrentUserClient:
      singleCandidate?.editCertificate?.results?.client_id !== ""
        ? true
        : false,
    isExistingOrg:
      singleCandidate?.editCertificate?.results?.organisation_id !== ""
        ? true
        : false,
    isExistingClient:
      singleCandidate?.editCertificate?.results?.client_id !== ""
        ? true
        : false,
    organisationId:
      singleCandidate?.editCertificate?.results?.organisation_id !== ""
        ? Number(singleCandidate?.editCertificate?.results?.organisation_id)
        : 0,
    organisationName: "",
    clientId:
      singleCandidate?.editCertificate?.results?.client_id !== ""
        ? Number(singleCandidate?.editCertificate?.results?.client_id)
        : 0,
    clientName: "",
    clientEmail: "",
    organisationIdError: "",
    organisationNameError: "",
    clientIdError: "",
    clientNameError: "",
    clientEmailError: "",
  };
  const [clientInfoData, setClientInfoData] = useState(initialOrgInfo);

  const backycheckService = [
    "nationally_coordinated_criminal_check",
    "credit_history_report_check_request",
    "working_with_children_check",
  ];

  const optionsCreateIndustry = candidate?.createdIndustry?.results?.map(
    (parent) => ({
      label: parent?.name,

      options: parent?.industry_certification_license?.map((e) => {
        return {
          label: backgroundCheck ? `${e?.name} \t ($${e?.price})` : e.name,
          value: e.id,
          parent: parent.id,
          is_backy_check: backycheckService.includes(e.slug),
          slug: e?.slug,
        };
      }),

      price: parent?.industry_certification_license?.map((e) => {
        return {
          label: e?.name,
          value: e.id,
          parent: parent.id,
          price: e?.price,
          is_backy_check: backycheckService.includes(e.slug),
          slug: e?.slug,
        };
      }),
    })
  );

  const [values, setValues] = useState({
    createdIndustry:
      {
        label: backgroundCheck
          ? `${singleCandidate?.editCertificate?.results?.industry_certification_license?.name} \t ($${singleCandidate?.editCertificate?.results?.industry_certification_license?.price})`
          : singleCandidate?.editCertificate?.results
              ?.industry_certification_license?.name,
        value:
          singleCandidate?.editCertificate?.results
            ?.industry_certification_license?.id,
        parent:
          singleCandidate?.editCertificate?.results?.recruiter_industry?.id,
        price:
          singleCandidate?.editCertificate?.results
            ?.industry_certification_license?.price,
        is_backy_check:
          singleCandidate?.editCertificate?.results?.is_backy_check === true
            ? true
            : false,
        slug:
          singleCandidate?.editCertificate?.results?.is_backy_check === true
            ? "nationally_coordinated_criminal_check"
            : "",
      } || "",
    new_industry_text: {
      label: "",
    },
  });

  const [Customerror, setErrors] = useState({});
  const validate = (data) => {
    const newError = {};
    for (let key in data) {
      let value = data[key];
      switch (key) {
        case "createdIndustry":
          if (value?.value === undefined || value?.value === "")
            newError[key] = `No ${
              backgroundCheck ? "check" : "license"
            } has been selected. Please select a ${
              backgroundCheck ? "check" : "license"
            } from dropdown menu`;
          break;
        default:
      }
    }
    return newError;
  };

  const handleInputChange = (inputValue) => {
    setTypedValue(inputValue);
    if (inputValue) {
      // Update the values.createdIndustry state with the typed value
      setValues((prevValues) => ({
        ...prevValues,
        createdIndustry: {
          label: inputValue,
          value: null,
          parent: null,
          price: null,
        },
        new_industry_text: {
          label: inputValue,
        },
      }));
    }
  };

  const submitData = async (e) => {
    e.preventDefault();
    const validateSelect = validate(values);
    if (Object?.keys(validateSelect)?.length > 0) {
      setErrors(validateSelect);
      return;
    }

    // if(backgroundCheck){
    //   // if((!backyCheckDetails?.client_id || backyCheckDetails?.client_id === '' || backyCheckDetails?.is_npcs_active || !backyCheckDetails?.is_npcs_verified || backyCheckDetails?.is_amici_active || !backyCheckDetails?.is_amici_verified) && values?.createdIndustry?.slug === "nationally_coordinated_criminal_check")
    //   if((backyCheckDetails?.is_npcs_active || !backyCheckDetails?.is_npcs_verified) && values?.createdIndustry?.slug === "nationally_coordinated_criminal_check"){
    //     setBackycheckPopupContant({
    //       heading: "Background Check Acceptance Of Terms Of Use",
    //       description: "As part of our agreement with our third party supplier of this check, we must register all customers by completing new customer form they have provided. Also required is a customer form which is to be registered with ACIC. Please review and fill in the forms required which will be sent to the third party for approval. This may take 3-5 business days for the approval.",
    //       isPoliceCheck: true,
    //     });
    //     setBackycheckInfoModel(true);
    //     return;
    //   }

    //   // if((!backyCheckDetails?.client_id || backyCheckDetails?.client_id === '' || backyCheckDetails?.is_amici_active || !backyCheckDetails?.is_amici_verified) && values?.createdIndustry?.slug !== "nationally_coordinated_criminal_check"){
    //   //   setBackycheckPopupContant({
    //   //     heading: "Background Check Acceptance Of Terms Of Use",
    //   //     description: "As part of our agreement with our third party supplier of this check, we must register all customers by completing new customer form they have provided. Also required is a customer form which is to be registered with ACIC. Please review and fill in the forms required which will be sent to the third party for approval. This may take 3-5 business days for the approval.",
    //   //     isPoliceCheck: false,
    //   //   });
    //   //   setBackycheckInfoModel(true);
    //   //   return;
    //   // }
    // }

    let isPoliceCheckErr = orgInfoValidations();
    console.log("here error ", isPoliceCheckErr);
    // if(isPoliceCheck){
    //   if(!clientInfoData.isCurrentUserClient){
    //     let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    //     let mail = "test@example.com";
    //     if(clientInfoData.isExistingOrg){
    //       if(clientInfoData.organisationId === 0){
    //         isPoliceCheckErr = true;
    //       }
    //     }else{
    //       if(clientInfoData.organisationName.trim() === ""){
    //         isPoliceCheckErr = true;
    //       }
    //     }

    //     if(clientInfoData.isExistingClient){
    //       if(clientInfoData.clientId === 0){
    //         isPoliceCheckErr = true;
    //       }
    //     } else {
    //       if(clientInfoData.clientName.trim() === ""){
    //         isPoliceCheckErr = true;
    //       }
    //       if(clientInfoData.clientEmail.trim() === ""){
    //         isPoliceCheckErr = true;
    //       }else if (!regex.test(mail)) {
    //         isPoliceCheckErr = true;
    //       }
    //     }

    //   }
    // }
    if (isPoliceCheckErr) {
      return;
    }
    const local_data = {
      recruitment_industries: {
        recruitment_industry_1: {
          rec_ind_request_id: values?.createdIndustry?.parent?.toString() || "",
          ind_cert_lic_request_id:
            values?.createdIndustry?.value?.toString() || "",
        },
      },
      recruiter_id: auth?.results?.recruiter_id,
      workspace_id: auth?.results?.workspace_id,
      candidate_id: candidate?.showCandidate?.results?.id,
      request_type: "recruitment",
      new_industry_text: values?.createdIndustry?.value
        ? ""
        : values?.new_industry_text?.label,
      is_background_check: backgroundCheck,
      license_name:
        values?.createdIndustry?.label?.toString().replace(/\(.*?\)/g, "") ||
        "",
      candidate_name: `${candidate?.showCandidate?.results?.first_name} ${candidate?.showCandidate?.results?.last_name}`,
    };

    const local_data2 = {
      uuid: singleCandidate?.editCertificate?.results?.uuid,
      candidate_lic_cert_id:
        singleCandidate?.editCertificate?.results?.candidate_license?.id,
      recruitment_industries: {
        [singleCandidate?.editCertificate?.results?.id]: {
          rec_ind_request_id: values?.createdIndustry?.parent?.toString() || "",
          ind_cert_lic_request_id:
            values?.createdIndustry?.value?.toString() || "",
        },
      },
      recruiter_id: auth?.results?.recruiter_id,
      workspace_id: auth?.results?.workspace_id,
      candidate_id: candidate?.showCandidate?.results?.id,
      request_type: "recruitment",
      is_background_check: backgroundCheck,
      license_name:
        values?.createdIndustry?.label?.toString().replace(/\(.*?\)/g, "") ||
        "",
      candidate_name: `${candidate?.showCandidate?.results?.first_name} ${candidate?.showCandidate?.results?.last_name}`,
    };

    let local_data_new = {
      ...local_data,
      ...{
        organisation_info: {
          isCurrentUserClient: clientInfoData.isCurrentUserClient,
          isExistingOrg: clientInfoData.isExistingOrg,
          isExistingClient: clientInfoData.isExistingClient,
          organisationId: clientInfoData.organisationId,
          organisationName: clientInfoData.organisationName,
          clientId: clientInfoData.clientId,
          clientName: clientInfoData.clientName,
          clientEmail: clientInfoData.clientEmail,
        },
      },
    };

    let local_data_new_2 = {
      ...local_data2,
      ...{
        organisation_info: {
          isCurrentUserClient: clientInfoData.isCurrentUserClient,
          isExistingOrg: clientInfoData.isExistingOrg,
          isExistingClient: clientInfoData.isExistingClient,
          organisationId: clientInfoData.organisationId,
          organisationName: clientInfoData.organisationName,
          clientId: clientInfoData.clientId,
          clientName: clientInfoData.clientName,
          clientEmail: clientInfoData.clientEmail,
        },
      },
    };
    // if (backgroundCheck === false) {
    if (singleCandidate?.editCertificate?.success === true) {
      dispatch(
        updateSingleCertificateFunc(local_data_new_2, {
          workspace_id: auth?.results?.workspace_id,
          candidate_id: candidate?.showCandidate?.results?.id,
        })
      );
    } else {
      dispatch(
        createSingleCertificateFunc(local_data_new, {
          workspace_id: auth?.results?.workspace_id,
          candidate_id: candidate?.showCandidate?.results?.id,
        })
      );
    }
    // }

    // setValues("");
    // if (backgroundCheck === true)
    //   return setOpen2(true), setData(local_data), setUpdatedData(local_data2);
  };

  useEffect(() => {
    if (singleCandidate?.createCertificate?.success === true) {
      setOpen(false);
      dispatch(resetSingleCandidateReff());
    }
  }, [singleCandidate?.createCertificate]);
  useEffect(() => {
    if (singleCandidate?.updateCertificate?.success === true) {
      setOpen(false);
      dispatch(resetSingleCandidateReff());
    }
  }, [singleCandidate?.updateCertificate]);

  const onChangeOrgInfo = (updatedOrgData) => {
    setClientInfoData((prev) => ({ ...prev, ...updatedOrgData }));
  };

  const orgInfoValidations = () => {
    let isPoliceCheckErr = false;
    const errorObject = {
      isCurrentUserClientError: "",
      organisationIdError: "",
      organisationNameError: "",
      clientIdError: "",
      clientNameError: "",
      clientEmailError: "",
    };
    console.log(
      "here isCurrentUserClient ",
      clientInfoData.isCurrentUserClient
    );
    if (isPoliceCheck) {
      if (
        clientInfoData.isCurrentUserClient === true ||
        clientInfoData.isCurrentUserClient === undefined
      ) {
        let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let mail = "test@example.com";
        if (clientInfoData.isExistingOrg) {
          if (clientInfoData.organisationId === 0) {
            isPoliceCheckErr = true;
            errorObject.organisationIdError = "Please Select Organisation";
          }
        } else {
          if (clientInfoData.organisationName.trim() === "") {
            isPoliceCheckErr = true;
            errorObject.organisationNameError = "Organisation name is required";
          }
        }

        if (clientInfoData.isExistingClient) {
          if (clientInfoData.clientId === 0) {
            isPoliceCheckErr = true;
            errorObject.clientIdError = "Please Select Client";
          }
        } else {
          if (clientInfoData.clientName.trim() === "") {
            isPoliceCheckErr = true;
            errorObject.clientNameError = "Client name is required";
          }
          if (clientInfoData.clientEmail.trim() === "") {
            isPoliceCheckErr = true;
            errorObject.clientEmailError = "Client email address is required";
          } else if (!regex.test(mail)) {
            isPoliceCheckErr = true;
            errorObject.clientEmailError =
              "Please enter valid client email address";
          }
        }

        if (clientInfoData.isCurrentUserClient === undefined) {
          errorObject.isCurrentUserClientError =
            "Select the above Question Option";
        }
      }
    }
    if (isPoliceCheckErr) {
      onChangeOrgInfo(errorObject);
      setFormErrors(errorObject);
    } else {
      setFormErrors("");
    }
    return isPoliceCheckErr;
  };
  useEffect(() => {
    if (
      values.createdIndustry?.slug === "nationally_coordinated_criminal_check"
    ) {
      setIsPoliceCheck(true);
    } else {
      setIsPoliceCheck(false);
    }
  }, [values.createdIndustry?.slug]);
  return (
    <>
      <Box className={`interviewParrent ${isMobile ? "mt-5" : ""}`}>
        <Typography variant="h4">
          Request for{" "}
          {backgroundCheck === true ? "Background Checks" : "License Copy"}{" "}
        </Typography>
        <form onSubmit={submitData} style={{ width: "100%" }}>
          <Box>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <CustomSelect
                options={optionsCreateIndustry}
                customClass={"interviewSelect"}
                label={
                  <>
                    {backgroundCheck === true
                      ? "Select Checks"
                      : "Select License Type"}
                    <span
                      style={{
                        color: "red",
                        marginLeft: "3px",
                        position: "relative",
                        display: "initial",
                      }}
                    >
                      *
                    </span>
                  </>
                }
                onFocus={() => {
                  dispatch(
                    backgroundCheck === true
                      ? getAllRecruiterCreatedIndustry({
                          workspace_id: auth?.results?.workspace_id?.toString(),
                          recruiter_id: auth?.results?.recruiter_id.toString(),
                          industry_id:
                            auth?.results?.company_detail?.industry?.id,
                          automated_list: 1,
                          is_background_check: 1,
                        })
                      : getAllRecruiterCreatedIndustry({
                          workspace_id: auth?.results?.workspace_id?.toString(),
                          recruiter_id: auth?.results?.recruiter_id.toString(),
                          industry_id:
                            auth?.results?.company_detail?.industry?.id,
                        })
                  );
                }}
                onChange={(e) => {
                  setValues({
                    ...values,
                    createdIndustry: { ...e, parent: e?.parent },
                  });

                  if (Customerror.hasOwnProperty("createdIndustry")) {
                    delete Customerror["createdIndustry"];
                    setErrors(Customerror);
                  }
                  console.log("here selected call");
                  if (e?.slug === "nationally_coordinated_criminal_check") {
                    setIsPoliceCheck(true);
                  } else {
                    setIsPoliceCheck(false);
                  }
                }}
                onInputChange={
                  backgroundCheck === true ? "" : handleInputChange
                }
                // inputValue={typedValue}
                onMenuClose={() => {
                  // Handle the menu close event
                  if (!typedValue) {
                    // If the typedValue is empty, update the state to maintain the selected value
                    setValues((prevValues) => ({
                      ...prevValues,
                      new_industry_text: {
                        label: prevValues.createdIndustry?.label || "",
                      },
                    }));
                  }
                }}
                value={
                  typedValue
                    ? {
                        label: typedValue,
                        value: null,
                        parent: null,
                        price: null,
                      }
                    : optionsCreateIndustry
                        ?.flatMap((group) => group.options) // Flatten nested options
                        ?.find(
                          (option) =>
                            option.value === values?.createdIndustry?.value
                        ) || values?.createdIndustry
                }
              />
              {Customerror?.createdIndustry && (
                <span style={{ margin: "auto" }}>
                  {Customerror?.createdIndustry}
                </span>
              )}
            </div>
          </Box>
          {isPoliceCheck && (
            <PoliceCheckForm
              clientInfoData={clientInfoData}
              onChangeOrgInfo={onChangeOrgInfo}
              orgInfoValidations={orgInfoValidations}
              formErrors={formErrors}
            />
          )}

          <Box
            className="button-primary buttoninterview"
            style={{ justifyContent: "center", gap: "2rem" }}
          >
            {/* <Button onClick={submitData} disabled={singleCandidate?.loading}>
              {singleCandidate?.loading ? (
                <p style={{ color: "white" }}>Loading...</p>
              ) : Object.keys(singleCandidate?.editCertificate)?.length > 0 ||
                backgroundCheck === false
                 ? (
                "Send"
              ) : (
                "Make Payment"
              )}
            </Button> */}
            <Button onClick={submitData} disabled={singleCandidate?.loading}>
              {singleCandidate?.loading ? (
                <p style={{ color: "white" }}>Loading...</p>
              ) : (
                "Send"
              )}
            </Button>
            <Button
              className="secondary-btn"
              onClick={() => {
                setOpen(false);
                dispatch(resetSingleCandidateReff());
              }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
      <CustomModel
        open={open2}
        setOpen={setOpen2}
        data={data}
        setData={setData}
      >
        <CheckoutForm
          open={open2}
          setOpen={setOpen2}
          data={data}
          setData={setData}
          updatedData={updatedData}
        />
      </CustomModel>
      <CustomModel open={backycheckInfoModel} setOpen={setBackycheckInfoModel}>
        <div className={`backycheck_popup`}>
          <div className={`heading mb-3`}>
            <Typography variant="h5">
              {backycheckPopupContant.heading}
            </Typography>
          </div>
          <div className={`description`} style={{ textAlign: "justify" }}>
            <p>{backycheckPopupContant.description} </p>
            <p>
              Have a link to the form in the pop up so the customer can complete
              the form.
            </p>
            <p>
              <span style={{ fontWeight: 600 }}>Forms required:</span>
            </p>
            <ol style={{ marginLeft: "1rem", color: "#757575" }}>
              {/* <li><a href={`${routes.BACKYCHECK_URL.AMICI_URL}?id=${candidate?.showCandidate?.results?.uuid}`} target={'_blank'} rel={'noreferrer'} style={{color: '#00CFC5', fontWeight: 500}}>AMICI TECH CUSTOMER AGREEMENT</a> {backyCheckDetails?.is_amici_fill ? ` : ${backyCheckDetails?.is_amici_verified ? "Verified" : "In Review"}` : "Pending"}</li> */}
              {backycheckPopupContant.isPoliceCheck && (
                <li>
                  <a
                    href={`${routes.BACKYCHECK_URL.NPCS_URL}?id=${candidate?.showCandidate?.results?.uuid}`}
                    target={"_blank"}
                    rel={"noreferrer"}
                    style={{ color: "#00CFC5", fontWeight: 500 }}
                  >
                    NPCS CUSTOMER TERMS OF USE
                  </a>{" "}
                  {backyCheckDetails?.is_npcs_fill
                    ? ` : ${
                        backyCheckDetails?.is_npcs_verified
                          ? "Verified"
                          : "In Review"
                      }`
                    : " : Pending"}
                </li>
              )}
            </ol>
          </div>
          <div className="button-primary text-end w-100 d-flex align-items-end flex-column-reverse">
            <Button
              className="danger-btn"
              onClick={() => {
                setBackycheckInfoModel(false);
                setBackycheckPopupContant({
                  heading: "",
                  description: "",
                  isPoliceCheck: false,
                });
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </CustomModel>
    </>
  );
};

export default InterviewPopup;
