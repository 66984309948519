import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./styles.module.scss";
import { ReactComponent as Cross } from "../../../assets/industries/model/x.svg";
import { isMobileOnly } from "react-device-detect";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

export default function BasicModal({
  open,
  setOpen,
  children,
  disableCross,
  setHeight = false,
  setIsEdit = false,
  isEdit = false,
  is_background_check = false,
}) {
  const handleClose = (_, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    if (isEdit) {
      setIsEdit(false);
    }
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}
      >
        {/* minHeight: isMobileOnly ? "unset" : "22rem" */}
        <Box
          className={`${styles.boxModel} xym1`}
          style={{
            height: "100%",
            minWidth: "auto",
            padding: "1rem 2rem",
          }}
        >
          {!disableCross && (
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
          )}
          <Box
            className={`${styles.body} ${setHeight ? styles.bodyHeight : ""}`}
          >
            {children}
          </Box>
        </Box>
      </Modal>
    </>
  );
}
