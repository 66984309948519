import React from "react";

import ContentFooter from "../content-footer/Content_footer";
import Navbar from "../navbar/Navbar";
import { Typography } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./styles.module.scss";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Row className={styles.rowMarginTop}>
          <Col lg={12} md={12} sm={12}>
            <Typography variant="h4" className={`text-center mb-3 ${styles.mainHeading}`}>Privacy Act Statement</Typography>
            <ul className={styles.privacyPageList}>
            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Collection and Use of Personal Information: </b>
              We may collect personal information from users of the mobile application (the "App") in accordance with applicable privacy laws. This information may include, but is not limited to, your name, contact details, and demographic information. We will only use this information for the purposes for which it was collected, such as providing and improving the functionality of the App, communicating with you, and complying with legal obligations.
            </p></li>
            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Disclosure of Personal Information: </b>
              We may disclose your personal information to third parties in limited circumstances, including service providers, business partners, and legal authorities, as required or permitted by law. We will take reasonable steps to ensure that any third party to whom we disclose your personal information has appropriate security measures in place to protect it.
            </p></li>

            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Security: </b>
              We are committed to ensuring the security of your personal information and will take reasonable precautions to protect it from unauthorized access, use, or disclosure. However, please note that no data transmission over the internet or storage system can be guaranteed to be 100% secure. Therefore, we cannot guarantee the absolute security of your personal information.
            </p></li>

            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Retention of Personal Information: </b>
              We will retain your personal information only for as long as necessary to fulfill the purposes for which it was collected, including any legal or regulatory requirements. When your personal information is no longer required, we will securely dispose of it in accordance with applicable laws and regulations.
            </p></li>

            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Access and Correction: </b>
              You have the right to access and correct any personal information we hold about you. If you would like to request access to or correction of your personal information, please contact us using the contact information provided in the App.
            </p></li>

            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Third-Party Websites and Services: </b>
              The App may contain links to third-party websites or services. We are not responsible for the privacy practices or the content of such websites or services. We encourage you to review the privacy policies of those third parties before providing any personal information.
            </p></li>

            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Children's Privacy: </b>
              The App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you become aware that your child has provided us with personal information without your consent, please contact us immediately, and we will take steps to remove such information from our records.
            </p></li>

            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Changes to the Privacy Act Statement: </b>
              We reserve the right to update or modify this Privacy Act Statement at any time. Any changes will be effective immediately upon posting the updated statement on the App. Your continued use of the App after any modifications to this statement constitutes your acceptance of the updated terms.
            </p></li>
            </ul>
          </Col>
        </Row>
      </Container>
      <ContentFooter />
    </>
  );
};

export default PrivacyPolicy;
