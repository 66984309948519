import React, { useEffect, useState } from "react";
import PopupModal from "../../document_sinner/PopupModal";
import SignersField from "../../document_sinner/SinnerFields";
import { SIGNER_COLORS } from "../../../store/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../store/actions/alertActions";
import { Button } from "@mui/material";
import IconPlus from "../../../assets/sinner/icon_plus.svg";
import style from "../../document_sinner/styles.module.scss";
import { setSigners } from "../../../store/actions/pdfupload.actions";
import { setSigningMetadata } from "../../../store/actions/signingMetadata.actions";

const AddSinnerModal = ({ open, close, isEdit, setDeletedSinnerDraft }) => {
  const [signerFields, setSignerFields] = useState([]);
  const [nextButtonClicked, setNextButtonClicked] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [areYouSigner, setAreYouSigner] = useState(false);
  const [matchedIndex, setMatchedIndex] = useState("");

  const userData = JSON.parse(localStorage.getItem("auth")) || {};
  const sinersList = useSelector((state) => state.pdfUploadReducer).signersList;
  const dispatch = useDispatch();
  let pdfMetadata = useSelector((s) => s.signingMetadataReducer);

  useEffect(() => {
    let flag = 0;
    for (let i = 0; i < signerFields?.length; i++) {
      console.log(signerFields[i].email, userData?.results?.email);
      if (
        signerFields[i].email.toLowerCase() ===
        userData?.results?.email.toLowerCase()
      ) {
        setMatchedIndex(i);
        flag = 1;
        setAreYouSigner(true);
      }
    }
    if (!flag) {
      setAreYouSigner(false);
    }
  }, [signerFields, userData?.results?.email]);

  useEffect(() => {
    if (open) {
      const updatedArray = sinersList.map((obj) => ({
        ...obj,
        fields: obj.fields.map((item) => ({
          ...item,
          isNew: false,
        })),
      }));
      setSignerFields([...updatedArray]);
      // setSignerFields([...sinersList]);
    }
  }, [sinersList, userData?.results?.email, open]);

  let fieldId = new Date().getTime();
  const addSigner = (e) => {
    e.preventDefault();
    if (signerFields.length > 5) {
      dispatch(ShowAlert("Already have 5 sinners", "error"));
      return;
    }

    if (signerFields.length > 0) {
      fieldId = new Date().getTime();
    }
    setSignerFields([
      ...signerFields,
      {
        id: fieldId,
        name: "",
        color: SIGNER_COLORS[signerFields.length],
        email: "",
        isValidated: false,
        fields: [],
        isDeleted: 0,
        DbId: "",
        contact_number: "",
      },
    ]);
  };

  const removeSigner = (id) => {
    const lst = signerFields.filter((item) => {
      if (item.id !== id) {
        return item;
      } else {
        setDeletedSinnerDraft((preVal) => {
          return [...preVal, item];
        });
      }
    });

    setSignerFields(lst);
  };

  const handleNext = () => {
    setNextButtonClicked(new Date().getTime());
    setErrorMsg(null);
    if (!areYouSigner && signerFields.length === 0) {
      setErrorMsg("At least 1 signer is required");
      return;
    }
    let isAllfieldValidated = true;
    signerFields.forEach((e) => {
      if (!e.isValidated) {
        isAllfieldValidated = false;
        if (e.emailError === undefined) {
          e.emailError = "Email is required";
        }

        if (e.phoneError === undefined) {
          e.phoneError = "Contact Number is required";
        }

        if (e.nameError === undefined) {
          e.nameError = "Name is required";
        }
        return;
      }
    });
    if (signerFields.length === 0) {
      setErrorMsg("At least 1 Signer is required");
      return;
    }
    if (isAllfieldValidated) {
      let signers = [...signerFields];
      console.log("here signer ", signers);
      console.log("areYouSigner", areYouSigner);
      if (areYouSigner === true) {
        let body = {
          id: new Date().getTime(),
          name: `${userData?.results?.first_name} ${userData?.results?.last_name}`,
          email: userData?.results?.email.toLowerCase(),
          color: isEdit
            ? signerFields[matchedIndex].color
            : signerFields[matchedIndex].color ||
              SIGNER_COLORS[signers.length + 1],
          isValidated: true,
          isDeleted: 0,
          me: "me",
          fields: isEdit
            ? signerFields[matchedIndex].fields
            : signerFields[matchedIndex].fields || [],
          DbId: isEdit
            ? signerFields[matchedIndex].DbId
            : signerFields[matchedIndex].DbId || "",
        };
        console.log("bodysss", body);
        signers = signers.filter(
          (e) =>
            e.email.toLowerCase() !== userData?.results?.email.toLowerCase()
        );
        signers.push(body);
      } else {
        const temp = signers.filter(
          (e) =>
            e.email.toLowerCase() !== userData?.results?.email.toLowerCase()
        );
        signers = temp;
      }
      // here everything is validated now we have to save signers to redux

      if (signers.length > 0) {
        console.log("inside", signers);
        dispatch(setSigners(signers));

        dispatch(
          setSigningMetadata({
            ...pdfMetadata,
            id: new Date().getTime(),
            sender: {
              name: `${userData?.results?.first_name} ${userData?.results?.last_name}`,
              email: userData?.results?.email.toLowerCase(),
            },
            signers: signers,
          })
        );
        handleClose();
      }
    }
  };

  const handleClose = () => {
    close(false);
  };
  return (
    <PopupModal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={handleClose}
      open={open}
      showCloseIcon={true}
    >
      {signerFields?.map((item, i) => {
        return (
          <SignersField
            index={i + 1}
            item={item}
            key={item.id}
            nextButtonClicked={nextButtonClicked}
            removeSigner={removeSigner}
            signerFields={signerFields}
            setSignerFields={setSignerFields}
            isEdit={true}
          />
        );
      })}
      <div className={`${style.signer_title} d-flex justify-content-end`}>
        <Button className="secondary-btn" onClick={addSigner}>
          <img alt="logo" className="app-logo" src={IconPlus} />
        </Button>
      </div>
      {errorMsg && (
        <div
          className={`${style.signer_title} d-flex justify-content-center text-danger`}
        >
          {errorMsg}
        </div>
      )}

      <div className="row ">
        <div className={`${style.signer_title} col`}>
          <Button className="secondary-btn w-100" onClick={handleNext}>
            Save
          </Button>
        </div>
        <div className={`col`}>
          <Button
            className="secondary-btn w-100 mt-4"
            style={{ border: "1px solid #00cfc5", color: "#00cfc5" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </PopupModal>
  );
};

export default AddSinnerModal;
