import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.scss";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

const AllCandidates = () => {
  const orders = useSelector((state) => state?.rorders);
  const subscription = useSelector((state) => state?.pricing?.subscription);
  const dispatch = useDispatch();

  const total = orders?.singleOrder?.results?.OrderDetail?.reduce(
    (i, e) => i + Number(e?.price),
    0
  );

  return (
    <Box className={styles.parent}>
      <Box className={styles.header}>
        <Typography variant="h4">E-Worecs</Typography>
      </Box>
      <Typography component={"h5"}>
      </Typography>
      <Box className={styles.candidateTable}>
        <Typography component={"h5"}>
          {subscription?.results?.month
            ? " Subscription Details"
            : "Not subscribed right now"}
        </Typography>
        {subscription?.results?.month && (
          <Grid container spacing={2}>
            <Grid item md={12} lg={12} sx={{ width: "100%" }}>
              <Box className={styles.boxTable}>
                <Box className={styles.rowHead}>
                  <Box className={styles.rowBody}>Item Name</Box>
                  <Box className={styles.rowBody}>Start Date</Box>
                  <Box className={styles.rowBody}>End Date</Box>
                  <Box className={styles.rowBody}>Price</Box>
                </Box>

                <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                  <Box className={styles.rowBody}>
                    {subscription?.results?.month} {parseInt(subscription?.results?.month) > 1 ? "Months" : "Month"}
                  </Box>
                  <Box className={styles.rowBody}>
                    {moment(subscription?.results?.start_at).format(
                      "D/MM/YYYY"
                    )}
                  </Box>
                  <Box className={styles.rowBody}>
                    {moment(subscription?.results?.ends_at).format("D/MM/YYYY")}
                  </Box>
                  <Box className={styles.rowBody}>
                    ${subscription?.results?.price}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}

      </Box>
    </Box>
  );
};

export default AllCandidates;
