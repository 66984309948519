import React, { useEffect, useState } from "react";
// import { Form } from "react-bootstrap";

import validator from "validator";

import Minus from "../../assets/sinner/minus.png";
import style from "./styles.module.scss";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";


/****
 * this component render signers form , in document signing flow,
 * eg- adds multiple signers on button click
 */
function SignersField({
  item,
  removeSigner,
  index,
  signerFields,
  setSignerFields,
  isEdit,
}) {
  // const [user] = useLocalStorage(KEY_USER);
  const [error, setError] = useState({
    name: null,
    email: null,
    contact_number: null,
  });
  const [country_code, setCountryCode] = useState("");
  const user = JSON.parse(localStorage.getItem("auth"))?.results || {};

  const handleNameOnBlur = () => {
    if (item.name?.length < 3) {
      setError({ ...error, name: "Name should be at least 3 characters" });
      item.nameError = "Name should be at least 3 characters";
      item.isValidated = false;
    } else {
      setError({ ...error, name: null });
      item.nameError = null;
    }
    if (
      error.email === null &&
      error.name === null &&
      item.name.length > 1 &&
      validator.isEmail(item.email)
    ) {
      item.isValidated = true;
    }
  };
  const handlePhoneOnBlur = () => {
    if (!item.contact_number) {
      setError({ ...error, contact_number: "Contact Number is Required" });
      item.phoneError = "Contact Number is Required";
      item.isValidated = false;
    } else {
      setError({ ...error, contact_number: null });
      item.phoneError = null;
    }
    if (
      error.email === null &&
      error.name === null &&
      item.name.length > 1 &&
      validator.isEmail(item.email) && 
      error.contact_number === null && item.contact_number
    ) {
      item.isValidated = true;
    }
  };

  const handleEmailOnBlur = () => {
    const isValidEmail = validator.isEmail(item.email);
    if (isValidEmail) {
      let isDuplicate = false;

      signerFields.forEach((element) => {
        if (
          element.id !== item.id &&
          element.email.toLowerCase() === item.email.toLowerCase()
        ) {
          isDuplicate = true;
          return;
        }
      });
      if (isDuplicate) {
        setError({ ...error, email: "Duplicate email found" });
        item.emailError = "Duplicate email found";
        return;
      }

      if (!isEdit) {
        if (item.email.toLowerCase() === user.email.toLowerCase()) {
          setError({
            ...error,
            email:
              "The account email can not be registered as an additional signer",
          });
          item.emailError =
            "The account email can not be registered as an additional signer";
          return;
        }
      }

      setError({ ...error, email: null });
      item.emailError = null;
    } else {
      setError({ ...error, email: "Valid Email format is required" });
      item.emailError = "Valid email is required";
      item.isValidated = false;
    }
  };

  useEffect(() => {
    if (
      error.email === null &&
      error.name === null &&
      error.contact_number === null &&
      item?.name?.length > 1 &&
      item?.email?.length > 1 && 
      item?.contact_number?.length > 1
    ) {
      item.isValidated = true;
    } else {
      item.isValidated = false;
    }
  }, [error]);

  return (
    <div className={`${style.form_main_box}`}>
      <h5 className="mb-2">
        {item?.isValidated ? "Selected signer" : `Additional signer`} ({index})
      </h5>
      <form controlId="formBasicEmail">
        <div className={`${style.position_minus}`}>
          <img
            alt="logo"
            className="App-logo"
            onClick={() => removeSigner(item.id)}
            src={Minus}
            style={{ cursor: "pointer" }}
            title="Remove "
          />
        </div>
        <label>Full name</label>
        <input
          onBlur={handleNameOnBlur}
          // disabled={item?.isValidated ? true : false}
          onChange={(e) =>
            setSignerFields((preVal) => {
              preVal[index > 0 ? index - 1 : 0] = {
                ...preVal[index > 0 ? index - 1 : 0],
                name: e.target.value.replace(/\s+/g, ' '),
              };
              return [...preVal];
            })
          }
          value={item?.name}
          placeholder="Enter name"
          type="name"
        />
        {item.nameError && <p className="text-danger">{item.nameError}</p>}
      </form>
      <form controlId="formBasicEmail">
        <label>Email address</label>
        <input
          onBlur={handleEmailOnBlur}
          // onChange={(e) => (item.email = e.target.value)}
          // disabled={item?.isValidated ? true : false}
          onChange={(e) =>
            setSignerFields((preVal) => {
              preVal[index > 0 ? index - 1 : 0] = {
                ...preVal[index > 0 ? index - 1 : 0],
                email: e.target.value.trim(),
              };
              return [...preVal];
            })
          }
          value={item?.email}
          placeholder="Enter email"
          type="email"
        />
        {item.emailError && <p className="text-danger">{item.emailError}</p>}
      </form>
      <form controlId={`formBasicEmail`}>
          <label>Contact Number</label>
          <PhoneInput
          className={style.formCheckPhone}
            name="contact_number"
            placeholder="0414 567 899"
            country="AU"
            initialValueFormat={"national"}
            defaultCountry="AU"
            limitMaxLength={true}
            label={index === 0 ? "Contact Number*" : "Contact Number"}
            value={item.contact_number || ""}
            onBlur={handlePhoneOnBlur}
            onChange={(e) => {
              setSignerFields((preVal) => {
                preVal[index > 0 ? index - 1 : 0] = {
                  ...preVal[index > 0 ? index - 1 : 0],
                  contact_number: e,
                };
                return [...preVal];
              })
            }}
          />
        {item.phoneError && <p className="text-danger">{item.phoneError}</p>}
      </form>
    </div>
  );
}

export default SignersField;
