import React, { useEffect, useState } from "react";

// import TextInput from "../../common/TextInput/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./styless.scss";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
// import CustomSelect from "../../common/Select";
// formik and yup for validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
// import PhoneFeild from "../../common/phoneFeild";
import { useDispatch, useSelector } from "react-redux";
// import {
//   createMember,
//   updateMember,
// } from "../../../store/actions/recruiterMembers";

const InterviewPopup = ({ setOpen, open }) => {
  const [selectValue, setSelectValue] = useState("Member");
  const auth = useSelector((state) => state?.auth);
  const member = useSelector((state) => state?.member);

  const dispatch = useDispatch();

  const initialValues = {
    first_name: member?.editedMember?.results?.first_name || "",
    last_name: member?.editedMember?.results?.last_name || "",
    email: member?.editedMember?.results?.email || "",
    contact_number: member?.editedMember?.results?.contact_number || "",
  };

  const SignupSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    last_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Email Required"),
    contact_number: Yup.string()
      .required("Phone Required")
      .min(11, "Phone Should be more then 11 digits"),
  });
  const [country_code, setCountryCode] = useState(
    member?.editedMember?.results?.country_code || ""
  );

  const onSubmit = async (values, { resetForm }) => {
    const local_data = {
      ...values,
      invitation: "member",
      terms: 1,
      country_code: country_code && country_code.trim() !== "" ?  country_code : "AU",
      type: "recruitment",
      invitation_type: "new_member",
      workspace_id: auth?.results?.workspace_id,
    };
    const local_data2 = {
      ...values,
      uuid: member?.editedMember?.results?.uuid,
      country_code: country_code && country_code.trim() !== "" ?  country_code : "AU",
    };
    // console.log(local_data2);
    // if (member?.editedMember?.results) {
    //   dispatch(updateMember(local_data2, auth?.results?.workspace_id)).then(
    //     () => {
    //       setOpen(false);
    //     }
    //   );
    // } else {
    //   dispatch(createMember(local_data, auth?.results?.workspace_id));
    //   !open && resetForm();
    // }
  };

  const options = [
    { value: "member", label: "Member" },
    { value: "Owner", label: "Owner" },
  ];

  return (
    <Box className="interviewParrent p-3">
      <Typography variant="h3"> Enquiry Submitted</Typography>
      Thank  you for submitting your enquiry, we will get in contact with you as soon as we can
      <Box className="button-primary buttoninterview ">
        <Button
          onClick={() => {
            setOpen(false);
            window.location.reload(false);
          }}
          className="secondary-btn"
        >
          OK
        </Button>
      </Box>
    </Box>
  );
};

export default InterviewPopup;
