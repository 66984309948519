import EndPoints from "../EndPoints";
import axios from "../axiosConfig";
// Backycheck Verification

export const getBackycheckDetails = async (id = null, is_admin = false, is_organisation = false, orgData = {}) => {
  try {
    let url = `${EndPoints.getBackycheckDetails}`;
    const baseUrl = process.env.REACT_APP_API_URL;
    const queryParams = [];

    if (is_organisation) {
      url = `${baseUrl}${EndPoints.getAdminBackycheckDetails}`;
      queryParams.push('is_organisation=true');
    }

    if(is_admin) {
      url = `${baseUrl}${EndPoints.getAdminBackycheckDetails}`;
      queryParams.push('is_admin=true');
    }

    if (id) {
      queryParams.push(`id=${id}`);
    }
    
    if(orgData){
      const query = Object.keys(orgData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(orgData[key])).join('&');
      queryParams.push(query);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    return await axios.get(url);

  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const getBackycheckPolicyForm = async (formtype, id = null, is_organisation = false, orgData = {}) => {
  try {
    let url = `${EndPoints.getBackycheckPolicyForm}`;
    if(is_organisation){
      url = `${EndPoints.getAdminBackycheckPolicyForm}`;
      console.log('formtype, id, is_organisation, orgData', formtype, id, is_organisation, orgData)
    }
    url = `${url}/${formtype}`;

    const queryParams = [];
    if (id) {
      queryParams.push(`id=${id}`);
    }
    if(is_organisation){
      queryParams.push(`is_organisation=true`);
    }
    if(orgData){
      console.log("orgData", orgData);
      const query = Object.keys(orgData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(orgData[key])).join('&');
      queryParams.push(query);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    return await axios.get(url);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const updateBackycheckPolicyForm = async (payload, isAdmin = false, is_organisation = false) => {
  try {
    if (isAdmin || is_organisation) {

      let url = `${EndPoints.updateAdminBackycheckPolicyForm}`;
      if(is_organisation){
        url += `?is_organisation=${is_organisation}`;
      }
      return await axios.post(
        url,
        payload
      );
    } else {
      return await axios.post(
        `${EndPoints.updateBackycheckPolicyForm}`,
        payload
      );
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const getCandidateLicCertificationDetails = async (id) => {
  try {
    let url = `${EndPoints.getCandidateLicCertificationDetails}?id=${id}`;
    const response = await axios.get(url);
    return response?.data;
  } catch (error) {
    return { success: false, message: error.message };
  }
};
