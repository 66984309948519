import { getDocument, GlobalWorkerOptions } from "pdfjs-dist/build/pdf";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import LoadingBar from 'react-top-loading-bar';

import "../../assets/sinner_css/assignFields.css";
import {
  setPdfImagesArray,
  setOriginalPdfArray,
  setSigners,
  setUploadPdf,
} from "../../store/actions/pdfupload.actions";
import { setSigningMetadata } from "../../store/actions/signingMetadata.actions";
import {
  FieldTypes,
  FontList,
  FontSize,
  LOADING_FALSE,
  LOADING_TRUE,
} from "../../store/constants/constants";
import AssignFieldRightMenu from "../../components/common/sinner/AssignFieldRightMenu.jsx";
import AssignFieldsLeftColumn from "../../components/common/sinner/AssignFieldsLeftColumn.jsx";
// import DashboardHeader from '../common/DashboardHeader';
import DropCheckbox from "../../components/common/sinner/DropCheckBox.jsx";
import DropDate from "../../components/common/sinner/DropDate.jsx";
import DropInitials from "../../components/common/sinner/DropInitials.jsx";
import DropInput from "../../components/common/sinner/DropInput.jsx";
import DropSignature from "../../components/common/sinner/DropSignature.jsx";
import style from "../../components/document_sinner/styles.module.scss";
import PopupModal from "../../components/document_sinner/PopupModal.jsx";
import { downloadFile, uploadFile } from "../../utils/s3Config.js";
import {
  EDocTemplateDetails,
  getDraftById,
  getFormCategoryDocBuilder,
  storeSigningService,
  storeTemplate,
  updatedDraft,
  updateTemplate,
} from "../../api/sinner/SinnerApi.js";
import { ShowAlert } from "../../store/actions/alertActions.js";
import { routes } from "../../routes.js";
import { Box, CircularProgress } from "@mui/material";
import Modal from "react-responsive-modal";
import axios from "axios";
import { isMobile } from "react-device-detect";

GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js";
/****
 * this page component add new fields to the metadata.
 */

function AssignFields() {
  const pdfFile = useSelector((state) => state.pdfUploadReducer).pdfFile;

  const navigation = useNavigate();
  const sinersList = useSelector((state) => state.pdfUploadReducer).signersList;
  const [selectedSigner, setSelectedSigner] = useState(sinersList[0]);
  console.log("sinersList", sinersList);
  const [containerSize, setContainerSize] = useState({
    width: 0,
    height: 0,
  });
  const canvasContainerRef = useRef();
  const pdfBgRef = useRef();
  const ref = useRef();
  const childRef = useRef();
  const { state } = useLocation();
  let pdfMetadata = useSelector((s) => s.signingMetadataReducer);
  const singleCandidate = useSelector((state) => state?.singleCandidate);

  const userData = JSON.parse(localStorage.getItem("auth"))?.results || {};

  const [isPdfLoaded, setIsPdfLoaded] = useState(false);

  const dispatch = useDispatch();
  const pdfImagesList = useSelector(
    (state) => state.pdfUploadReducer
  ).pdfImagesList;

  const [selectedInputField, setSelectedInputField] = useState(null);
  const [heightOfPage, setHeightOfPage] = useState(0);
  const [documentImages, setDocumentImages] = useState([]);
  const [offset, setOffset] = useState(0);
  const [draftForm, setDraftForm] = useState({
    title: "",
    desc: "",
  });
  const [draftEditForm, setDraftEditForm] = useState({
    title: "",
    desc: "",
  });
  const [openDraft, setOpenDraft] = useState(false);
  const [errors, setErrors] = useState(null);
  const [pdfMetaUrl, setpdfMetaUrl] = useState("");
  const [deletedSinnerDraft, setDeletedSinnerDraft] = useState([]);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [templateSaveLoader, setTemplateSaveLoader] = useState(false);
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [checkboxGroupData, setCheckboxGroupData] = useState({
    groupName: "",
    totalCheckbox: 1,
    requiredCheckbox: 0,
    isAllRequired: false,
    groupNameError: "",
    totalCheckboxError: "",
    checkboxObject: {},
    checkboxId: new Date().getTime(),
    isEdit: false,
    signer: {},
    eventsNew: [],
    eltObject: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    setSelectedSigner(sinersList[0]);
  }, [sinersList]);

  useEffect(() => {
    dispatch(setPdfImagesArray([]));
    dispatch(setOriginalPdfArray([]));

    if (!state?.uuid && !state?.templateUuid) {
      if (pdfFile === null) {
        navigation(-1);
        return;
      }
      loadPdf({});
    } else if (state?.uuid) {
      if (!pdfMetadata) {
        navigation(-1);
      } else {
        handelGetDraftedDocument();
      }
    } else if (state?.templateUuid) {
      if (sinersList?.length <= 0) {
        navigate(-1);
      }
      handelTemplateDetails();
    }
    window.addEventListener("beforeunload", handleEndConcert);
    return () => {
      //window.removeEventListener('beforeunload', alertUser)
      window.removeEventListener("beforeunload", handleEndConcert);
    };
  }, []);

  const handelGetDraftedDocument = async () => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });
      const response = await getDraftById(state?.uuid);

      if (response?.data?.code === 200) {
        const data = response?.data?.results;
        console.log("testttt", data);

        setDraftEditForm({
          title: data?.title,
          desc: data?.desc,
        });
        const pdfUrl = downloadFile(data?.metadataUrl);
        setpdfMetaUrl(data?.metadataUrl);
        pdfMetadata.signers = data?.signers;

        for (let i = 0; i < data?.signers?.length; i++) {
          if (data?.signers[i].signerEmail === userData?.email) {
            data.signers[i].me = "me";
          }
          console.log("ssssssss", data.signers);
          data.signers[i].email = data.signers[i].signerEmail;
          data.signers[i].DbId = data.signers[i].id;
          data.signers[i].isDeleted = 0;
          data.signers[i].fields = data.signers[0].fields;
          delete data.signers[i].signerEmail;
        }

        loadPdf({ pdfUrl: pdfUrl, signers: data?.signers });
        dispatch(setSigners(data?.signers));
        setSelectedSigner(data?.signers[0]);
        setDeletedSinnerDraft([]);
      } else {
        dispatch(ShowAlert(response?.data?.message, "error"));
      }
    } catch (error) {
      console.error("errorsssss", error);
      dispatch(ShowAlert("Something went wrong..!!", "error"));
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
    }
  };

  const handleClick = () => {
    if (childRef.current) {
      childRef.current.sendDocs();
    }
  };

  const handleEndConcert = (e) => {
    e.preventDefault();
    e.returnValue = "Are you sure you want to leave this page?";
  };

  const handleSignerChange = (e) => {
    sinersList.forEach((element) => {
      if (element.email.toLowerCase() === e.target.value.toLowerCase()) {
        setSelectedSigner(element);
        return;
      }
    });
  };

  const onDragEnd = ({
    x = null,
    selectedSigners = null,
    checkboxGroupData = {},
    index = 0,
  }) => {
    let posFromTop;
    let posfromleft;
    let fieldType;
    console.log("here selectedSigner ", selectedSigner);
    const signerTemp = selectedSigner || selectedSigners;
    if (x) {
      fieldType = x?.target?.outerText?.trim() || {};
      const canvasTopHeight =
        document.getElementsByClassName("header_box")[0].clientHeight +
        canvasContainerRef.current.offsetTop;

      posFromTop = x.pageY - canvasTopHeight;
      posfromleft = x.pageX - canvasContainerRef.current.offsetLeft;
      posfromleft = posfromleft < 0 ? 0 : posfromleft;
    }

    if (selectedSigner) {
      switch (fieldType) {
        case FieldTypes.TEXT_INPUT:
          selectedSigner.fields.push({
            id: new Date().getTime(),
            width: 100,
            background: selectedSigner?.color,
            height: "39px",
            isNew: true,
            positionFromParentLeft: posfromleft,
            positionFromParentTop: posFromTop,
            fieldType: FieldTypes.TEXT_INPUT,
            date: "",
            fontSize: FontSize[0].size,
            fontFamily: FontList[0].fontFamily,
            isRequired: true,
            signer: signerTemp,
            placeholder: "Input Text",
            page: 0,
            pagePositionY: 0,
            maxWidth: 0,
            pageWidth: 0,
          });
          break;
        case FieldTypes.DATES:
          //const signerTemp = selectedSigner;
          selectedSigner.fields.push({
            id: new Date().getTime(),
            width: 100,
            height: "38px",
            isNew: true,
            background: selectedSigner.color,
            positionFromParentLeft: posfromleft,
            positionFromParentTop: posFromTop,
            fieldType: FieldTypes.DATES,
            date: "",
            fontSize: FontSize[0].size,
            fontFamily: FontList[0].fontFamily,
            isRequired: true,
            signer: signerTemp,
            placeholder: "Dates Picker",
            page: 0,
            pagePositionY: 0,
            maxWidth: 0,
            pageWidth: 0,
          });

          break;
        case FieldTypes.INITIALS:
          //const signerTemp = selectedSigner;
          selectedSigner.fields.push({
            id: new Date().getTime(),
            width: 100,
            height: "40px",
            isNew: true,
            background: selectedSigner.color,
            positionFromParentLeft: posfromleft,
            positionFromParentTop: posFromTop,
            fieldType: FieldTypes.INITIALS,
            date: "",
            fontSize: FontSize[0].size,
            fontFamily: FontList[0].fontFamily,
            isRequired: true,
            signer: signerTemp,
            placeholder: "Initials",
            page: 0,
            pagePositionY: 0,
            maxWidth: 0,
            pageWidth: 0,
          });

          break;
        case FieldTypes.SIGNATURE:
          //const signerTemp = selectedSigner;
          selectedSigner.fields.push({
            id: new Date().getTime(),
            width: 200,
            height: 50,
            background: selectedSigner.color,
            isNew: true,
            positionFromParentLeft: posfromleft,
            positionFromParentTop: posFromTop,
            fieldType: FieldTypes.SIGNATURE,
            date: "",
            fontSize: FontSize[0].size,
            fontFamily: FontList[0].fontFamily,
            isRequired: true,
            signer: signerTemp,
            placeholder: "Signature",
            page: 0,
            pagePositionY: 0,
            maxWidth: 0,
            pageWidth: 0,
          });

          break;
        case FieldTypes.CHECKBOX:
        case FieldTypes.CHECKBOXGROUP:
          //const signerTemp = selectedSigner;
          selectedSigner.fields.push({
            id:
              FieldTypes.CHECKBOXGROUP === fieldType
                ? `${checkboxGroupData.checkboxId}-${index}`
                : new Date().getTime(),
            width: 40,
            height: 40,
            positionFromParentLeft: posfromleft,
            positionFromParentTop: posFromTop,
            fieldType: FieldTypes.CHECKBOX,
            isNew: true,
            date: "",
            background: selectedSigner.color,
            fontSize: FontSize[0].size,
            fontFamily: FontList[0].fontFamily,
            isRequired: true,
            signer: signerTemp,
            placeholder: "Check Box",
            page: 0,
            pagePositionY: 0,
            maxWidth: 0,
            pageWidth: 0,
            isCheckboxGroup:
              FieldTypes.CHECKBOXGROUP === fieldType ? true : false,
            groupName:
              FieldTypes.CHECKBOXGROUP === fieldType &&
              checkboxGroupData &&
              checkboxGroupData.groupName
                ? checkboxGroupData.groupName
                : "",
            totalCheckbox:
              FieldTypes.CHECKBOXGROUP === fieldType &&
              checkboxGroupData &&
              checkboxGroupData.totalCheckbox
                ? checkboxGroupData.totalCheckbox
                : 1,
            requiredCheckbox:
              FieldTypes.CHECKBOXGROUP === fieldType &&
              checkboxGroupData &&
              checkboxGroupData.requiredCheckbox
                ? checkboxGroupData.requiredCheckbox
                : 0,
            isAllRequired:
              FieldTypes.CHECKBOXGROUP === fieldType &&
              checkboxGroupData &&
              checkboxGroupData.isAllRequired
                ? checkboxGroupData.isAllRequired
                : false,
            // checkBoxObject: x
          });

          break;

        default:
          break;
      }
    }

    // eltObject
    if (FieldTypes.CHECKBOXGROUP === fieldType) {
      setCheckboxGroupData((prevState) => ({
        ...prevState,
        eltObject: {
          ...prevState.eltObject,
          [checkboxGroupData.checkboxId]: checkboxGroupData,
        },
      }));
    }
    console.log("here meta data ", pdfMetadata);
    dispatch(setSigningMetadata({ ...pdfMetadata }));
    console.log("selectedSignerFields here", selectedSigner?.fields);
    if (selectedSigner?.fields?.length > 0) {
      const selected = selectedSigner.fields[selectedSigner.fields.length - 1];
      // console.log("selected", selected);
      setSelectedInputField(selected);
    }

    for (let i = 0; i < selectedSigner?.fields?.length; i++) {
      onStop(selectedSigner?.fields[i], selectedInputField);
    }
  };

  const loadPdf = async ({ pdfUrl = null, signers = null }) => {
    try {
      // ref.current.continuousStart();

      if (pdfUrl) {
        const response = await axios.get(pdfUrl, {
          responseType: "arraybuffer", // Important for handling binary data
        });

        const blob = new Blob([response.data], { type: "application/pdf" });

        const file = new File([blob], "sample.pdf", {
          lastModified: new Date().getTime(),
        });
        dispatch(setUploadPdf(file));
      }
      const url = pdfUrl ? pdfUrl : URL.createObjectURL(pdfFile);

      const pdf = await getDocument(url).promise;
      const arr = [];
      let totalHeight = 0;
      let totalWidth = 0;
      let pageHeight = 0;

      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const scale = 1.0,
          viewport = page.getViewport({ scale }),
          canvas = document.createElement("canvas"),
          context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        pageHeight = viewport.height;

        totalHeight = totalHeight + canvas.height;
        totalWidth = canvas.width;

        const task = page.render({
          canvasContext: context,
          viewport: viewport,
        });

        await task.promise;
        arr.push({
          imgBase64: canvas.toDataURL(),
          height: canvas.height,
          width: canvas.width,
        });
      }
      console.log(arr);
      setDocumentImages(arr);
      dispatch(setPdfImagesArray([...arr]));
      dispatch(setOriginalPdfArray(pdfFile));
      setHeightOfPage(pageHeight);

      pdfMetadata.pdfImagesList = arr;
      pdfMetadata.containerSize = {
        width: totalWidth,
        height: totalHeight,
      };
      pdfMetadata.pageHeight = pageHeight;
      dispatch(setSigningMetadata({ ...pdfMetadata }));
      setContainerSize({
        width: totalWidth,
        height: totalHeight,
      });
      setIsPdfLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setContainerSize({
        width: pdfBgRef?.current.clientWidth,
        height: pdfBgRef?.current.clientHeight,
      });
    }, 0);
  }, [isPdfLoaded]);

  useEffect(() => {
    if (!openDraft && !openTemplate) {
      setErrors({});
      setDraftForm({
        title: "",
        desc: "",
      });
    } else if (openDraft && state?.uuid) {
      setDraftForm(draftEditForm);
    } else if (openTemplate) {
      getCategoryList();
      if (state?.templateUuid) {
        setDraftForm(draftEditForm);
      }
    }
  }, [draftEditForm, openDraft, state?.uuid, openTemplate]);

  const handleScroll = (event) => {
    setOffset(event.currentTarget.scrollTop);
  };

  const onResize = () => {};
  const onElementDelete = (elt, signer) => {
    setSelectedInputField(null);
    if (elt?.isCheckboxGroup) {
      for (let i = 0; i < elt.totalCheckbox; i++) {
        let id = elt?.id.split("-");
        id = id[0];
        setSelectedInputField(null);
        const temp = signer.fields.filter((e) => `${id}-${i}` !== e.id);
        signer.fields = [...temp];
        dispatch(setSigningMetadata({ ...pdfMetadata }));
      }
    } else {
      setSelectedInputField(null);
      const temp = signer.fields.filter((e) => elt.id !== e.id);
      signer.fields = [...temp];
      dispatch(setSigningMetadata({ ...pdfMetadata }));
    }
  };

  const onStop = (x, selectedInputField) => {
    let pageNumber = 0;
    console.log("selectedSigner?.fields", x);
    let totalHeightofDoc = 0;
    let maxWidth = 0;

    for (let j = 0; j < documentImages.length; j++) {
      if (maxWidth < documentImages[j].width) {
        maxWidth = documentImages[j].width;
      }
    }

    for (let i = 0; i < documentImages.length; i++) {
      const element = documentImages[i];
      const elementHeight = element.height + 1;
      totalHeightofDoc += elementHeight;
      pageNumber++;

      if (x.positionFromParentTop < totalHeightofDoc) {
        break;
      } else {
        continue;
      }
    }
    console.log("documentImages", documentImages, pageNumber);
    x.page = pageNumber;
    x.pagePositionY = totalHeightofDoc - x.positionFromParentTop;
    x.maxWidth = maxWidth;
    x.pageWidth = documentImages[pageNumber - 1].width;
    console.log("insidexx", x);
    setSelectedInputField(x);
  };
  const onFieldItemClick = (elt, signer) => {};

  const handelChange = (e) => {
    setErrors({});
    const { name, value } = e.target || {};
    setDraftForm((preVal) => {
      if (name === "title") {
        preVal = {
          ...preVal,
          [name]: preVal?.title?.length === 0 ? value.trim() : value,
        };
      } else {
        preVal = { ...preVal, [name]: value };
      }

      return { ...preVal };
    });
  };

  const handelSaveDraft = async () => {
    const error = {};
    if (!draftForm?.title) {
      error.title = "Please fill the field";
    } else if (draftForm?.title?.length > 64) {
      error.title = "Title should be maximum 64 characters";
    }
    setErrors({ ...error });
    if (error?.title) {
      return;
    }

    try {
      dispatch({
        type: LOADING_TRUE,
      });
      let uploadFileResponse;
      if (!state?.uuid) {
        const pdfLocation = `media/documentSigning/pdf/draft/${
          userData.id
        }/${new Date().getTime()}`;
        uploadFileResponse = await uploadFile(pdfFile, pdfLocation);
      }
      console.log("sinnerList Here before for", sinersList);
      for (let i = 0; i < sinersList?.length; i++) {
        for (let j = 0; j < sinersList[i]?.fields?.length; j++) {
          delete sinersList[i]?.fields[j].signer;
        }
      }
      let deletedSignersInDraft;
      if (state?.uuid) {
        deletedSignersInDraft = [...deletedSinnerDraft];

        for (let i = 0; i < deletedSignersInDraft?.length; i++) {
          for (let j = 0; j < deletedSignersInDraft[i]?.fields?.length; j++) {
            delete deletedSignersInDraft[i]?.fields[j].signer;
          }
        }
      }

      console.log("sinnerList Here after for", sinersList?.length);
      let body = {
        recruiter_id: userData?.recruiter_id,
        workspace_id: userData?.workspace_id,
        metadataUrl: uploadFileResponse?.location,
        // Spread the FormData properties
        ...draftForm,
        isDraft: 1,
        totalSigners: sinersList?.length,
        totalSigned: 0,
        // Pass the sinersList array directly
        signers: sinersList,
        userName: userData.name,
      };
      if (state?.uuid) {
        body = {
          ...body,
          uuid: state.uuid,
          metadataUrl: pdfMetaUrl,
          deletedSigner: deletedSignersInDraft,
        };
      }

      for (let i = 0; i < body?.signers?.length; i++) {
        for (let j = 0; j < body?.signers?.[i]?.fields?.length; j++) {
          body.signers[i].fields[j].isNew = false;
        }
      }
      let result;
      if (state?.uuid) {
        result = await updatedDraft(body);
      } else {
        result = await storeSigningService(body);
      }
      console.log(result);
      if (result?.data?.code === 200) {
        dispatch(ShowAlert(result?.data?.message, "success"));
        navigate(routes.ALL_DOCUMENTS);
      } else {
        dispatch(ShowAlert(result?.data?.message, "error"));
      }
    } catch (error) {
      console.error(error);
      dispatch(ShowAlert("Something went wrong..!!", "error"));
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
    }
  };
  const handelSaveTemplate = async (sinersList) => {
    let error = {};

    if (!draftForm?.title) {
      error.title = "Please fill the filed";
    } else if (draftForm?.title?.length > 64) {
      error.title = "Title should be maximum 64 characters";
    }
    if (!draftForm?.category) {
      error.category = "Please fill the filed";
    }
    setErrors({ ...error });
    if (error?.title || error?.category) {
      return;
    }
    try {
      setTemplateSaveLoader(true);
      dispatch({
        type: LOADING_TRUE,
      });
      let uploadFileResponse;
      if (!state?.templateUuid) {
        const pdfLocation = `media/documentSigning/pdf/template/${
          userData.id
        }/${new Date().getTime()}`;
        uploadFileResponse = await uploadFile(pdfFile, pdfLocation);
      }
      for (let i = 0; i < sinersList?.length; i++) {
        for (let j = 0; j < sinersList[i]?.fields?.length; j++) {
          delete sinersList[i]?.fields[j].signer;
        }
      }
      let signers = JSON.parse(JSON.stringify(sinersList));
      for (let i = 0; i < signers?.length; i++) {
        for (const key in signers[i]) {
          if (key !== "fields") {
            signers[i][key] = "";
          }
        }
      }

      let body = {
        recruiter_id: userData?.recruiter_id,
        workspace_id: userData?.workspace_id,
        metadataUrl: uploadFileResponse?.location,
        // Spread the FormData properties
        ...draftForm,

        totalSigners: signers?.length,
        totalSigned: 0,
        // Pass the signers array directly
        fields: signers,
        userName: userData.name,
      };
      let deletedSignersInDraft;
      if (state?.uuid) {
        deletedSignersInDraft = [...deletedSinnerDraft];

        for (let i = 0; i < deletedSignersInDraft?.length; i++) {
          for (let j = 0; j < deletedSignersInDraft[i]?.fields?.length; j++) {
            delete deletedSignersInDraft[i]?.fields[j].signer;
          }
        }
      }
      if (state?.templateUuid) {
        body = {
          ...body,
          uuid: state.templateUuid,
          metadataUrl: pdfMetaUrl,
          deletedSigner: deletedSignersInDraft,
        };
      }
      for (let i = 0; i < body?.fields?.length; i++) {
        for (let j = 0; j < body?.fields?.[i]?.fields?.length; j++) {
          body.fields[i].fields[j].isNew = false;
        }
      }
      console.log("bodyss", body);

      let result;
      if (state?.templateUuid) {
        result = await updateTemplate(body);
      } else {
        result = await storeTemplate(body);
      }

      if (result?.data?.code === 200) {
        dispatch(ShowAlert(result?.data?.message, "success"));
        navigate(routes.ALL_DOCUMENTS);
      } else {
        dispatch(ShowAlert(result?.data?.message, "error"));
      }
    } catch (error) {
      console.error(error);
      dispatch(ShowAlert("Something went wrong..!!", "error"));
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
      setTemplateSaveLoader(false);
    }
  };

  const getCategoryList = async () => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });
      let body = {
        isDocBuilder: 1,
      };
      const result = await getFormCategoryDocBuilder(body);
      if (result?.data?.code === 200) {
        setCategoryList(result?.data?.results || []);
      } else {
        dispatch(ShowAlert(result?.data?.message, "error"));
      }
    } catch (error) {
      console.error(error);
      dispatch(ShowAlert("Something went wrong..!!", "error"));
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
    }
  };

  const handelTemplateDetails = async () => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });
      const response = await EDocTemplateDetails(state?.templateUuid);

      if (response?.data?.code === 200) {
        const data = response?.data?.results;

        setDraftEditForm({
          title: data?.title,
          desc: data?.desc,
          category: data?.category,
        });
        console.log("dataaaaaa", data?.title);
        const pdfUrl = downloadFile(data?.metadataUrl);
        setpdfMetaUrl(data?.metadataUrl);
        setDeletedSinnerDraft([]);
        loadPdf({ pdfUrl: pdfUrl });
        for (let i = 0; i < sinersList?.length; i++) {
          if (sinersList[i].signerEmail === userData?.email) {
            sinersList[i].me = "me";
          }

          sinersList[i].DbId = sinersList[i].id;
          sinersList[i].isDeleted = 0;
        }
        for (let i = 0; i < sinersList?.length; i++) {
          for (let j = 0; j < sinersList[i]?.fields?.length; j++) {
            sinersList[i].fields[j].signer = sinersList[i];
          }
        }
      } else {
        dispatch(ShowAlert(response?.data?.message, "error"));
      }
    } catch (error) {
      console.error("errorsssss", error);
      dispatch(ShowAlert("Something went wrong..!!", "error"));
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
    }
  };

  const changeInNumber = (field) => {
    if (
      field?.height &&
      typeof field?.height === "string" &&
      field?.height?.includes("px")
    ) {
      return Number(
        field?.height.split("px")[0] || field?.height.split("PX")[0]
      );
    }
    return field?.height;
  };

  const checkboxGroupModal = (x, isDragEnd = false) => {
    setOpenGroupModal(true);
    setCheckboxGroupData((prev) => ({
      ...prev,
      checkboxObject: isDragEnd ? x.changedTouches[0] : x,
    }));
  };

  const keepSpecificCount = (arr, idToKeep, countToKeep, checkboxGroupData) => {
    let keptCount = 0;
    let totoalCurrentCount = 0;
    let totoalDifffCount = 0;
    const dataArray = arr
      .filter((item) => {
        // Keep the first 'countToKeep' occurrences of the given 'idToKeep'
        if (item.id === idToKeep) {
          totoalCurrentCount++;
        } else {
          totoalDifffCount++;
        }
        if (
          item.id === idToKeep &&
          keptCount < countToKeep &&
          countToKeep > 0
        ) {
          keptCount++;
          return true; // Keep this item
        }
        // Always keep items with different ids
        return item.id !== idToKeep;
      })
      .map((item) => {
        // Modify the kept items by adding 'modified: true'
        if (item.id === idToKeep) {
          return {
            ...item,
            modified: true,
            groupName:
              checkboxGroupData && checkboxGroupData.groupName
                ? checkboxGroupData.groupName
                : "",
            totalCheckbox:
              checkboxGroupData && checkboxGroupData.totalCheckbox
                ? checkboxGroupData.totalCheckbox
                : 1,
            requiredCheckbox:
              checkboxGroupData && checkboxGroupData.requiredCheckbox
                ? checkboxGroupData.requiredCheckbox
                : 0,
            isAllRequired:
              checkboxGroupData && checkboxGroupData.isAllRequired
                ? checkboxGroupData.isAllRequired
                : false,
          };
        }
        return item;
      });
    console.log("keptCount", keptCount, countToKeep, idToKeep);

    if (
      keptCount !== countToKeep &&
      keptCount < countToKeep &&
      countToKeep !== 0
    ) {
      console.log("Data Aray 123456", totoalCurrentCount, totoalDifffCount);
      for (let i = 0; i < countToKeep - keptCount; i++) {
        let incX = 30;
        let incY = 20;
        if (i === 0) {
          incX = incX * dataArray.length;
          incY = incY * dataArray.length;
        }
        const formData = checkboxGroupData.eventsNew[idToKeep];
        formData.pageY = formData.pageY + incX;
        formData.pageX = formData.pageX + incY;
        onDragEnd({
          x: checkboxGroupData.eventsNew[idToKeep],
          checkboxGroupData: checkboxGroupData,
        });
      }
    }
    return { updatedData: dataArray, totoalCurrentCount, totoalDifffCount };
  };

  const handleCheckboxGroupSubmit = (isDelete = false) => {
    let errorGenerated = false;
    if (checkboxGroupData.groupName.trim() === "") {
      setCheckboxGroupData((prev) => ({
        ...prev,
        groupNameError: "Group Name Is Required",
      }));
      errorGenerated = true;
    } else {
      setCheckboxGroupData((prev) => ({
        ...prev,
        groupNameError: "",
      }));
    }
    if (checkboxGroupData.totalCheckbox < 1) {
      setCheckboxGroupData((prev) => ({
        ...prev,
        totalCheckboxError: "Total Checkbox Is Required",
      }));
      errorGenerated = true;
    } else {
      setCheckboxGroupData((prev) => ({
        ...prev,
        totalCheckboxError: "",
      }));
    }
    if (!checkboxGroupData.requiredCheckbox) {
      setCheckboxGroupData((prev) => ({
        ...prev,
        requiredCheckbox: 0,
      }));
    }
    if (errorGenerated) {
      return;
    }

    if (checkboxGroupData.isEdit) {
      setSelectedInputField(null);
      let deleteCount = isDelete ? 0 : checkboxGroupData.totalCheckbox;
      const { updatedData, totoalCurrentCount, totoalDifffCount } =
        keepSpecificCount(
          checkboxGroupData.signer.fields,
          checkboxGroupData.checkboxId,
          deleteCount,
          checkboxGroupData
        );
      // isDelete ||
      if (checkboxGroupData.signer.fields.length > deleteCount) {
        console.log(
          "updatedData, totoalCurrentCount, totoalDifffCount",
          updatedData,
          totoalCurrentCount,
          totoalDifffCount
        );

        const signer = pdfMetadata.signers.find(
          (s) => s.id === selectedSigner.id
        );

        if (signer) {
          signer.fields = updatedData;
        }

        setCheckboxGroupData((prevState) => ({
          ...prevState,
          eltObject: {
            ...prevState.eltObject,
            [checkboxGroupData.checkboxId]: checkboxGroupData,
          },
          signer: {
            ...prevState.signer,
            fields: updatedData,
          },
        }));
        dispatch(setSigningMetadata({ ...pdfMetadata }));
      }
    } else {
      const checkId = new Date().getTime();
      setCheckboxGroupData((prev) => ({
        ...prev,
        checkboxId: checkId,
      }));
      if (checkboxGroupData.totalCheckbox > 0) {
        for (let i = 0; i < checkboxGroupData.totalCheckbox; i++) {
          // checkboxObject
          setCheckboxGroupData((prevState) => ({
            ...prevState,
            checkboxObject: {
              ...prevState.checkboxObject,
              pageY: prevState.checkboxObject.pageY + 30,
              pageX: prevState.checkboxObject.pageX + 20,
            },
          }));
          const formData = checkboxGroupData.checkboxObject;

          onDragEnd({
            x: formData,
            checkboxGroupData: checkboxGroupData,
            index: i,
          });
          setCheckboxGroupData((prevState) => ({
            ...prevState,
            eventsNew: {
              ...prevState.eventsNew,
              [checkboxGroupData.checkboxId]: checkboxGroupData.checkboxObject,
            },
          }));
        }
      }
    }
    setCheckboxGroupData((prevState) => ({
      ...prevState,
      groupName: "",
      totalCheckbox: 1,
      requiredCheckbox: 0,
      isAllRequired: false,
      groupNameError: "",
      totalCheckboxError: "",
      checkboxObject: {},
      checkboxId: new Date().getTime(),
      isEdit: false,
      signer: {},
    }));
    setSelectedInputField(null);
    setOpenGroupModal(false);
  };
  return (
    <>
      <div className="header_box"></div>
      {/* <DashboardHeader /> */}
      {/* <LoadingBar color="#f11946" ref={ref} /> */}

      <div
        className={`${style.pdf_format}`}
        style={{ height: "calc(100vh - 255px)" }}
      >
        <AssignFieldsLeftColumn
          handleSignerChange={handleSignerChange}
          onDragEnd={onDragEnd}
          ref={childRef}
          sinersList={sinersList}
          isEdit={state?.uuid ? true : false}
          uuid={state?.uuid}
          checkboxGroupModal={checkboxGroupModal}
        />
        <div
          className="dashboard_right right_column"
          id="scrollableaxis"
          onScroll={handleScroll}
        >
          <div
            className={`${style.innerBox}`}
            style={{
              height: "fit-content",
              overflowY: "hidden",
            }}
          >
            {pdfImagesList.length === 0 && <p>Loading ...</p>}
            <div
              className="pdfWrapper"
              id="pdfScroll"
              style={{
                border: "1px solid",
                width: "fit-content",
                margin: "auto",
              }}
            >
              <div
                className="canvas"
                ref={canvasContainerRef}
                style={{
                  position: "relative",
                  height: containerSize.height,
                  width: containerSize.width,
                }}
              >
                <div className="pdfBg" ref={pdfBgRef}>
                  {pdfImagesList?.length > 0 &&
                    pdfImagesList?.map((m, i) => (
                      <img
                        alt="pdf"
                        key={i}
                        src={m.imgBase64}
                        style={{
                          pointerEvents: "none",
                          borderBottom: "solid 1px",
                          maxWidth: "none",
                        }}
                      ></img>
                    ))}
                </div>

                {pdfMetadata?.signers?.map((signer) => {
                  return signer?.fields?.map((elt, i) => {
                    let FieldComponent = null;
                    switch (elt.fieldType) {
                      case FieldTypes.TEXT_INPUT:
                        FieldComponent = DropInput;
                        break;
                      case FieldTypes.DATES:
                        FieldComponent = DropDate;
                        break;
                      case FieldTypes.INITIALS:
                        FieldComponent = DropInitials;
                        break;
                      case FieldTypes.SIGNATURE:
                        FieldComponent = DropSignature;
                        break;
                      case FieldTypes.CHECKBOX:
                        FieldComponent = DropCheckbox;
                        break;
                      default:
                        <></>;
                    }

                    console.log(
                      "selectedInputField 123",
                      elt.positionFromParentTop,
                      elt
                    );
                    // y: elt?.isNew ?  ((elt.pagePositionY + elt.positionFromParentTop) * -1)   : elt.positionFromParentTop,
                    return (
                      <FieldComponent
                        defaultPosition={{
                          x:
                            parseInt(containerSize.width) <
                              parseInt(elt.positionFromParentLeft) +
                                parseInt(elt.width) && !state?.templateUuid
                              ? parseInt(containerSize.width) -
                                parseInt(elt.width)
                              : elt.positionFromParentLeft,
                          // y: elt?.isNew ? (isMobile || elt.positionFromParentTop < 0 || elt.positionFromParentTop + offset > containerSize.height ? (elt.positionFromParentTop + offset > containerSize.height ? containerSize.height - changeInNumber(elt) : offset) : (offset + elt.positionFromParentTop)) : elt.positionFromParentTop,
                          y: elt?.isNew
                            ? isMobile ||
                              elt.positionFromParentTop < 0 ||
                              elt.positionFromParentTop + offset >
                                containerSize.height
                              ? elt.positionFromParentTop + offset >
                                containerSize.height
                                ? containerSize.height - changeInNumber(elt)
                                : offset
                              : offset + elt.positionFromParentTop
                            : elt.positionFromParentTop,
                        }}
                        elt={elt}
                        id={elt.id}
                        key={elt.id}
                        onElementDelete={onElementDelete}
                        onFieldItemClick={onFieldItemClick}
                        onResize={onResize}
                        onStop={onStop}
                        selectedInputField={selectedInputField}
                        setSelectedInputField={setSelectedInputField}
                        signer={signer}
                      />
                    );
                  });
                })}
              </div>
            </div>
          </div>
        </div>
        <AssignFieldRightMenu
          selectedInputField={selectedInputField}
          sendData={handleClick}
          setSelectedInputField={setSelectedInputField}
          setOpenDraft={setOpenDraft}
          isEdit={state?.uuid ? true : false}
          setDeletedSinnerDraft={setDeletedSinnerDraft}
          setOpenTemplate={setOpenTemplate}
        />

        <PopupModal
          closeOnEsc={false}
          closeOnOverlayClick={false}
          onClose={() => setOpenDraft(false)}
          open={openDraft}
          showCloseIcon={false}
        >
          <div
            className="optionContainer iconPopups"
            style={{ paddingTop: 30 }}
          >
            <div className="margin_box popup">
              <form>
                <div className="form-group">
                  <label htmlFor="forDoctitle">Document title*</label>
                  <input
                    aria-describedby="forDoctitle"
                    autoComplete="off"
                    className="form-control text-dark"
                    id="forDoctitle"
                    name="title"
                    onChange={handelChange}
                    placeholder="Enter document title"
                    value={draftForm?.title}
                    type="text"
                  />
                  <small className="form-text text-danger" id="emailHelp">
                    {errors && errors.title && <>{errors.title}</>}
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">
                    Description
                  </label>
                  <textarea
                    className="form-control text-dark"
                    id="exampleFormControlTextarea1"
                    name="desc"
                    onChange={handelChange}
                    placeholder="Description"
                    value={draftForm?.desc}
                    rows={3}
                  />
                </div>
              </form>
            </div>
            <div
              className="button_box"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <button onClick={handelSaveDraft} type="button">
                {state?.uuid ? "Update" : "Save"}
              </button>
              <button onClick={() => setOpenDraft(false)} type="button">
                Cancel
              </button>
            </div>
          </div>
        </PopupModal>
        {/* template starts */}
        <PopupModal
          closeOnEsc={false}
          closeOnOverlayClick={false}
          onClose={() => setOpenTemplate(false)}
          open={openTemplate}
          showCloseIcon={false}
        >
          <div
            className="optionContainer iconPopups"
            style={{ paddingTop: 30 }}
          >
            <div className="margin_box popup">
              <form>
                <div className="form-group">
                  <label htmlFor="forDoctitle">Template title*</label>
                  <input
                    aria-describedby="forDoctitle"
                    autoComplete="off"
                    className="form-control text-dark"
                    id="forDoctitle"
                    name="title"
                    onChange={handelChange}
                    placeholder="Enter document title"
                    value={draftForm?.title}
                    type="text"
                  />
                  <small className="form-text text-danger" id="emailHelp">
                    {errors && errors.title && <>{errors.title}</>}
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">
                    Description
                  </label>
                  <textarea
                    className="form-control text-dark"
                    id="exampleFormControlTextarea1"
                    name="desc"
                    onChange={handelChange}
                    placeholder="Description"
                    value={draftForm?.desc}
                    rows={3}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">
                    Template Category*
                  </label>
                  <select
                    name="category"
                    id=""
                    onChange={handelChange}
                    className="form-control text-dark"
                  >
                    <option
                      style={{ display: "hidden" }}
                      value=""
                      disabled
                      selected={
                        state?.templateUuid && draftForm?.category
                          ? false
                          : true
                      }
                    >
                      Select category
                    </option>
                    {categoryList?.length > 0 &&
                      categoryList?.map((item) => {
                        return (
                          <option
                            value={item?.id}
                            key={item?.id}
                            selected={
                              state?.templateUuid
                                ? draftForm?.category === item?.id
                                : false
                            }
                          >
                            {item?.name}
                          </option>
                        );
                      })}
                  </select>
                  <small className="form-text text-danger" id="emailHelp">
                    {errors && errors.category && <>{errors.category}</>}
                  </small>
                </div>
              </form>
            </div>
            <div
              className="button_box"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <button
                disabled={templateSaveLoader}
                onClick={() => handelSaveTemplate(sinersList)}
                type="button"
              >
                {state?.templateUuid ? "Update" : "Save"}
              </button>
              <button
                disabled={templateSaveLoader}
                onClick={() => setOpenTemplate(false)}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </PopupModal>
        {/* template ends */}
      </div>
      <Modal
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => {}}
        open={singleCandidate?.loading}
        showCloseIcon={false}
      >
        <div
          className="optionContainer iconPopups"
          style={{ minWidth: 200, paddingTop: 30 }}
        >
          <div className="margin_box popup text-center">
            {/* <img
              src={LoaderGif}
              style={{ margin: "auto", width: 50, height: 50 }}
            /> */}
            {singleCandidate?.loading && (
              <Box className="loader">
                <CircularProgress />
              </Box>
            )}
          </div>
          <div className="button_box"></div>
        </div>
      </Modal>

      <Modal
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => setOpenGroupModal(false)}
        open={openGroupModal}
        styles={{ modal: { width: isMobile ? "90%" : "50%" } }}
      >
        <div className="optionContainer iconPopups">
          <div className="margin_box popup">
            <div className="form-group">
              <label htmlFor="checkboxGroupTitle">
                Checkbox group name <span className="text-danger">*</span>
              </label>
              <input
                aria-describedby="checkboxGroupTitle"
                autoComplete="off"
                className="form-control text-dark"
                id="checkboxGroupTitle"
                name="title"
                onChange={(e) => {
                  setCheckboxGroupData((prevState) => ({
                    ...prevState,
                    groupName: e.target.value,
                    groupNameError:
                      e.target.value && e.target.value.trim() !== ""
                        ? ""
                        : "Group Name Is Required",
                  }));
                }}
                value={checkboxGroupData.groupName}
                placeholder="Enter Group Title"
                type="text"
              />
              <small className="form-text text-danger" id="emailHelp">
                {checkboxGroupData && checkboxGroupData.groupNameError && (
                  <>{checkboxGroupData.groupNameError}</>
                )}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="checkboxCount">
                How many checkboxes are required for the total amount of
                choices? <span className="text-danger">*</span>
              </label>
              <input
                aria-describedby="checkboxCount"
                autoComplete="off"
                className="form-control text-dark"
                id="checkboxCount"
                name="checkbox_count"
                onChange={(e) => {
                  let count =
                    e.target.value && parseInt(e.target.value) > 0
                      ? parseInt(e.target.value)
                      : "";
                  setCheckboxGroupData((prevState) => ({
                    ...prevState,
                    totalCheckbox: count,
                    requiredCheckbox: checkboxGroupData.isAllRequired
                      ? count
                      : 0,
                  }));
                }}
                placeholder="Enter No of Checkbox"
                type="number"
                min={1}
                value={checkboxGroupData.totalCheckbox}
              />
              <small className="form-text text-danger" id="emailHelp">
                {checkboxGroupData && checkboxGroupData.totalCheckboxError && (
                  <>{checkboxGroupData.totalCheckboxError}</>
                )}
              </small>
            </div>
            <div className="form-group m-0">
              <label className="m-0">
                Out of the total checkbox amount, what is the mandatory amount
                of checkboxes which have to be answered?{" "}
                <span className="text-danger">*</span>
              </label>
            </div>
            <div className="form-group m-0">
              <div
                className="m-0 mb-2"
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  gap: "0.5rem",
                  height: "fit-contant",
                }}
              >
                <div>
                  <input
                    className=""
                    name="isPasswordRequired"
                    onChange={(e) => {
                      setCheckboxGroupData((prev) => ({
                        ...prev,
                        isAllRequired: e.target.checked,
                        requiredCheckbox: checkboxGroupData.totalCheckbox,
                      }));
                    }}
                    style={{ margin: 0, left: "15px" }}
                    type="checkbox"
                    id="requiredCheckboxCountBox"
                    checked={checkboxGroupData.isAllRequired}
                  />
                </div>
                <div>
                  <label
                    htmlFor="requiredCheckboxCountBox"
                    style={{ margin: 0 }}
                  >
                    All Checkboxes
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group m-0">
              <input
                disabled={checkboxGroupData.isAllRequired}
                aria-describedby="requiredCheckboxCount"
                autoComplete="off"
                className="form-control text-dark"
                id="requiredCheckboxCount"
                name="required_checkbox_count"
                placeholder="Enter No of Checkboxes Required"
                type="number"
                onChange={(e) => {
                  let count = e.target.value ? parseInt(e.target.value) : "";
                  if (
                    count !== "" &&
                    count > parseInt(checkboxGroupData.totalCheckbox)
                  ) {
                    count = parseInt(checkboxGroupData.totalCheckbox);
                  }
                  setCheckboxGroupData((prev) => ({
                    ...prev,
                    requiredCheckbox: count,
                  }));
                }}
                value={checkboxGroupData.requiredCheckbox}
              />
            </div>
          </div>
          <div className="d-flex" style={{ justifyContent: "space-evenly" }}>
            <div className="button_box">
              <button onClick={() => handleCheckboxGroupSubmit()} type="button">
                Save
              </button>
            </div>
            {checkboxGroupData.isEdit && (
              <div className="button_box">
                <button
                  onClick={() => handleCheckboxGroupSubmit(true)}
                  type="button"
                >
                  Delete Group
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
export default AssignFields;
