import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import Navbar from "../navbar/Navbar";
import style from "./style.module.scss";
import Content_footer from "../content-footer/Content_footer";
import JOBS from "../../../assets/icons/jobsposticon.svg";
import phone from "../../../assets/icons/job_location.svg";
import iconLocation from "../../../assets/icons/job_phone.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getHomeJobs,
  getSingleJob,
} from "../../../store/actions/homeJobActions";
import { useNavigate, useLocation } from "react-router-dom";
import ModeCustom from "./model/index";
import LoginModel from "./login/index";
import ApplyModel from "./confirmBox/index";
import { ShowAlert } from "../../../store/actions/alertActions";
import SEO from "../../meta-tags";
import Model from "./model/index";

const JobDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location?.state?.id;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [redirect, setRedirect] = useState(0);

  const dispatch = useDispatch();
  const homeJobs = useSelector((state) => state?.hjob);

  const auth = useSelector((state) => state?.auth);
  const reqJobs = useSelector((state) => state?.rjobs);
  useEffect(() => {
    if (homeJobs?.singleJob?.results?.is_archived)
      setRedirect(homeJobs?.singleJob?.results?.is_archived);
  }, [homeJobs]);

  useEffect(() => {
    dispatch(getSingleJob(location?.state?.slug));
  }, []);

  useEffect(() => {
    if (redirect === 1) {
      dispatch(ShowAlert("This Job is Unable to access", "error"));
      navigate(`/jobs/search/`);
    }
  }, [redirect]);

  // console.log("homeJobs", homeJobs);

  const [showMore, setShowMore] = useState({
    cata: false,
    exp: false,
    salary: false,
    type: false,
  });
  // console.log(homeJobs)
  const handleSubmit = () => {
    if (auth?.results?.access_token) {
      if (homeJobs?.singleJob?.results?.user_apply === 1) {
        return dispatch(ShowAlert("User already applied", "error"));
      }
      setOpen2(true);
      return;
    }
    setOpen(true);
  };

  return (
    <>
      <SEO title="Job Detail"></SEO>
      <Model open={open2} setOpen={setOpen2}>
        <ApplyModel open={open2} setOpen={setOpen2} />
      </Model>
      <ModeCustom open={open} setOpen={setOpen}>
        <LoginModel open={open} setOpen={setOpen} />
      </ModeCustom>
      <Navbar />
      <Container>
        <Box className={style.parent}>
          <Grid container>
            <Grid item xs={12}>
              <Box className={style.main_heading}>
                <Typography
                  variant="h1"
                  className="latest_jobs_main_box_search_headingh4"
                >
                  Recent Job Openings
                </Typography>
                {/* <Typography variant="h6">
                  Automated reference checking service for professionals, by
                  professionals
                </Typography> */}
              </Box>
            </Grid>
          </Grid>
          <Box className={style.job_box_main}>
            <Grid container className={style.job_box}>
              <Grid item xs={12} sm={3}>
                <Box className={style.job_box_img}>
                  <img
                    src={`${process.env.REACT_APP_URL}${homeJobs?.singleJob?.results?.logo}`}
                    alt=""
                  />
                </Box>
              </Grid>
              <Grid item sm={9} className={style.job_box_right}>
                <Box>
                  <Grid container>
                    <Grid item xs={12} className={style.job_box_right_head}>
                      <Box className="d-flex">
                        <Typography
                          style={{ fontWeight: 600, paddingRight: "12px" }}
                          variant="h6"
                        >
                          Company Name:
                        </Typography>
                        <Typography variant="h6">
                          {homeJobs?.singleJob?.results?.company_name}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container className={style.job_box_heading}>
                    <Grid item xs={12} sm={6}>
                      <Box className={style.job_box_left}>
                        <Box className="d-flex">
                          <Typography
                            style={{ fontWeight: 600, paddingRight: "12px" }}
                            variant="h6"
                          >
                            Job Title:
                          </Typography>
                          <Typography variant="h6">
                            {homeJobs?.singleJob?.results?.job_title}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box className={style.job_box_heading_right}>
                        <Typography variant="h5">
                          {homeJobs?.singleJob?.results?.country_symbol
                            ?.currency_symbol +
                            homeJobs?.singleJob?.results?.min_salary}
                          <span>/</span>
                          {homeJobs?.singleJob?.results?.job_salary_type?.name}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container className={style.job_btn_box}>
                    <Grid item xs={12} sm={6}>
                      <Grid container className={style.job_btn_box_heading}>
                        <Grid item xs={12} sm={6}>
                          <Box
                            main
                            sx={{ display: "flex" }}
                            className={style.job_btn_box_heading}
                          >
                            <Box paddingRight={1}>
                              <img src={iconLocation} alt="" />
                            </Box>
                            <Box>
                              <Typography variant="h6">
                                {homeJobs?.singleJob?.results?.address}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <Box
                            main
                            sx={{ display: "flex" }}
                            className={style.job_btn_box_heading_left}
                          >
                            <Box paddingRight={1}>
                              <img src={phone} alt="" />
                            </Box>
                            <Box>
                              <Typography variant="h6">
                                {
                                  homeJobs?.singleJob?.results
                                    ?.recruiter_details?.mobile
                                }
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Box className={style.job_btn}>
                        {auth?.results?.type !== "recruitment" && (
                          <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={
                              homeJobs?.singleJob?.results?.user_apply === 1
                            }
                          >
                            {homeJobs?.singleJob?.results?.user_apply === 1
                              ? "Applied"
                              : "Apply Now"}
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container justifyContent="center">

              <Grid item sm={12}>
                <Box marginTop={3} className={style.jobs_details_right_box}>
                  <Box>
                    <Typography variant="h2">Summary</Typography>
                    {/* <Divider /> */}
                  </Box>
                  <Box>
                    <table>
                      <tr>
                        <th>Job Type:</th>
                        <td>{homeJobs?.singleJob?.results?.job_type?.name}</td>
                      </tr>
                      <tr>
                        <th>Category:</th>
                        <td>
                          {homeJobs?.singleJob?.results?.job_category?.name}
                        </td>
                      </tr>
                      <tr>
                        <th>Posted:</th>
                        <td>{homeJobs?.singleJob?.results?.created_at}</td>
                      </tr>
                      <tr>
                        <th>Salary Type:</th>
                        <td>
                          {homeJobs?.singleJob?.results?.job_salary_type?.name}
                        </td>
                      </tr>
                      <tr>
                        <th>Qualification:</th>
                        <td>{homeJobs?.singleJob?.results?.qualification}</td>
                      </tr>
                      <tr>
                        <th>Experience:</th>
                        <td>
                          {homeJobs?.singleJob?.results?.job_experience?.name}
                        </td>
                      </tr>
                      <tr>
                        <th>Description:</th>
                        <td>{homeJobs?.singleJob?.results?.job_description}</td>
                      </tr>
                      {homeJobs?.singleJob?.results?.input_fields && homeJobs?.singleJob?.results?.input_fields[0].label !==
                        null &&
                        homeJobs?.singleJob?.results?.input_fields.map(
                          (item) => {
                            return (
                              <tr item sx={12}>
                                <th>{item.label}:</th>
                                <td>{item.value}</td>
                              </tr>
                            );
                          }
                        )}
                    </table>
                    <List container spacing={2}>
                      {/* <ListItem item sx={12}>
                        <span>Job Type:</span>
                        <span>
                          {homeJobs?.singleJob?.results?.job_type?.name}
                        </span>
                      </ListItem>
                      <ListItem item sx={12}>
                        <span>Category:</span>
                        <span>
                          {homeJobs?.singleJob?.results?.job_category?.name}
                        </span>
                      </ListItem>
                      <ListItem item sx={12}>
                        <span>Posted:</span>
                        <span>{homeJobs?.singleJob?.results?.created_at}</span>
                      </ListItem> */}
                      {/* <ListItem item sx={6}>
                          Category{" "}
                          <span style={{ paddingLeft: "40px" }}>:</span>{" "}
                          Development
                        </ListItem> */}
                      {/* <ListItem item sx={12}>
                        <span>Salary Type:</span>
                        <span>
                          {homeJobs?.singleJob?.results?.job_salary_type?.name}
                        </span>
                      </ListItem> */}
                      {/* <ListItem item sx={6}>
                          Gender <span style={{ paddingLeft: "55px" }}>:</span>{" "}
                          Male or Female
                        </ListItem> */}
                      {/* <ListItem item sx={12}>
                        <span>Qualification:</span>
                        <span>
                          {homeJobs?.singleJob?.results?.qualification}
                        </span>
                      </ListItem>
                      <ListItem item sx={12}>
                        <span>Experience:</span>
                        <span>
                          {homeJobs?.singleJob?.results?.job_experience?.name}
                        </span>
                      </ListItem> */}
                      {/* <ListItem item sx={12}>
                          Applied <span style={{ paddingLeft: "20px" }}>:</span>
                          {"26 Applicant"}
                        </ListItem> */}
                      {/* <ListItem item sx={6}>
                          Application End <span>:</span> 20 November, 2021
                        </ListItem> */}
                      {/* <ListItem item>
                        <p>Description:</p>
                        <p>{homeJobs?.singleJob?.results?.job_description}</p>
                      </ListItem> */}

                      {/* {homeJobs?.singleJob?.results?.input_fields[0].label !==
                        null &&
                        homeJobs?.singleJob?.results?.input_fields.map(
                          (item) => {
                            return (
                              <ListItem item sx={12}>
                                <span>{item.label}:</span>
                                <span>{item.value}</span>
                              </ListItem>
                            );
                          }
                        )} */}
                    </List>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Content_footer />
    </>
  );
};

export default JobDetails;
