import { Box, Grid, Typography } from "@mui/material";

import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../routes";
import { resetPricing } from "../../../store/actions/pricingActions";
import styles from "./styles.module.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { isMobile } from "react-device-detect";
import { getCandidateLicCertificationDetails } from "../../../api/backycheck/backycheck.class";

const Checkout = ({
  setCart,
  setLicenseValue,
  setLicense,
  setRadio,
  setBilling,
  setUpdateValues,
}) => {
  const { id } = useParams();
  const { user_id } = useParams();
  const { user_type } = useParams();
  console.log("here user_id", user_id);
  console.log("here user_type ", user_type);
  const [data, setData] = useState(null);
  const [Open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const payment = useSelector((state) => state?.pricing);

  const navigate = useNavigate();
  // Fetch data when id is available
  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          try {
            const response = await getCandidateLicCertificationDetails(id).then(
              function (res) {
                return res;
              }
            );
            console.log("response ", response?.result);
            setData(response?.result);
          } catch (error) {
            console.log(error);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [id]);
  useEffect(() => {
    if (payment?.paymentDone?.success) {
      setOpen(false);
      navigate(routes?.DASHBOARD);
      dispatch(resetPricing());
      setCart({});
      setLicenseValue({});
      setLicense([]);
      setBilling({ address: "", city: "", state: "", country: "" });
      setRadio({});
    }
  }, [payment?.paymentDone]);

  useEffect(() => {
    if (payment?.updateSubscription?.success) {
      setOpen(false);
      navigate(routes?.DASHBOARD);
      dispatch(resetPricing());
      setUpdateValues({
        label: "",
        value: "",
        interval_count: "",
        product: "",
        price: "00",
      });
    }
  }, [payment?.updateSubscription]);

  return (
    <Box className={styles.gridParrent}>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        {/* <img src="path_to_logo" alt="Logo" style={{ maxWidth: "100px" }} />{" "} */}
        {/* Add your logo here */}
        <Typography
          className="logo_head"
          variant="h4"
          onClick={() => navigate("/")}
        >
          Worecs.
        </Typography>
      </div>
      <Typography component="h2">Check Summary</Typography>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <tbody>
            <tr>
              <th style={{ textAlign: "left", padding: "8px" }}>Check Name</th>
              <td
                style={{
                  textAlign: "right",
                  padding: "8px",
                  whiteSpace: "normal", // ✅ Allow text wrapping
                  wordBreak: "break-word", // ✅ Break long words
                  maxWidth: "150px", // ✅ Set a max width to force wrapping (adjust as needed)
                }}
              >
                {data?.industry_certification_license?.name}
              </td>
            </tr>
            <tr>
              <th style={{ textAlign: "left", padding: "8px" }}>Subtotal</th>
              <td style={{ textAlign: "right", padding: "8px" }}>
                ${data?.industry_certification_license?.price}
              </td>
            </tr>
            <tr>
              <th style={{ textAlign: "left", padding: "8px" }}>GST(10%)</th>
              <td style={{ textAlign: "right", padding: "8px" }}>
                $
                {(data?.industry_certification_license?.price * 0.1).toFixed(2)}
              </td>
            </tr>
            <tr>
              <th style={{ textAlign: "left", padding: "8px" }}>Total</th>
              <td style={{ textAlign: "right", padding: "8px" }}>
                $
                {(
                  parseFloat(data?.industry_certification_license?.price) +
                  data?.industry_certification_license?.price * 0.1
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Typography component="h2" style={{ padding: "1rem" }}>
        Card Details
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ width: "100%", padding: "0px 1rem 1rem 1rem" }}
      >
        <Grid item xs={12} sx={{ maxWidth: "100%" }}>
          <Elements
            stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)}
          >
            <CheckoutForm
              data={data}
              setOpen={setOpen}
              userType={user_type}
              userId={user_id}
            />
          </Elements>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Checkout;
