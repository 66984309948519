import { Box, Grid, Typography } from "@mui/material";

import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../../../routes";
import { resetPricing } from "../../../../../../store/actions/pricingActions";
import styles from "./styles.module.scss";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const Checkout = ({
  setCart,
  setOpen,
  setLicenseValue,
  setLicense,
  setRadio,
  setBilling,
  setUpdateValues,
  data,
  setUpdation,
  isCandidate
}) => {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state?.pricing);
  const navigate = useNavigate();

  useEffect(() => {
    if (payment?.paymentDone?.success) {
      setOpen(false);
      navigate(routes?.DASHBOARD);
      dispatch(resetPricing());
      setCart({});
      setLicenseValue({});
      setLicense([]);
      setBilling({ address: "", city: "", state: "", country: "" });
      setRadio({});
    }
  }, [payment?.paymentDone]);

  useEffect(() => {
    if (payment?.updateSubscription?.success) {
      setOpen(false);
      navigate(routes?.DASHBOARD);
      dispatch(resetPricing());
      setUpdateValues({
        label: "",
        value: "",
        interval_count: "",
        product: "",
        price: "00",
      });
    }
  }, [payment?.updateSubscription]);

  return (
    <Box className={styles.gridParrent} style={{padding: '0px'}}>
      <Typography component="h2">Check Summary</Typography>
      <table style={{ width: "100%", marginBottom: "20px" }}>
        <tbody>
          <tr>
            <th>Check Name</th>
            <td>{data?.industry_certification_license?.name}</td>
          </tr>
          <tr>
            <th>Subtotal</th>
            <td>${data?.industry_certification_license?.price}</td>
          </tr>
          <tr>
          <th>GST(10%)</th>
          <td>${(data?.industry_certification_license?.price * 0.10).toFixed(2)}</td>
          </tr>
          <tr>
            <th>Total</th>
            <td>${(parseFloat(data?.industry_certification_license?.price) +  (data?.industry_certification_license?.price * 0.10)).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <Typography component="h2">Card Details</Typography>
        <Grid item md={12} lg={12}>
        <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)}>
          <CheckoutForm 
            data={data}
            setOpen={setOpen}
            setUpdation={setUpdation}
            isCandidate={isCandidate}
              // updateValues={updateValues}
              // payment={payment}
              // billing={billing}
              // radio={radio}
              // cart={cart}
              // licenseValue={licenseValue}
              // sub={sub}
            />
          </Elements>
        </Grid>
    </Box>
  );
};

export default Checkout;
