import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress, // import CircularProgress from MUI
} from "@mui/material";
import { useEffect, useState } from "react"; // import useState
import { ShowAlert } from "../../../store/actions/alertActions";
import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";

export default function FormDialog({
  deleteOpen,
  setDeleteOpen,
  item,
  workspaceId,
  deleteFunc,
  deletedName,
  restFucntion,
  candidateId,
  interviewSection,
  deleteName,
}) {
  const [loading, setLoading] = useState(false); // add loading state
  // const dispatch = useDispatch();

  useEffect(() => {
    if (deletedName?.deletedItem?.success === true) {
      setDeleteOpen(false);
      restFucntion && restFucntion();
      // dispatch(ShowAlert("Deleted", "success"));
    } else if (deletedName?.deletedItem?.success === false) {
      setDeleteOpen(false);
      restFucntion && restFucntion();
      // dispatch(ShowAlert("Something Went Wrong", "error"));
    }
    // setLoading(false); // set loading to false after deletion completes
  }, [deletedName, deletedName?.deletedItem]);

  const handleDelete = async () => {
    setLoading(true); // set loading to true before deletion starts
    if (interviewSection) {
      await deleteFunc(item?.uuid, {
        workspace_id: workspaceId,
        candidate_id: candidateId,
      });
    } else {
      await deleteFunc(item, workspaceId || "");
    }
  };

  const handleClose = (_, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setDeleteOpen(false);
  };

  // console.log("loading", deletedName, deletedName?.deletedItem);

  return (
    <>
      <Dialog
        open={deleteOpen}
        onClose={handleClose}
        className={styles.modelMail}
      >
        <DialogTitle>
          {deleteName === 'interview' ? "Please confirm if you would like to delete the interview." : deleteName === 'job' ? "Please confirm if you would like to remove the job post" :  `Would you like to remove the ${deleteName || "item"} from the list?`}
        </DialogTitle>

        <DialogActions className={styles.buttonSection}>
          <Box className={`button-primary ${styles.button1}`}>
            {/* Update Confirm button text and disabled state based on loading state */}

            <Button onClick={handleDelete} disabled={loading}>
              {loading ? (
                <p style={{ color: "white" }}>Loading...</p>
              ) : (
                "Confirm"
              )}
            </Button>
          </Box>

          <Box className={` button-primary ${styles.button}`}>
            <Button className={"secondary-btn"} onClick={handleClose}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
