import { UPLOAD_FILE, UPLOAD_FILE_RESET } from "../constants/constants";

const initialState = [];

export const uploadFileBuilderReducers = (
  state = initialState,
  { type, name, payload }
) => {
  switch (type) {
    case UPLOAD_FILE:
      // return [...state, payload];
      return state.some(item => item.name === payload.name)
  ? state.map(item =>
      item.name === payload.name
        ? { ...item, value: payload.value } // Overwrite the value if name matches
        : item
    )
  : [...state, payload];
    case UPLOAD_FILE_RESET:
      return [...initialState];
    default:
      return state;
  }
};
