import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextInput from "../../../../common/TextInput";
import { ReactComponent as Cross } from "../../../../../assets/industries/model/x.svg";
import Modal from "@mui/material/Modal";
import styles from "./styles.module.scss";
import { Button } from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../../../api/candidate/candidate.class";
import { ShowAlert } from "../../../../../store/actions/alertActions";
import Alerts from "../../../../common/Alert";

export default function CustomModal({ openModal, setOpenModal }) {
  const handleClose = () => setOpenModal(false);
  const dispatch = useDispatch();
  // const candidateId = useSelector((state) => state.auth.results.id);
  const candidateId = useSelector(
    (state) => state?.auth?.results?.candidate_id
  );
  const recruiterId = useSelector(
    (state) => state?.auth?.results?.recruiter_id
  );
  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };

  // const changePasswordSchema = Yup.object().shape({
  //   current_password: Yup.string().required("Required"),
  //   new_password: Yup.string()
  //     .required("Please enter password")
  //     .min(8, "Password is too short - should be 8 characters minimum"),
  //   confirm_password: Yup.string()
  //     .required("Please enter password")
  //     .min(8, "Password is too short - should be 8 characters minimum"),
  // });

  const onSubmit = async (values) => {
    try {
      if (
        values?.new_password.trim() === "" ||
        values?.confirm_password?.trim() === "" ||
        values?.current_password.trim() === ""
      ) {
        return dispatch(ShowAlert("Fields are required", "error"));
      } else if (
        values?.new_password.length < 8 ||
        values?.confirm_password?.length < 8 ||
        values?.current_password?.length < 8
      ) {
        return dispatch(
          ShowAlert("Password must be at least 8 characters long", "error")
        );
      } else if (values?.confirm_password !== values?.new_password) {
        return dispatch(
          ShowAlert("Confirm password and new password not match", "error")
        );
      }
      let response;
      if (candidateId) {
        response = await changePassword({
          ...values,
          candidate_id: candidateId,
        });
      } else {
        response = await changePassword({
          ...values,
          recruiter_id: recruiterId,
        });
      }
      if (response.data.success) {
        dispatch(ShowAlert(response?.data?.message, "success"));
        handleClose();
      } else {
        if (response?.data?.message) {
          dispatch(ShowAlert(response?.data?.message, "error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Alerts />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}
      >
        <Box className={`${styles.boxModel} xym1`}>
          <Box className={styles.modalwapper}>
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
            <Typography variant="h4" className={styles.heading}>
              Change Password
            </Typography>

            <Formik
              initialValues={initialValues}
              // validationSchema={changePasswordSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className={styles.formsBox}>
                  <Box className={styles.statusbox}>
                    <TextInput
                      autoComplete={"off"}
                      customClass={styles.formBox}
                      type="password"
                      name="current_password"
                      placeholder="Password"
                      onChange={handleChange}
                      value={values.current_password}
                    />
                    <TextInput
                      autoComplete={"off"}
                      customClass={styles.formBox}
                      type="password"
                      name="new_password"
                      placeholder="New Password"
                      onChange={handleChange}
                      value={values.new_password}
                    />
                    <TextInput
                      autoComplete={"off"}
                      customClass={styles.formBox}
                      type="password"
                      name="confirm_password"
                      placeholder="Confirm New Password"
                      onChange={handleChange}
                      value={values.confirm_password}
                    />
                    <Box className={`${styles.button} button-primary`}>
                      <Button type="submit">Save</Button>
                      <Button className="secondary-btn" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
