import React from "react";
import Navbar from "../navbar/Navbar";
import { Col, Container, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import styles from "./styles.module.scss";
import ContentFooter from "../content-footer/Content_footer";

// const linksArray = ["Jobs", "Blogs", "Our Solutions", "Contact Us"];
const TermsCondition = () => {
  return (
    <>
       <>
      <Navbar />
      <Container>
        <Row className={styles.rowMarginTop}>
          <Col lg={12} md={12} sm={12}>
            <Typography variant="h4" className={`text-center mb-3 ${styles.mainHeading}`}>Terms and Conditions</Typography>
            <ul className={styles.privacyPageList}>
            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Acceptance of Terms: </b>
              By accessing or using this mobile or web application (the "App, WORECS), you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using the App.
            </p></li>
            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Use of the App Worecs: </b>
              You acknowledge that the App is provided for informational purposes only and is not intended to provide professional advice. The information provided within the App is subject to change without notice.
            </p></li>

            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Confidentiality: </b>
              Any information or data provided by you through the App will be treated as confidential and will be handled in accordance with our Privacy Policy. However, please note that no data transmission over the internet can be guaranteed to be 100% secure, and we cannot guarantee the absolute security of any information you provide through the App.
            </p></li>

            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Disclaimer: </b>
              The App and its content are provided on an "as is" basis without any warranties, express or implied. We do not warrant that the App will be error-free, uninterrupted, or free from viruses or other harmful components. You agree that your use of the App is at your own risk.
            </p></li>

            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Third-Party Integration: </b>
              The App may integrate with third-party services or websites for enhanced functionality. We do not control or endorse these third-party services and cannot be held responsible for any content, functionality, or actions of such third parties. Your use of any third-party services is subject to their respective terms and conditions.
            </p></li>

            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Australian Consumer Law and Law: </b>
              To the extent permitted by law, any conditions, warranties, or consumer guarantees under the Australian Consumer Law are excluded. These terms and conditions are governed by the laws of Australia, and any disputes arising from the use of the App shall be subject to the exclusive jurisdiction of the courts of Australia.
            </p></li>

            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Security: </b>
              We strive to implement reasonable security measures to protect your information and data. However, you acknowledge that no method of transmission or storage is completely secure, and we cannot guarantee the absolute security of your information. You agree to use the App responsibly and take appropriate measures to protect your own information.
            </p></li>

            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Modification of Terms: </b>
              We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting the updated terms on the App. Your continued use of the App after any modifications will constitute your acceptance of the modified terms.
            </p></li>

            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Termination: </b>
              We may terminate or suspend your access to the App at any time, without prior notice or liability, for any reason whatsoever, including but not limited to a breach of these Terms and Conditions. No lock in contract. 60 days written notice if you wish to terminate your subscription. Any credits will expire if not used prior to your 60 days written termination letter to Worecs.
            </p></li>
            <li><p className={styles.privacyParaTag}>
              <b className={styles.privacyHeading}>Severability: </b>
              If any provision of these Terms and Conditions is found to be invalid or unenforceable, the remaining provisions shall continue to be valid and enforceable to the fullest extent permitted by law.
            </p></li>
            </ul>
            <br/>
            <br/>
            <p><b>By using the App, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</b></p>
          </Col>
        </Row>
      </Container>
      <ContentFooter />
    </>
    </>
  );
};

export default TermsCondition;
