import React, { useEffect, useState } from "react";
import { Grid, Box, TextField, TextareaAutosize } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { alpha, styled } from "@mui/material/styles";
import { teal } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import styles from "./styles.module.scss";
import CustomSelect from "../../common/Select";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../store/actions/alertActions";
import moment from "moment";
import DatePicker from "react-datepicker";

import {
  uploadImages,
  getImagelist,
} from "../../../api/uploadFile/uploadFile.class";
import { Formik } from "formik";

const TealSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: teal["A700"],
    "&:hover": {
      backgroundColor: alpha(teal["A700"], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: teal["A700"],
  },
}));

function OfferLetterForm({
  formState,
  setBubble,
  bubbleform,
  setFile,
  file,
  handleSubmit,
  initialValues,
  fieldRequiredSchema,
}) {
  const auth = useSelector((state) => state.auth);
  // console.log("auth", auth);
  const currentDate = new Date().toISOString().split("T")[0];
  const dispatch = useDispatch();
  const [imagelist, setImagelist] = useState();
  const [salary, setSalary] = useState({ name: "Salary Package" });

  const [imagelistOptions, setimagelistOptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [checked, setChecked] = React.useState(true);
  const handleToggleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    const api = async () => {
      try {
        const payload = {
          recruiter_id: auth.results.recruiter_id,
          workspace_id: auth.results.workspace_id,
        };
        const response = await getImagelist(payload);

        setImagelist(response.data.results);
      } catch (error) {}
    };
    api();
  }, []);

  useEffect(() => {
    if (imagelist?.length > 0) {
      [...imagelist].forEach((record) => {
        const data = [{ value: record.url, label: record.name, id: record.id }];
        if(bubbleform.form_logo_image_id === record.id){
          setSelectedImage({ value: record.url, label: record.name, id: record.id })  
        }
        setimagelistOptions((prev) => [...prev, ...data]);
      });
    }

    return () => {
      setimagelistOptions([]);
    };
  }, [imagelist, bubbleform]);

  const handleupload = async (file) => {
    try {
      const payload = {
        image: file,
        recruiter_id: auth.results.recruiter_id,
        workspace_id: auth.results.workspace_id,
      };

      const response = await uploadImages(payload);
      if (response?.data?.success) {
        dispatch(ShowAlert(response?.data?.message, "success"));

        // Fetch the updated image list after a successful upload
        const updatedPayload = {
          recruiter_id: auth.results.recruiter_id,
          workspace_id: auth.results.workspace_id,
        };
        const updatedResponse = await getImagelist(updatedPayload);

        // Update the imagelist and imagelistOptions states
        setImagelist(updatedResponse.data.results);

        const updatedData = updatedResponse.data.results.map((record) => ({
          value: record.url,
          label: record.name,
          id: record.id,
        }));
        setimagelistOptions(updatedData);
      } else {
        dispatch(ShowAlert(response?.data?.message, "error"));
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const hanglechangeimage = async (value) => {
    const image = `${process.env.REACT_APP_URL}/storage/${value.value}`;
    setBubble({
      ...bubbleform,
      image_url: `storage/${value.value}`,
      form_logo_image_id: value.id,
      image: image,
    });

    const api = async () => {
      try {
        const payload = {
          recruiter_id: auth.results.recruiter_id,
          workspace_id: auth.results.workspace_id,
        };
        const response = await getImagelist(payload);

        setImagelist(response.data.results);
      } catch (error) {}
    };
    api();
  };

  // --------------------CustomSelect-----------------

  // --------------- complete CustomSelect-----------------

  const currancyOptions = [
    { value: "aud", label: "AUD" },
    { value: "usd", label: "USD" },
    { value: "eur", label: "EUR" },
    { value: "jpy", label: "JPY" },
    { value: "gbp", label: "GBP" },
    { value: "cad", label: "CAD" },
    { value: "chf", label: "CHF" },
    { value: "php", label: "PHP" },
  ];
  const convertDate = (dateString) => {
    const [date] = dateString.split('T');
    const [year, month, day] = date.split('-');
    const dateObject = new Date(year, month - 1, day);
    return dateObject;
  }
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed, so add 1 and pad to 2 digits
    const day = today.getDate().toString().padStart(2, '0'); // Pad day to 2 digits
    return `${year}/${month}/${day}`;
  }
  
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();

    return `${day}/${month}/${year}`;
  }
  return (
    <Box className={styles.formCard}>
      <Formik
        initialValues={initialValues}
        validationSchema={fieldRequiredSchema}
      >
        {({ errors }) => (
          <form
            initialValues={initialValues}
            validationSchema={fieldRequiredSchema}
          >
            <Grid container spacing={2}>
              <Grid item md={8}>
                <div className={styles.imageBox}>
                  {bubbleform?.image_url && (
                    <img
                      src={`${process.env.REACT_APP_URL}/${bubbleform?.image_url}`}
                      alt="lettertop"
                    />
                  )}
                </div>
              </Grid>
              <Grid item md={4}>
                <label htmlFor="name">Name:</label>
                {formState === 0 ? (
                  <TextField
                    required
                    type="text"
                    id="name"
                    size="small"
                    placeholder="Recruiter's name"
                    disabled={true}
                    value={
                      bubbleform?.recruiter_name ||
                      auth?.results?.first_name + " " + auth?.results?.last_name
                    }
                    fullWidth
                    onChange={(e) =>
                      setBubble({
                        ...bubbleform,
                        recruiter_name: e.target.value,
                      })
                    }
                  ></TextField>
                ) : (
                  <p>
                    {bubbleform?.recruiter_name ||
                      auth?.results?.first_name +
                        " " +
                        auth?.results?.last_name}
                  </p>
                )}
                <label htmlFor="address" className="mt-2">
                  Address:
                </label>
                {formState === 0 ? (
                  <>
                  <TextareaAutosize
                    disabled={true}
                    style={{ width: "100%" }}
                    type="textarea"
                    size="small"
                    value={
                      bubbleform?.recruiter_address ||
                     `${auth?.results?.company_detail?.address}${(auth?.results?.company_detail?.suburb ? `, ${auth?.results?.company_detail?.suburb}` : '')}${(auth?.results?.company_detail?.postcode ? `, ${auth?.results?.company_detail?.postcode}` : '')}${(auth?.results?.company_detail?.state ? `, ${auth?.results?.company_detail?.state}` : '')}`
                    }
                    id="description"
                    minRows={3}
                    placeholder="Recruiter Address"
                    fullWidth
                    onChange={(e) =>
                      setBubble({
                        ...bubbleform,
                        recruiter_address: e.target.value,
                      })
                    }
                    ></TextareaAutosize>
                  </>
                  
                ) : (
                  <p>
                    {bubbleform?.recruiter_address ||
                      `${auth?.results?.company_detail?.address} ${(auth?.results?.company_detail?.suburb ? `, ${auth?.results?.company_detail?.suburb}` : '')} ${(auth?.results?.company_detail?.state ? `, ${auth?.results?.company_detail?.state}` : '')} ${(auth?.results?.company_detail?.postcode ? `- ${auth?.results?.company_detail?.postcode}` : '')}`}
                  </p>
                )}
                <label htmlFor="eamil" className="mt-2">
                  Email:
                </label>
                {formState === 0 ? (
                  <TextField
                    required
                    type="email"
                    size="small"
                    id="email"
                    disabled={true}
                    placeholder="Recruiter email"
                    value={bubbleform?.recruiter_email || auth?.results?.email}
                    fullWidth
                    onChange={(e) =>
                      setBubble({
                        ...bubbleform,
                        recruiter_email: e.target.value,
                      })
                    }
                  ></TextField>
                ) : (
                  <p>{bubbleform?.recruiter_email || auth?.results?.email}</p>
                )}
              </Grid>

              {formState === 0 ? (
                <>
                  <Grid item xl={2} lg={4}>
                    <CustomSelect
                      required
                      options={imagelistOptions}
                      onChange={(el) => hanglechangeimage(el)}
                      sx={{ maxWidth: "initial" }}
                      placeholder="Select Uploaded Logo"
                      value={selectedImage}
                    ></CustomSelect>
                  </Grid>
                  <Grid item md={4}>
                    <Box className={`${styles.fileUpload} button-primary`}>
                      <label className={styles.customFileUpload}>
                        <input
                          required
                          accept="image/*"
                          className={styles.fileUploadInput}
                          style={{ display: "none" }}
                          id="raised-button-file"
                          multiple
                          type="file"
                          onChange={(e) => {
                            const selectedFile = e?.target?.files[0];
                            if (selectedFile.type.includes("image")) {
                              setFile({
                                ...file,
                                resume_path: e?.target?.files[0],
                              });
                              handleupload(selectedFile);
                            } else {
                              alert("pls select an image file");
                            }
                          }}
                        />
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="inherit"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 1L10.25 4M7 1L4 4M7 1V10.5M13 6.5V13H1V6.5"
                            stroke="currentColor"
                            fill="transparent"
                          ></path>
                        </svg>
                        <span className={styles.uploadLabel}>Upload Files</span>
                      </label>
                    </Box>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <div className="d-flex align-items-center mt-3">
                  <span>Dear</span>
                  {formState === 0 ? (
                    <TextField
                      required
                      className="ml-3"
                      type="text"
                      size="small"
                      placeholder="Candidate's first name"
                      value={bubbleform?.candidate_name}
                      id="candidateName"
                      //this is the character limit
                      inputProps={{ minLength: 2, maxLength: 40 }}
                      onChange={(e) =>
                        setBubble({
                          ...bubbleform,
                          candidate_name: e.target.value,
                        })
                      }
                    ></TextField>
                  ) : (
                    <p className="ml-2 mb-0">{bubbleform?.candidate_name}</p>
                  )}
                  ,
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} className="mt-3">
              <Grid item md={4}>
                <label htmlFor="lattername">Letter of offer</label>
                {formState === 0 ? (
                  <TextField
                    required
                    type="text"
                    size="small"
                    placeholder="Job company"
                    id="lattername"
                    value={bubbleform?.job_company}
                    //this is the character limitation
                    inputProps={{ minLength: 2, maxLength: 40 }}
                    onChange={(e) =>
                      setBubble({ ...bubbleform, job_company: e.target.value })
                    }
                  ></TextField>
                ) : (
                  <p className="">{bubbleform?.job_company}</p>
                )}
              </Grid>
              <Grid item md={12}>
                {formState === 0 ? (
                  <TextareaAutosize
                    style={{ width: "100%" }}
                    type="textarea"
                    size="small"
                    value={bubbleform?.job_company_description}
                    id="description"
                    minRows={3}
                    placeholder="Congratulatory remark. 
                Ex. It is my pleasure to extend the following offer of employment to you on behalf of the employer."
                    fullWidth
                    onChange={(e) =>
                      setBubble({
                        ...bubbleform,
                        job_company_description: e.target.value,
                      })
                    }
                  ></TextareaAutosize>
                ) : (
                  <p className="">{bubbleform?.job_company_description}</p>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mt-3" alignItems="center">
              <Grid item xl={2} md={3}>
                <label htmlFor="role">Role:</label>
              </Grid>
              <Grid item sm={3}>
                {formState === 0 ? (
                  <TextField
                    required
                    id="role"
                    size="small"
                    placeholder="Job role"
                    value={bubbleform?.job_role}
                    fullWidth
                    inputProps={{ minLength: 2, maxLength: 40 }}
                    onChange={(e) =>
                      setBubble({ ...bubbleform, job_role: e.target.value })
                    }
                  ></TextField>
                ) : (
                  <p className="mb-0">{bubbleform?.job_role}</p>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mt-3" alignItems="center">
              <Grid item xl={2} md={3}>
                <label htmlFor="reporting">Reporting To:</label>
              </Grid>
              {formState === 0 ? (
                <Grid item sm={3}>
                  <TextField
                    id="reporting"
                    size="small"
                    value={bubbleform?.reporting_to}
                    placeholder="Reporting to"
                    fullWidth
                    inputProps={{ minLength: 2, maxLength: 40 }}
                    onChange={(e) =>
                      setBubble({ ...bubbleform, reporting_to: e.target.value })
                    }
                  ></TextField>
                </Grid>
              ) : (
                <p className="mb-0" style={{ paddingLeft: "16px" }}>
                  {bubbleform?.reporting_to}
                </p>
              )}
            </Grid>

            <Grid container spacing={2} className="mt-4">
              <Grid item xl={2} md={3}>
                <label htmlFor="date">Commencement Date:</label>
              </Grid>
              <Grid item sm={3}>
              {/* bubbleform.commencement_date instanceof Date ? bubbleform.commencement_date :  */}
                {formState === 0 ? (
                  <div className={styles.datePicker}>
                    <DatePicker
                      selected={
                        bubbleform.commencement_date
                          ? convertDate(bubbleform.commencement_date)
                          : new Date()
                      }
                      onChange={(date) =>{
                        const dateObj = new Date(date);
                        const day = String(dateObj.getDate()).padStart(2, '0');
                        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
                        const year = dateObj.getFullYear();

                        const formattedDate = `${year}-${month}-${day}`;
                        setBubble({
                          ...bubbleform,
                          commencement_date: formattedDate,
                        })
                      }
                      }
                      placeholderText="Date *"
                      dateFormat="dd/MM/yyyy"
                      className="mb-3"
                    />

                    {/* <TextField
                      type="date"
                      formate="dd/mm/yyyy"
                      id="date"
                      min={currentDate}
                      size="small"
                      fullWidth
                      value={bubbleform?.commencement_date}
                      className="mb-3"
                      onChange={(e) =>
                        setBubble({
                          ...bubbleform,
                          commencement_date: e.target.value,
                        })
                      }
                      // onChange={handleDateChangedate}
                      InputProps={{
                        inputProps: {
                          style: { textAlign: "left" },
                        },
                      }}
                    ></TextField> */}
                  </div>
                ) : (
                  <p className="mb-0">{bubbleform.to_be_confirmed ? 'To Be Confirmed' : formatDate(bubbleform?.commencement_date || getCurrentDate())}</p>
                )}

                {formState === 0 ? (
                  <Box className={styles.customCheckBox}>
                    <label className="labelContainer">
                      To be confirmed
                      <input type="checkbox" name="terms" checked={bubbleform.to_be_confirmed} onChange={()=>{
                         setBubble({
                          ...bubbleform,
                          to_be_confirmed: !bubbleform.to_be_confirmed
                        })
                      }}/>
                      <span className="checkbox"></span>
                    </label>
                  </Box>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            {formState === 0 ? (
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Box className={styles.checkboxgroup}>
                  <Box className={styles.customCheckBox}>
                    <label className="labelContainer">
                      Salary Package
                      <input
                        type="radio"
                        defaultChecked={true}
                        name="radio-group"
                        onChange={(e) =>{
                          setSalary({
                            ...salary,
                            name: "Salary Package",
                          })
                          setBubble({
                            ...bubbleform,
                            salary_amount: 0,
                          });
                        }
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Box>
                  <Box className={styles.customCheckBox}>
                    <label className="labelContainer">
                      Hourly Rate
                      <input
                        type="radio"
                        name="radio-group"
                        onChange={(e) =>
                          {
                            setSalary({
                            ...salary,
                            name: "Hourly Rate",
                          })
                          setBubble({
                            ...bubbleform,
                            salary_amount: 0,
                          });
                          }
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Box>
                </Box>
              </Grid>
            </Grid>) : null}

            <Grid container spacing={2} className="mt-3" alignItems="center">
              <Grid item xl={2} md={3}>
                <label htmlFor="date">{salary.name} :</label>
                {/* <p className="">{bubbleform?.currency_code}</p>  <p className="ml-2">{bubbleform?.salary_amount}</p> */}
              </Grid>
              <Grid item sm={6} md={8}>
                <div className={`${styles.slectLebel} d-flex`}>
                  {formState === 0 ? (
                    <CustomSelect
                    value={{value: bubbleform?.currency_code?.toLowerCase(), label: bubbleform?.currency_code}}
                      required
                      options={currancyOptions}
                      sx={{ maxWidth: "initial" }}
                      onChange={(e) => {
                        const inputValue = e.label;
                        const onlyText = /^[A-Za-z\s]*$/;

                        if (onlyText.test(inputValue)) {
                          setBubble({
                            ...bubbleform,
                            currency_code: inputValue,
                          });
                        }
                      }}
                    ></CustomSelect>
                  ) : (
                    <p className="">{bubbleform?.salary_amount}</p>
                  )}

                  {formState === 0 ? (
                    salary.name === "Hourly Rate" ? (
                      <TextField
                        id="amount"
                        required
                        size="small"
                        value={bubbleform?.salary_amount}
                        placeholder="Job package"
                        className="mb-3 ml-2"
                        style={{ width: "150px" }}
                        onChange={(e) =>
                          setBubble({
                            ...bubbleform,
                            salary_amount: e.target.value.replace(/\D/g, ""),
                          })
                        }
                        inputProps={{
                          type: "text",
                          min: 0, // Set a minimum value if needed
                          max: "999",
                          maxLength: "3",
                        }}
                      ></TextField>
                    ) : (
                      <TextField
                        id="amount"
                        required
                        size="small"
                        value={bubbleform?.salary_amount}
                        placeholder="Job package"
                        className="mb-3 ml-2"
                        style={{ width: "150px" }}
                        onChange={(e) =>
                          setBubble({
                            ...bubbleform,
                            salary_amount: e.target.value,
                          })
                        }
                        inputProps={{
                          type: "number",
                          min: 0, // Set a minimum value if needed
                        }}
                      ></TextField>
                    )
                  ) : (
                    <p className="ml-2">{bubbleform?.currency_code}</p>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={`${formState === 0 ? "mt-3" : ""}`} alignItems="center">
              {formState === 0 ? (
                <>
                  <Grid item xl={2} md={3}>
                    <label htmlFor="date">Super Annuation: </label>
                  </Grid>
                  <Grid item sm={3}>
                    <div className="d-flex">
                      <Box className={styles.customCheckBox}>
                        <label className="labelContainer">
                          Inclusive
                          <input type="checkbox" name="annuation-group"
                            checked={bubbleform.annuation === 1}
                            onChange={() =>{
                              setBubble({
                                ...bubbleform,
                                annuation: bubbleform.annuation === 1 ? 0 : 1
                              })
                            }}
                          />
                            <span className="checkbox"></span>
                        </label>
                      </Box>
                      <Box className={`${styles.customCheckBox} ml-4`}>
                        <label className="labelContainer">
                          Exclusive
                          <input type="checkbox" name="annuation-group"
                            checked={bubbleform.annuation === 2}
                            onChange={() =>{
                              setBubble({
                                ...bubbleform,
                                annuation: bubbleform.annuation === 2 ? 0 : 2
                              })
                            }}
                          />
                          <span className="checkbox"></span>
                        </label>
                      </Box>
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                <Grid item xl={2} md={3}>
                </Grid>
                <Grid item sm={3} xl={10} md={9}>
                <p className=""> {bubbleform?.annuation === 1 && "This wage is inclusive of superannuation."} {bubbleform?.annuation === 2 && "You will receive superannuation on top of this wage."}</p>
                </Grid>
              </>
              )}
            </Grid>
            <Grid container spacing={2} className="mt-3">
              <Grid item md={12}>
                {formState === 0 ? (
                  <TextareaAutosize
                    style={{ width: "100%" }}
                    value={bubbleform?.additional_message}
                    type="textarea"
                    size="small"
                    id=""
                    minRows={3}
                    placeholder="Additional message for the candidate."
                    fullWidth
                    onChange={(e) =>
                      setBubble({
                        ...bubbleform,
                        additional_message: e.target.value,
                      })
                    }
                  ></TextareaAutosize>
                ) : (
                  <p className="">{bubbleform?.additional_message}</p>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mt-3">
              <Grid item md={12}>
                <div className="d-flex align-items-center">
                  <span>
                    Congratulations on your appointment and I wish you all the
                    best at{" "}
                  </span>
                  {formState === 0 ? (
                    <TextField
                      required
                      className="ml-3"
                      inputProps={{ minLength: 2, maxLength: 20 }}
                      onChange={(e) =>
                        setBubble({
                          ...bubbleform,
                          company_name: e.target.value,
                        })
                      }
                      type="text"
                      value={bubbleform?.company_name}
                      size="small"
                      id="companyName"
                      placeholder="Company Name"
                    ></TextField>
                  ) : (
                    <p className="mb-0 ml-2">{bubbleform?.company_name}</p>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mt-3">
              <Grid item md={3}>
                <label htmlFor="">Yours faithfully,</label>
                {formState === 0 ? (
                  <FormControlLabel
                    control={
                      <TealSwitch
                        checked={checked}
                        onChange={handleToggleChange}
                      />
                    }
                    label="Sign document"
                  />
                ) : (
                  checked && "**Recruiter's signature**"
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mt-3">
              <Grid item md={3}>
                {formState === 0 ? (
                  <TextField
                    required
                    className=""
                    type="text"
                    value={
                      bubbleform?.recruiter_full_name ||
                      auth?.results?.first_name + " " + auth?.results?.last_name
                    }
                    size="small"
                    id="recruiterName"
                    placeholder="Recruiter's full name"
                    fullWidth
                    inputProps={{ minLength: 2, maxLength: 20 }}
                    onChange={(e) =>
                      setBubble({
                        ...bubbleform,
                        recruiter_full_name: e.target.value,
                      })
                    }
                  ></TextField>
                ) : (
                  <p className="">
                    {bubbleform?.recruiter_full_name ||
                      auth?.results?.first_name +
                        " " +
                        auth?.results?.last_name}
                  </p>
                )}
                {formState === 0 ? (
                  <TextField
                    className="mt-2"
                    type="tel"
                    value={
                      bubbleform?.recruiter_contact_number ||
                      auth?.results?.contact_number
                    }
                    size="small"
                    id="recruiterNumber"
                    placeholder="Recruiter's contact number"
                    fullWidth
                    inputProps={{ minLength: 10, maxLength: 11 }}
                    onChange={(e) =>
                      setBubble({
                        ...bubbleform,
                        recruiter_contact_number: e.target.value,
                      })
                    }
                  ></TextField>
                ) : (
                  <p className="">
                    {bubbleform?.recruiter_contact_number ||
                      auth?.results?.contact_number}
                  </p>
                )}
                <label htmlFor="" className="mt-2">
                  Recruitment consultant
                </label>
                {formState === 0 ? (
                  <TextField
                    className=""
                    type="text"
                    value={
                      bubbleform?.recruiter_company_name ||
                      auth?.results?.company_detail?.name
                    }
                    size="small"
                    id="recruiterCompany"
                    placeholder="Recruiter's company"
                    fullWidth
                    inputProps={{ minLength: 2, maxLength: 20 }}
                    onChange={(e) =>
                      setBubble({
                        ...bubbleform,
                        recruiter_company_name: e.target.value,
                      })
                    }
                  ></TextField>
                ) : (
                  <p className="">
                    {bubbleform?.recruiter_company_name ||
                      auth?.results?.company_detail?.name}
                  </p>
                )}
              </Grid>
            </Grid>
            {/* <Grid container spacing={2} className="mt-3">
              <Grid item md={3}>
                <div className={styles.signatureblock}></div>
                {formState === 0 ? (
                  <TextField
                    required
                    className="mt-2"
                    value={bubbleform?.candidate_full_name}
                    type="text"
                    size="small"
                    id="candidateFullName"
                    placeholder="Candidates's full name"
                    fullWidth
                    inputProps={{ minLength: 2, maxLength: 20 }}
                    onChange={(e) =>
                      setBubble({
                        ...bubbleform,
                        candidate_full_name: e.target.value,
                      })
                    }
                  ></TextField>
                ) : (
                  <p className="mt-2">{bubbleform?.candidate_full_name}</p>
                )}
              </Grid>
            </Grid> */}
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default OfferLetterForm;
