import EndPoints from "../EndPoints";
import axios from "../axiosConfig";

export const getOrganisationList = async () => {
  try {
    let url = `${EndPoints.organisationList}`;
    const response = await axios.get(url);
    return response?.data;
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const getOrganisationClientList = async (org_id) => {
  try {
    let url = `${EndPoints.organisationClientList}?organisation_id=${org_id}`;
    const response = await axios.get(url);
    return response?.data;
  } catch (error) {
    return { success: false, message: error.message };
  }
};
