import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import style from "../../document_sinner/styles.module.scss";
import { setSigningMetadata } from "../../../store/actions/signingMetadata.actions";
import {
  FieldTypes,
  FontList,
  FontSize,
} from "../../../store/constants/constants";
import AddSinnerModal from "./AddSinnerModal";
import { ShowAlert } from "../../../store/actions/alertActions";

function AssignFieldRightMenu({
  selectedInputField,
  setSelectedInputField,
  sendData,
  setOpenDraft,
  isEdit,
  setDeletedSinnerDraft,
  setOpenTemplate,
}) {
  const pdfMetadata = useSelector((s) => s.signingMetadataReducer);
  const dispatch = useDispatch();
  const [fontList, setFontList] = useState(FontList);
  const [fontSize, setFontSize] = useState(FontSize);
  const [openAddModal, setOpenAddModal] = useState(false);

  useEffect(() => {
    setFontList([...fontList]);
    setFontSize([...fontSize]);
  }, [selectedInputField]);

  const handleFontChange = (e) => {
    selectedInputField.fontFamily = e.target.value;
    dispatch(setSigningMetadata({ ...pdfMetadata }));
  };

  const handleTextSizeChange = (e) => {
    selectedInputField.fontSize = parseInt(e.target.value);
    dispatch(setSigningMetadata({ ...pdfMetadata }));
    setFontSize([...fontSize]);
    setSelectedInputField({ ...selectedInputField });
  };

  const handlePlaceholderChange = (e) => {
    selectedInputField.placeholder = e.target.value;
  };

  const handleAddSinner = () => {};

  function validateFields(arr) {
    return !arr.some(obj => obj.fields?.length > 0);
}

  const createDocToSend = () => {
      const isError = validateFields(pdfMetadata.signers);
      if(isError) {
        dispatch(ShowAlert("E-sign request can not be sent until one option has been placed on the document","error"));
        return;
      }
      sendData();
  }


  return (
    <>
      <div className={`${style.siderbarPopup}`}>
        <div className={style.siderbarPopup_inner} style={{ padding: "12px" }}>
          <div
            className={selectedInputField ? "button_box mb-4" : "button_box"}>
            <button onClick={()=>{
              createDocToSend();
            }}>Send</button>
          </div>
          <div
            className={selectedInputField ? "button_box mb-4" : "button_box"}>
            <button onClick={() => setOpenDraft(true)}>Draft</button>
          </div>
          {selectedInputField ? (
            <>
              <div className='top_title'>
                <h6>Field Properties </h6>
              </div>
              <div className={`${style.common_section}`}>
                <h6>Assigned to {selectedInputField?.signer?.name}</h6>
              </div>
              <div className={`${style.formChecks}`}>
                <h6>Required</h6>
                <Form>
                  <Form.Check
                    checked={selectedInputField?.isRequired}
                    id='custom-switch'
                    label='Check this if Field is Required'
                    onChange={() => {
                      selectedInputField.isRequired =
                        !selectedInputField?.isRequired;
                      dispatch(setSigningMetadata({ ...pdfMetadata }));
                    }}
                    type='switch'
                  />
                </Form>
              </div>
              {(selectedInputField?.fieldType === FieldTypes?.TEXT_INPUT ||
                selectedInputField?.fieldType === FieldTypes?.DATES) && (
                <div className={`${style.formattingTitle}`}>
                  <h6>Formatting</h6>
                  <Form.Select onChange={handleFontChange} size='lg'>
                    {fontList.map((e) => {
                      return (
                        <option
                          defaultValue={
                            e.fontFamily === selectedInputField.fontFamily
                          }
                          key={e.id}
                          value={e.fontFamily}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Select onChange={handleTextSizeChange} size='lg'>
                    {fontSize.map((e) => {
                      return (
                        <option
                          defaultValue={e.size === selectedInputField.fontSize}
                          key={e.id}
                          value={e.size}>
                          {e.size}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
              )}
            </>
          ) : (
            <>
              <div className={`${style.assigned} common_section`}>
                <h6>No Field Selected</h6>
              </div>
            </>
          )}

          <div className='button_box'>
            <button onClick={() => setOpenAddModal(true)}>+ Add Signers</button>
          </div>
          <div className='button_box'>
            <button onClick={() => setOpenTemplate(true)}>
              Save as Template
            </button>
          </div>
        </div>
      </div>
      <AddSinnerModal
        open={openAddModal}
        close={setOpenAddModal}
        isEdit={isEdit}
        setDeletedSinnerDraft={setDeletedSinnerDraft}
      />
    </>
  );
}

export default AssignFieldRightMenu;
