import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';

import { Box, Button, Grid } from "@mui/material";

import styles from "./styles.module.scss";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { candidateLicenseVerify } from '../../../../../../api/candidate/candidate.class';
import { ShowAlert } from '../../../../../../store/actions/alertActions';
import TextInput from '../../../../../common/TextInput';
import { PAYMENT_SENT, PAYMENT_START } from '../../../../../../store/constants/constants';
import { isMobile } from 'react-device-detect';

export default function CheckoutForm({
  data,
  setOpen,
  setUpdation,
  isCandidate
}){
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();
  const payment = useSelector((state) => state?.pricing);
  const [Customerror, setErrors] = useState({});
  const [paymentId, setPaymentId] = useState('');


  const initialState = {
    name: "",
  };
  const checkAlpha = (value) => {
    return /^[A-Za-z\s]*$/.test(value);
  };
  const onChange = (e) => {
    const { name, value } = e?.target;

    if (name === "name") {
      if ((checkAlpha(value) && value?.trim().length > 0) || value == "") {
        setValues({
          ...values,
          [name]: value,
        });
      }
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }

    if (Customerror.hasOwnProperty(name)) {
      delete Customerror[name];
      setErrors(Customerror);
    }
  };
  const [values, setValues] = useState(initialState);
  // const onCheckedChange = (e) => {
  //   if (e?.target.checked) {
  //     setValues({ ...values, [e.target.name]: 1 });
  //     setErrors(false);
  //   } else {
  //     setValues({ ...values, [e?.target.name]: 0 });
  //     setErrors(true);
  //   }
  // };
  const validate = (data) => {
    // console.log("validateData", data);
    const newError = {};
    for (let key in data) {
      let value = data[key];
      switch (key) {
        case "name":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "Field is required";
          break;
        // case "terms":
        //   if (value.length === 0 || value.toString() === "")
        //     newError[key] = "Field is required";
        //   break;
        default:
          break;
      }
    }
    return newError;
  };

  const submitData = async (e) => {
    e.preventDefault();

    const validateSelect = validate(values);
    if (Object?.keys(validateSelect)?.length > 0) {
      setErrors(validateSelect);
      return;
    }

    if (!stripe || !elements) {
      return;
    }
  
    const card = elements.getElement(CardElement);
  
    if (card == null) {
      return;
    }
      dispatch({
        type: PAYMENT_START,
      });
    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name: values?.name,
      },
    });
  
    if (error) {
      console.log('[error] submit data', error);
      return;
    } else {
      setPaymentId(paymentMethod.id);
    }

    const local_data = {
      // ...data,
      name: values?.name,
      uuid: data?.uuid,
      // terms: values?.terms,
      plan_name: values?.name,
      payment_id: paymentMethod.id,
    };
    // dispatch(candidateLicenseVerify(local_data)).then(() => {
    //   setOpen(false);
    // });
    const response = await candidateLicenseVerify(local_data);
    if (response?.data?.success === true) {
      dispatch(
        ShowAlert(
          "We will contact to third party organization on your behalf to verify this license.",
          "success"
        )
      );
      if(isCandidate){
        setUpdation((old) => old + 1)
      }
      setOpen(false);
      dispatch({
        type: PAYMENT_SENT,
      });
    } else {
      if (typeof response?.data?.message === "object") {
        Object.entries(response?.data?.message)?.map(([key, value], i) =>
          dispatch(ShowAlert(value, "error"))
        );
      } else {
        dispatch(ShowAlert(response?.data?.message, "error"));
      }
      dispatch({
        type: PAYMENT_SENT,
      });
    }
  };
  const cardElementOptions = {
    style: {
        base: {
            fontSize: '16px',
            color: '#32325d',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
        },
    },
  };

  return (
    <form onSubmit={submitData}>
    <Grid container spacing={2} style={{width: isMobile ? "180%" : "100%"}}>
      <Grid item md={12} xs={12} lg={12}>
        <Box className={styles.parentBox}>
          <TextInput
            compulsory={true}
            customClass={styles.input}
            label={"Name"}
            placeholder={"name"}
            name="name"
            value={values?.name}
            onChange={onChange}
          />
          {Customerror?.name && (
            <p className="error-class position-absolute">
              {Customerror?.name}
            </p>
          )}
        </Box>
      </Grid>
      <Grid item md={12} xs={12} lg={12}>
        <Box className={styles.parentBox} style={{
            marginTop: "0.5rem",
            background: "#FFFFFF",
            border: "1px solid #afafaf",
            borderRadius: "3px",
            width: "100%",
            position: "relative",
            minHeight: "41px",
            fontFamily: "poppins-regular",
            fontStyle: "normal",
            fontSize: "12px",
            lineHeight: "18px",
            padding: "10px 10px",
            letterSpacing: "0.025em",
          }}>
            <CardElement options={cardElementOptions}/>
        </Box>
      </Grid>
      {/* <Grid item xs={12}>
        <Box className={styles.customCheckBox}>
          <label
            className="labelContainer"
            style={{ marginTop: "15px" }}
          >
            I have read and agree to the{" "}
            <a
              className="text-primary"
              href={
                data?.third_party_name === "vsure"
                  ? "https://www.vsure.com.au/terms-and-conditions-for-vsure/"
                  : "https://backycheck.com/terms-and-conditions.html"
              }
              target={"_blank"}
            >
              Terms of Service
            </a>
            <input
              type="checkbox"
              name="terms"
              onChange={onCheckedChange}
            />
            <span className="checkmark"></span>
          </label>
        </Box>
      </Grid> */}
    </Grid>
    <Box className={`button-primary ${styles.button}`}>
      <Button onClick={submitData} disabled={payment?.isLoading} style={{marginTop: '1rem'}}>
        {payment?.isLoading ? (
          <p style={{ color: "white" }}>Loading...</p>
        ) : (
          "Send"
        )}
      </Button>
    </Box>
  </form>
  );
};