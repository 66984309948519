import React, { useState } from "react";
import { ReactComponent as Cross } from "../../../../../assets/industries/model/x.svg";
import styles from "./styles.module.scss";
import { Button, Grid, Box, Modal, Typography } from "@mui/material";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import { updateProfileLicense } from "../../../../../api/candidate/candidate.class";
import CustomSelect from "../../../../common/Select";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../../../store/actions/alertActions";
import Alerts from "../../../../common/Alert";
import TextInput from "../../../../common/TextInput";
import CountrySelect from "../../../../common/CountrySelect/CountrySelect";

export default function UpdateBackyCheck({
  openModal,
  setOpenModal,
  setUpdation,
  userDetailData,
  data,
  type,
}) {
  const handleClose = () => setOpenModal(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth?.results);

  const [loading, setLoading] = useState(false);

  const initialValues = {
    recruit_industry:
      {
        value: data?.recruiter_industry?.id,
        label: data?.recruiter_industry?.name,
      } || "",
    industry_cert_license:
      {
        value: data?.industry_certification_license?.id,
        label: data?.industry_certification_license?.name,
      } || "",
    first_name: data?.first_name ? data?.first_name : auth?.first_name ?? "",
    middle_name: data?.middle_name
      ? data?.middle_name
      : auth?.middle_name ?? "",
    last_name: data?.last_name ? data?.last_name : auth?.last_name ?? "",
    dob: data?.dob ? moment(data?.dob).format("yyyy-MM-DD") : "",
    mail_id: data?.email ? data?.email : auth?.email ?? "",
    country: data?.country || "",
    service_type: data?.backy_check_type?.toLowerCase(),
    document_number: data?.document_number || "",
    expiry_date: data?.expiry_date
      ? moment(data?.expiry_date).format("yyyy-MM-DD")
      : "",
    state: data?.state || "",
  };

  const licenseSchema = Yup.object().shape({
    recruit_industry: Yup.object().required("Enter Recruit Industry"),
    industry_cert_license: Yup.object().required("Enter Certificate License"),
    first_name: Yup.string().required("Enter First Name"),
    middle_name: Yup.string().optional(),
    last_name: Yup.string().required("Enter Last Name"),
    dob: Yup.date()
      .max(new Date(), "Date of Birth must be less than today date")
      .required("Enter Date of Birth"),
    document_number: Yup.string().when("service_type", {
      is: "wwc",
      then: Yup.string("Document Number is required").required(
        "Document Number is required"
      ),
      otherwise: Yup.string(),
    }),
    expiry_date: Yup.date().when("service_type", {
      is: "wwc",
      then: Yup.date()
        .min(new Date(), "Expiry Date must be greater than today's date")
        .required("Expiry Date is required"),
      otherwise: Yup.date(),
    }),
    state: Yup.string().when("service_type", {
      is: "wwc",
      then: Yup.string("State is required").required("State is required"),
      otherwise: Yup.string(),
    }),
  });

  const onSubmit = async (values) => {
    let payload;
    setLoading(true);
    try {
      payload = {
        uuid: data?.uuid,
        candidate_id: auth?.candidate_id,
        workspace_id: data?.workspace_id,
        recruiter_id: data?.recruiter_id,
        doc_id: values.doc_id,
        first_name: values?.first_name,
        last_name: values?.last_name,
        dob: values?.dob,
        country: values?.country,
        mail_id: values?.email,
        request_type: "candidate",
        candidate_lic_cert_id: userDetailData?.id || "",
        expiry_date: values?.expiry_date,
        state: values?.state,
        document_number: values?.document_number,
        is_background_check : true,
      };
      payload["recruitment_industries[" + data.id + "][document_number]"] =
        values.document_number || "";
      payload["recruitment_industries[" + data.id + "][expiry_date]"] =
        values.expiry_date || "";
      payload["recruitment_industries[" + data.id + "][state]"] =
        values.state || "";
      payload["recruitment_industries[" + data.id + "][rec_ind_request_id]"] =
        values.recruit_industry?.value || "";
      payload[
        "recruitment_industries[" + data.id + "][ind_cert_lic_request_id]"
      ] = values.industry_cert_license?.value || "";
      payload["recruitment_industries[" + data.id + "][first_name]"] =
        values.first_name || "";
      payload["recruitment_industries[" + data.id + "][middle_name]"] =
        values.middle_name || "";
      payload["recruitment_industries[" + data.id + "][last_name]"] =
        values.last_name || "";
      payload["recruitment_industries[" + data.id + "][dob]"] =
        values.dob || "";
      payload["recruitment_industries[" + data.id + "][country]"] =
        values.country || "";
      payload["recruitment_industries[" + data.id + "][mail_id]"] =
        values.mail_id || "";

      const response = await updateProfileLicense({ ...payload });

      if (response.data.success) {
        setLoading(false);
        dispatch(ShowAlert(response?.data?.message, "success"));
        handleClose();
        setUpdation((old) => old + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Alerts />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}
      >
        <Box className={`${styles.boxModel}`}>
          <Box className={styles.modalwapper}>
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
            <Typography variant="h4" className={styles.heading}>
              {data?.industry_certification_license?.name ||
                "Background Check Certificates and Licenses"}
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={licenseSchema}
              onSubmit={onSubmit}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit} className={styles.formsBox}>
                  {console.log("errors", errors)}
                  <Box className={styles.statusbox}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item md={6} xs={12}>
                        <TextInput
                          style={{ width: "100%" }}
                          compolsory={true}
                          customClass={styles.input}
                          label="First Name"
                          type="text"
                          name="first_name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="First Name"
                          onChange={handleChange}
                          value={values?.first_name}
                        />
                        <span>
                          {errors.first_name &&
                            touched.first_name &&
                            errors.first_name}
                        </span>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextInput
                          style={{ width: "100%" }}
                          customClass={styles.input}
                          label="Middle Name"
                          type="text"
                          name="middle_name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Middle Name"
                          onChange={handleChange}
                          value={values?.middle_name}
                        />
                        <span>
                          {errors.middle_name &&
                            touched.middle_name &&
                            errors.middle_name}
                        </span>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextInput
                          style={{ width: "100%" }}
                          compolsory={true}
                          customClass={styles.input}
                          label="Surname"
                          type="text"
                          name="last_name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Surname"
                          onChange={handleChange}
                          value={values?.last_name}
                        />
                        <span>
                          {errors.last_name &&
                            touched.last_name &&
                            errors.last_name}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          compolsory={true}
                          style={{ width: "100%" }}
                          customClass={styles.input}
                          label="Email"
                          type="email"
                          name="mail_id"
                          placeholder={"Email"}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleChange}
                          value={values.mail_id}
                        />
                        <span>
                          {errors.mail_id && touched.mail_id && errors.mail_id}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          compolsory={true}
                          label="Date of birth"
                          type="date"
                          name="dob"
                          placeholder={"Date of birth"}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={handleChange}
                          value={values.dob}
                        />
                        <span>{errors.dob && touched.dob && errors.dob}</span>
                      </Grid>
                      {data?.backy_check_type?.toLowerCase() === "wwc" && (
                        <>
                          <Grid item xs={6}>
                            <TextInput
                              compolsory={true}
                              label="Document Number"
                              type="text"
                              name="document_number"
                              placeholder={"Document Number"}
                              onChange={handleChange}
                              value={values.document_number}
                            />
                            <span>
                              {errors.document_number &&
                                touched.document_number &&
                                errors.document_number}
                            </span>
                          </Grid>
                          <Grid item xs={6}>
                            <TextInput
                              compolsory={true}
                              label="Card Expiry Date"
                              type="date"
                              name="expiry_date"
                              placeholder={"Card Expiry Date"}
                              onChange={handleChange}
                              min={new Date().toISOString().split("T")[0]}
                              value={values.expiry_date}
                            />
                            <span>
                              {errors.expiry_date &&
                                touched.expiry_date &&
                                errors.expiry_date}
                            </span>
                          </Grid>
                          <Grid item xs={6}>
                            {/* <TextInput
                              compolsory={true}
                              label="Card State Issue"
                              type="text"
                              name="state"
                              placeholder={"Card State Issue"}
                              onChange={handleChange}
                              min={new Date().toISOString().split("T")[0]}
                              value={values.state}
                            /> */}
                            <Field
                                compolsory={true}
                                customClass={styles.input}
                                label="Card State Issue"
                                name="state"
                                component={CountrySelect}
                                placeholder="Card State Issue"
                                isStateList={true}
                                value={values.state}
                                style={{ marginTop: "19px", minHeight: "41px" }}
                              />
                            <span>
                              {errors.state && touched.state && errors.state}
                            </span>
                          </Grid>
                        </>
                      )}
                      {/* <Grid item xs={6}>
                        <Box className={styles.parentInput}>
                          <CustomSelect
                            customClass={styles.select}
                            type="text"
                            name="recruit_industry"
                            value={values.recruit_industry}
                            sx={{ maxWidth: "initial" }}
                            label={
                              <>
                                Recruitment Industries&nbsp;
                                <span style={{ color: "red" }}>*</span>
                              </>
                            }
                            placeholder={"Recruitment Industries"}
                            isDisabled={true}
                          />
                          <span>
                            {errors.recruit_industry &&
                              touched.recruit_industry &&
                              errors.recruit_industry}
                          </span>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className={styles.parentInput}>
                          <CustomSelect
                            customClass={styles.select}
                            type="text"
                            name="industry_cert_license"
                            value={values.industry_cert_license}
                            placeholder={"Industry Certification License"}
                            sx={{ maxWidth: "initial" }}
                            label={
                              <>
                                Industry Certification License&nbsp;
                                <span style={{ color: "red" }}>*</span>
                              </>
                            }
                            isDisabled={true}
                          />
                          <span>
                            {errors.industry_cert_license &&
                              touched.industry_cert_license &&
                              errors.industry_cert_license}
                          </span>
                        </Box>
                      </Grid> */}
                    </Grid>

                    <Box className={`${styles.button} button-primary`}>
                      <Button type="submit" disabled={loading}>
                        {" "}
                        {loading ? (
                          <p style={{ color: "white" }}>Loading...</p>
                        ) : (
                          "Save"
                        )}
                      </Button>
                      <Button className="secondary-btn" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
