import { Box } from "@mui/material";
import React, { useState } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import styles from "./styles.module.scss";
const CustomSelect = ({
  options,
  label,
  customClass,
  sx,
  placeholder,
  disable,
  isInduction = false,
  ...rest
}) => {
  const [test, settest] = useState(true);
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isFocused ? "#00CFC5" : "",
        color: isFocused ? "white" : "black",
      };
    },
  };
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">
          {options?.length === 0
            ? "No data"
            : (() => {
                setTimeout(() => {
                  settest(false);
                }, 1000);

                if (test) {
                  return "Loading...";
                } else {
                  return "No data";
                }
              })()}
        </span>
      </components.NoOptionsMessage>
    );
  };
  return (
    <>
      <Box
        className={`${styles.select} selectCreate ${
          customClass ? customClass : ""
        }`}
        sx={sx}
      >
        <label>{label}</label>
        <Select
          options={options}
          isDisabled={disable}
          classNamePrefix="react-select"
          placeholder={placeholder || "Select"}
          styles={colourStyles}
          components={{ NoOptionsMessage }}
          isMulti={isInduction}
          {...rest}
        />
        {/* <AsyncSelect
          defaultOptions
          cacheOptions
          loadOptions={options}
          classNamePrefix="react-select"
          placeholder={placeholder || "Select"}
          styles={colourStyles}
          {...rest}
        /> */}
      </Box>
    </>
  );
};
// Certification & Licenses
export default CustomSelect;
