export const routes = {
  HOME: "/",
  LOGIN: "/login",
  SIGNUP: "/sign-up",
  PAYMENT: "/payment",
  FORGET_PASSWORD: "/forgot-password",
  TERMS_AND_CONDITION: "/terms-conditions",
  PRIVACY_POLICY: "/privacy-policy",
  NOT_FOUND: "*",
  PROFILE: "/dashboard/profile",
  DASHBOARD: "/dashboard/activity",
  INDUSTRY: "/dashboard/industry",
  CANDIDATE: "/dashboard/candidates",
  ALL_CANDIDATES: "/dashboard/candidates/all-candidates",
  NEW_REQUEST: "/dashboard/candidates/new-request",
  SINGLE_CANDIDATE: "/dashboard/candidates/single-candidate",
  PREVIEW_CANDIDATE_FORM: "/dashboard/candidateform",
  WORKSPACE: "/dashboard/workspace",
  PRICING: "/dashboard/pricing",
  TEMPLATE_BUILDER: "/dashboard/templates/",
  JOBS: "/dashboard/jobs",
  JOBS_CREATE: "/dashboard/jobs/edit",
  HELP_DESK: "/dashboard/help-desk/",
  HELP_DESK_REQ: "/dashboard/req-help-desk/",
  FORMS: "/dashboard/forms/:category",
  CREATE_NEW_FORM: "/dashboard/create-new-form",
  ALL_FORMS: "/dashboard/all-forms",
  ARCHIVED: "/dashboard/archived",
  DRAFT: "/dashboard/draft",
  SUB_LIST: "/dashboard/submission",
  JOBS_SEARCH: "/jobs/search",
  ALL_FILES: "/dashboard/files/index",
  ARCHIVED_FILES: "/dashboard/files/archived/index",
  SUB_FILE_LIST: "/dashboard/files/submission/index",
  CONTACTS: "/contact-us",
  Modals: "/Index",

  BLOGS: "/resources",
  PRICINGTABLE: "/pricingtable",
  GUEST: "/guest",
  BLOGSPOSTS: "/resources/detail/:slug",
  JOBSDETAILS: "/jobs/detail",
  RESEND_VERIFICATION: "/resend-verification",
  FORM_BUILDER: "/dashboard/create-form",
  FORM_BUILDER_EDIT: "/dashboard/edit-form",

  // --------
  DOCUMENTS: "/dashboard/documents",
  CREATE_NEW_DOCUMENT: "/dashboard/documents/create-new-document",
  ALL_DOCUMENTS: "/dashboard/documents/all-documents",
  ALL_ARCHIVED: "/dashboard/documents/documents-archeived",
  // --------
  FAQS: "/dashboard/help-desk/faqs",
  FAQS_REQ: "/dashboard/req-help-desk/faqs",
  CONTACT: "/dashboard/help-desk/contact-us",
  CONTACT_REQ: "/dashboard/req-help-desk/contact-us",
  INTEGRATIONS: "/dashboard/integration",
  CANDIDATE_DASHBOARD: "/dashboard/candidate",
  CANDIDATE_JOBS: "/dashboard/jobs-candidate",
  CANDIDATE_PROFILE: "/dashboard/profile-candidate",
  CANDIDATE_INTERVIEW: "/dashboard/interview-candidate",
  CANDIDATE_LICENSE: "/dashboard/license-candidate",
  CANDIDATE_BACKGROUND_CHECK: "/dashboard/background-check-license-candidate",
  verify_token: "/user/verify",
  verify_email_forget_password: "/user/reset-password",
  REF: "/reference",
  NEW_USER_REQUEST: "/user/request/accept",
  NEW_USER_REJECT: "/user/request/deny",
  COMPANY: "/dashboard/company",
  INTERVIEW: "/dashboard/interview",
  ADMINLOGIN: "/adminlogin/:slug",
  FORM_VIEW: "/dashboard/form/view",
  GUEST_FROM_REQUEST: "/guest/form-request/accept",

  CANDIDATE_DOC_LIST: "/dashboard/EDocument/list",

  // ORDER_HISTORY
  ORDER_LIST: "/dashboard/pricing/orders/",
  ORDER_DETAIL: "/dashboard/pricing/order",
  LICENSE_ORDER_LIST: "/dashboard/pricing/license-orders/",
  LICENSE_ORDER_DETAIL: "/dashboard/pricing/license-orders-detail/",

  //
  SUBSCRIPTION_GET: "/dashboard/pricing/subscription/",
  SUBSCRIPTION_UPDATE: "/dashboard/pricing/subscription-update/",
  SUBSCRIPTION_DELETE: "/dashboard/pricing/subscription-delete",

  // INDUSTRY_CREATE_PAGE: "/dashboard/industry/create-page-1",
  // INDUSTRY_CREATE_PAGE2: "/dashboard/industry/create-page-2",
  // REAL_STATE: "/dashboard/industry/real-state",
  // HOSPITALITY: "/dashboard/industry/hospitality",
  // GIG_ECONMY: "/dashboard/industry/gig-econmy",
  // HEALTH_CARE: "/dashboard/industry/health-care",
  // RECRUITMENT: "/dashboard/industry/recruitment",
  // TRANSPORT: "/dashboard/industry/transport",
  // ENGENERING: "/dashboard/industry/engineering",
  // AGED_CARE: "/dashboard/industry/age-care",

  REQUEST_LIST: "/dashboard/request/list",
  SUBMISSION_LIST: "/dashboard/submit/list",
  GUEST_REFERENCE: "/guest/reference/accept",
  DOCUMENT_SIGN: {
    UPLOAD_PDF: "/dashboard/document/documentSign",
    SINNER: "/dashboard/document/selectSinner",
    DRAG_FIELDS: "/dashboard/document/assignFields",
    DOC_SIGN: "/dashboard/document/signDoc",
    VIEW_SIGNING_DOC: "/dashboard/document/viewSigningDoc",
    DOC_PREVIEW_SINNER: "/dashboard/document/DocPreviewCombinedSigners",
    DOC_SIGN_GUEST: "/dashboard/document/signDoc",
    DOC_PDF_VIEW: "/dashboard/document/DocPreviewCombinedSigners/pdfview",
  },

  CAN_DOCUMENT: {
    DOC_SIGN_CAN: "/dashboard/EDocument/signDoc",
    VIEW_SIGNING_DOC_CAN: "/dashboard/EDocument/viewSigningDoc",
    DOC_PREVIEW_SINNER_CAN: "/dashboard/EDocument/DocPreviewCombinedSigners",
  },
  BACKYCHECK_URL: {
    AMICI_URL: "/dashboard/backycheck/amici",
    NPCS_URL: "/dashboard/backycheck/npcs",
  },
};
