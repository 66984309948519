import React, { useState } from "react";

import TextInput from "../../common/TextInput/index";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./styles.scss";
import { Box, Typography, Grid, Button } from "@mui/material";
import PhotoPopup from "../../photo_popup";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateSingleReffFunc2 } from "../../../store/actions/singleCandidateScreenActions";
import { useParams } from "react-router-dom";
import CountrySelect from "../../common/CountrySelect/CountrySelect";
// import { useDispatch, useSelector } from "react-redux";

const InterviewPopup = ({ opne, setOpen, data, name, certificate, setIsEdit = false, isEdit = false }) => {
  const [isPhotoOpen, setIsPhotoOpen] = useState(false);
  const [photoUrl, setPhotoUrl] = useState('');
  const [loading, setLoading] = useState(false);
   const { uuid } = useParams();
   const candidateId = uuid;
  const auth = useSelector((state) => state?.auth?.results);
  
  const dispatch = useDispatch();
  function convertDate(dateStr) {
    if(dateStr){
      const months = {
        'jan': '01', 'feb': '02', 'mar': '03', 'apr': '04', 'may': '05', 'jun': '06',
        'jul': '07', 'aug': '08', 'sep': '09', 'oct': '10', 'nov': '11', 'dec': '12'
      };
    
      const [day, monthStr, year] = dateStr.split('-');
      const month = months[monthStr.toLowerCase()];
    
      return `${day}-${month}-${year}`;
    }
    return '';
  }
  
  const openPhoto = (url) => {
    setIsPhotoOpen(true);
    setPhotoUrl(url);
  }
  const referenceSchema = Yup.object().shape({
    full_name: Yup.string().required("Full name is required"),
    job_title: Yup.string().required("Job title is required"),
    email: Yup.string().required("Email address is required"),
    contact_number: Yup.string().required("Phone number is required"),
    reference_relationship: Yup.string().required("Reference relationship is required"),
    reference_company_name: Yup.string().required("Reference company name is required"),
    // company_start_date: Yup.string().required("Start date is required"),
    // company_end_date: Yup.string().required("End date is required"),
  });
  const formData = useFormik({
    validationSchema: referenceSchema,
    initialValues:{
      uuid: data?.uuid,
      full_name: data?.full_name,
      job_title: data?.job_title,
      email: data?.email,
      contact_number: data?.contact_number,
      reference_relationship: data?.reference_relationship,
      reference_company_name: data?.reference_company_name,
      candidate_id: candidateId,
      recruiter_id: auth?.recruiter_id,
      workspace_id: auth?.workspace_id,
    },
    onSubmit: (values) => {
      try{

        setLoading(true);
        dispatch(updateSingleReffFunc2(values, {
          workspace_id: auth?.workspace_id,
          candidate_id: data?.candidate_id,
        })).then(() => {
          setLoading(false);
          setOpen(false);
        });
      }catch(error){
        console.error("Error", error);
        setLoading(false);
        setOpen(false);
      }
    },
  })
  const handleClose = () => {
    setIsEdit(false);
    setOpen(false);
  }
  return (
    <><Box className="interviewParrent">
      <Typography variant="h3">{name || "Referee Details"}</Typography>
      {!certificate && (
         <form onSubmit={formData.handleSubmit}>
        <Grid container spacing={2}>
        
        <Grid item xs={12} lg={6}>
          <TextInput
            disabled={isEdit ? false : true}
            label="Full Name"
            name="full_name"
            value={formData.values.full_name}
            onChange={(e) => {
              formData.handleChange(e);
            }}
             />
            <span className="error-inputfield">
              {formData.errors.full_name &&
                formData.errors.full_name}
            </span>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            disabled={isEdit ? false : true}
            label="Job Title"
            name="job_title"
            value={formData.values.job_title}
            onChange={(e) => {
              formData.handleChange(e);
            }}
             />
            <span className="error-inputfield">
              {formData.errors.job_title &&
                formData.errors.job_title}
            </span>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            disabled={isEdit ? false : true}
            label="Email"
            name="email"
            value={formData.values.email}
            onChange={(e) => {
              formData.handleChange(e);
            }}
             />
            <span className="error-inputfield">
              {formData.errors.email &&
                formData.errors.email}
            </span>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            disabled={isEdit ? false : true}
            label="Phone Number"
            name="contact_number"
            value={formData.values.contact_number}
            onChange={(e) => {
              formData.handleChange(e);
            }}
             />
            <span className="error-inputfield">
              {formData.errors.contact_number &&
                formData.errors.contact_number}
            </span>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            disabled={isEdit ? false : true}
            label="Reference Relationship"
            name="reference_relationship"
            value={formData.values.reference_relationship}
            onChange={(e) => {
              formData.handleChange(e);
            }}
             />
            <span className="error-inputfield">
              {formData.errors.reference_relationship &&
                formData.errors.reference_relationship}
            </span>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            disabled={isEdit ? false : true}
            label="Reference Company Name"
            name="reference_company_name"
            value={formData.values.reference_company_name}
            onChange={(e) => {
              formData.handleChange(e);
            }}
             />
             <span className="error-inputfield">
              {formData.errors.reference_company_name &&
                formData.errors.reference_company_name}
            </span>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Box className={`button-primary d-flex align-items-center justify-content-start`} style={{gap: '1rem'}}>
            <Button type="submit" disabled={loading}>
              {" "}
              {loading ? (
                <p style={{ color: "white" }}>Loading...</p>
              ) : (
                "Save"
              )}
            </Button>
            <Button className="secondary-btn" onClick={handleClose}>
              Cancel
            </Button>
            </Box>
        </Grid>
      </Grid>
      </form>
      )}
      {certificate && !data?.is_visa && !data?.is_backy_check && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="First Name"
              value={data?.first_name}
               />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Last Name"
              value={data?.last_name}
               />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Email"
              value={data?.email}
               />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Date Of Birth"
              value={new Date(data?.dob).toLocaleDateString("en-GB")}
              
              style={{ textTransform: "uppercase" }} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="State"
              value={data?.state || ""}
               />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Country"
              value={data?.country}
               />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Industry Certificate Name"
              value={data?.industry_certification_license?.name}
               />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Recruiter Industry"
              value={data?.recruiter_industry?.name}
               />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Issue Date"
              value={data?.issue_date ? new Date(data?.issue_date).toLocaleDateString("en-GB") : new Date().toLocaleDateString("en-GB")}
               />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Expiry Date"
              value={data?.expiry_date ? new Date(data?.expiry_date).toLocaleDateString("en-GB") : new Date().toLocaleDateString("en-GB")}
               />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextInput
              disabled={true}
              label="Document Number"
              value={data?.document_number}
               />
          </Grid>

          <Grid item xs={6} lg={6}>
            <Box className="stars-form">
              <label>Front Image</label>
              {data?.front_image ? (
                <img
                  src={`${process.env.REACT_APP_URL}${data?.front_image}`}
                  width={70}
                  height={70}
                  alt="front-img" 
                  onClick={()=>{
                    openPhoto(`${process.env.REACT_APP_URL}${data?.front_image}`);
                  }}/>
              ) : (
                "No image uploaded"
              )}
            </Box>
          </Grid>
          <Grid item xs={6} lg={6}>
            <Box className="stars-form">
              <label>Back Image</label>
              {data?.back_image ? (
                <img
                  src={`${process.env.REACT_APP_URL}${data?.back_image}`}
                  width={70}
                  height={70}
                  alt="back-img" 
                  onClick={()=>{
                    openPhoto(`${process.env.REACT_APP_URL}${data?.back_image}`);
                  }}/>
              ) : (
                "No image uploaded"
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      {certificate && data?.is_visa && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Given Name"
              value={`${data?.first_name}`}
               />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Family Name"
              value={`${data?.last_name}`}
               />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Email"
              value={data?.email}
              
              style={{ textTransform: 'lowercase' }} />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled={true}
              label="Date of birth"
              type="date"
              name="dob"
              placeholder={"Date of birth"}
              max={new Date().toISOString().split("T")[0]}
              value={data?.dob}
              
              style={{ textTransform: "uppercase" }} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Country of Document"
              value={data?.country}
               />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Document Number"
              value={data?.document_number}
               />
          </Grid>
          {/* <Grid item xs={12} lg={12}>
            <TextInput
              disabled={true}
              label="Recruitment Industries"
              value={data?.recruiter_industry?.name}
               />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextInput
              disabled={true}
              label="Industry Certification License"
              value={data?.industry_certification_license?.name}
               />
          </Grid> */}
        </Grid>)}
      {certificate && data?.is_backy_check && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="First Name"
              value={`${data?.first_name}`}
               />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Middle Name"
              value={`${data?.middle_name}`}
               />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Surname"
              value={`${data?.last_name}`}
               />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Email"
              value={data?.email}
              
              style={{ textTransform: 'lowercase' }} />
          </Grid>

          <Grid item xs={6}>
            <TextInput
              disabled={true}
              label="Date of birth"
              type="date"
              name="dob"
              placeholder={"Date of birth"}
              max={new Date().toISOString().split("T")[0]}
              value={data?.dob}
              
              style={{ textTransform: "uppercase" }} />
          </Grid>
          {data?.backy_check_type.toLowerCase() === 'wwc' && (<>
            <Grid item xs={6}>
              <TextInput
                disabled={true}
                label="Document Number"
                type="text"
                name="document_number"
                placeholder={"Document Number"}
                value={data?.document_number}
                 />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                disabled={true}
                label="Card Expiry Date"
                type="text"
                name="expiry_date"
                placeholder={"Card Expiry Date"}
                value={convertDate(data?.expiry_date)}
                 />
            </Grid>
            <Grid item xs={6}>
              {/* <TextInput
                disabled={true}
                label="Card State Issue"
                type="text"
                name="state"
                placeholder={"Card State Issue"}
                value={data?.state}
                 /> */}

              <Field
                compolsory={true}
                // customClass={styles.input}
                label="Card State Issue"
                name="state"
                component={CountrySelect}
                placeholder="Card State Issue"
                isStateList={true}
                value={data?.state}
                style={{ marginTop: "19px", minHeight: "41px" }}
              />
            </Grid>
          </>)}
          {/* <Grid item xs={12} lg={12}>
            <TextInput
              disabled={true}
              label="Recruitment Industries"
              value={data?.recruiter_industry?.name}
               />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextInput
              disabled={true}
              label="Industry Certification License"
              value={data?.industry_certification_license?.name}
               />
          </Grid> */}
        </Grid>)}
    </Box>
    <PhotoPopup 
     isPhotoOpen={isPhotoOpen}
     setIsPhotoOpen={setIsPhotoOpen}
     imageUrl={photoUrl}
     />
    </>
  );
};

export default InterviewPopup;
