import { UPLOAD_FILE, UPLOAD_FILE_RESET } from "../constants/constants";

export const uploadfile = (name, value) => {
  return {
    type: UPLOAD_FILE,
    name: name,
    payload: { name: name, value: value },
  };
};

export const resetFile = (name, value) => {
  return {
    type: UPLOAD_FILE_RESET,
    name: name,
    payload: [],
  };
};
