import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import { Box } from "@mui/material";
import CustomSelect from "../../common/Select";
import TextInput from "../../common/TextInput";
import {
  getOrganisationClientList,
  getOrganisationList,
} from "../../../api/organisation/organisation.class";

const PoliceCheckForm = ({
  clientInfoData,
  onChangeOrgInfo,
  orgInfoValidations,
  formErrors,
}) => {
  const [organisationOption, setOrganisationOption] = useState([]);
  const [organisationClientOption, setOrganisationClientOption] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(
    clientInfoData?.organisationId !== "" ? clientInfoData?.organisationId : 0
  );

  useEffect(() => {
    const apiCall = async () => {
      try {
        getOrganisationList().then((response) => {
          if (response?.success) {
            setOrganisationOption(response?.results);
            setOrganisationClientOption([]);
          }
        });
      } catch (error) {
        setOrganisationOption([]);
        setOrganisationClientOption([]);
      }
    };
    apiCall();
  }, []);

  useEffect(() => {
    const apiCall = async () => {
      try {
        getOrganisationClientList(selectedOrgId).then((response) => {
          if (response?.success) {
            setOrganisationClientOption(response?.results);
          }
        });
      } catch (error) {
        setOrganisationClientOption([]);
      }
    };
    apiCall();
  }, [selectedOrgId]);

  return (
    <Box className="mt-3">
      <div>
        <label>Are you recruiter hiring on behalf of an organisation? </label>
        <div style={{ display: "flex", gap: "1rem" }}>
          <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <input
              type="radio"
              id="is_organisation_yes"
              checked={clientInfoData.isCurrentUserClient === true}
              value={"yes"}
              name={"is_organisation"}
              onChange={() => {
                onChangeOrgInfo({ isCurrentUserClient: true });
              }}
            />
            <label htmlFor="is_organisation_yes" style={{ marginBottom: "0" }}>
              Yes
            </label>
          </div>
          <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <input
              type="radio"
              id="is_organisation_no"
              checked={clientInfoData.isCurrentUserClient === false}
              value={"No"}
              name={"is_organisation"}
              onChange={() => {
                onChangeOrgInfo({
                  isCurrentUserClient: false,
                  isExistingOrg: false,
                  isExistingClient: false,
                });
              }}
            />
            <label htmlFor="is_organisation_no" style={{ marginBottom: "0" }}>
              No
            </label>
          </div>
        </div>
        <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <span
            style={{
              color: "red",
              marginLeft: "3px",
              position: "relative",
              display: "initial",
            }}
          >
            {formErrors && formErrors.isCurrentUserClientError
              ? formErrors.isCurrentUserClientError
              : ""}
          </span>
        </div>
      </div>

      {clientInfoData.isCurrentUserClient && (
        <>
          <p className={"text-justify mt-4"} style={{ fontSize: "0.9rem" }}>
            If you are a recruitment agency or are a third party representative
            hiring for this role, then under the supplier terms of ACIC we are
            required to only give visibility of Nationally Coordinated Criminal
            Check results to the direct organisation hiring for this role.
            Please check this box in this instance and you will be given a text
            field to implement the direct hiring manager's email address where
            they will be able to receive the results of this background check
            directly.
          </p>

          <div className="mt-2">
            <label>An existing organisation? </label>
            <div style={{ display: "flex", gap: "1rem" }}>
              <div
                style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
              >
                <input
                  type="radio"
                  id="is_existing_organisation_yes"
                  checked={clientInfoData.isExistingOrg === true}
                  value={"yes"}
                  name={"is_existing_organisation"}
                  onChange={() => {
                    onChangeOrgInfo({
                      isExistingOrg: true,
                      isExistingClient: false,
                    });
                  }}
                />
                <label
                  htmlFor="is_existing_organisation_yes"
                  style={{ marginBottom: "0" }}
                >
                  Yes
                </label>
              </div>
              <div
                style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
              >
                <input
                  type="radio"
                  id="is_existing_organisation_no"
                  checked={clientInfoData.isExistingOrg === false}
                  value={"No"}
                  name={"is_existing_organisation"}
                  onChange={() => {
                    onChangeOrgInfo({
                      isExistingOrg: false,
                      isExistingClient: false,
                    });
                  }}
                />
                <label
                  htmlFor="is_existing_organisation_no"
                  style={{ marginBottom: "0" }}
                >
                  No
                </label>
              </div>
            </div>

            {clientInfoData.isExistingOrg ? (
              <>
                <CustomSelect
                  options={organisationOption}
                  customClass={"interviewSelect mt-1"}
                  label={"Select Organisation"}
                  onChange={(e) => {
                    setSelectedOrgId(e.value);
                    onChangeOrgInfo({ organisationId: e.value });
                  }}
                  placeholder={"Select Organisation"}
                  value={organisationOption.find(
                    (option) => option.value === clientInfoData.organisationId
                  )}
                  // onInputChange={orgInfoValidations}
                  onMenuClose={orgInfoValidations}
                />
                <span
                  style={{
                    color: "red",
                    marginLeft: "3px",
                    position: "relative",
                    display: "initial",
                  }}
                >
                  {formErrors && formErrors.organisationIdError
                    ? formErrors.organisationIdError
                    : ""}
                </span>
              </>
            ) : (
              <div className="mt-2">
                <label>Organisation Name</label>
                <TextInput
                  style={{ marginTop: "0" }}
                  name="organisation_name"
                  value={clientInfoData.organisationName}
                  onBlur={orgInfoValidations}
                  onChange={(e) => {
                    onChangeOrgInfo({ organisationName: e.target.value });
                  }}
                />
                <span
                  style={{
                    color: "red",
                    marginLeft: "3px",
                    position: "relative",
                    display: "initial",
                  }}
                >
                  {formErrors && formErrors.organisationNameError
                    ? formErrors.organisationNameError
                    : ""}
                </span>
              </div>
            )}
          </div>

          <div className="mt-2">
            {clientInfoData.isExistingOrg && (
              <>
                <label>An existing client? </label>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="is_existing_client_yes"
                      checked={clientInfoData.isExistingClient === true}
                      value={"yes"}
                      name={"is_existing_client"}
                      onChange={() => {
                        onChangeOrgInfo({ isExistingClient: true });
                      }}
                    />
                    <label
                      htmlFor="is_existing_client_yes"
                      style={{ marginBottom: "0" }}
                    >
                      Yes
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      checked={clientInfoData.isExistingClient === false}
                      id="is_existing_client_no"
                      value={"No"}
                      name={"is_existing_client"}
                      onChange={() => {
                        onChangeOrgInfo({ isExistingClient: false });
                      }}
                    />
                    <label
                      htmlFor="is_existing_client_no"
                      style={{ marginBottom: "0" }}
                    >
                      No
                    </label>
                  </div>
                </div>
              </>
            )}

            {clientInfoData.isExistingClient && (
              <>
                <CustomSelect
                  options={organisationClientOption}
                  customClass={"interviewSelect mt-1"}
                  label={"Select Client"}
                  placeholder={"Select Client"}
                  onChange={(e) => {
                    onChangeOrgInfo({ clientId: e.value });
                  }}
                  value={organisationClientOption.find(
                    (option) =>
                      option.value.toString() ===
                      clientInfoData.clientId.toString()
                  )}
                  // onInputChange={orgInfoValidations}
                  onMenuClose={orgInfoValidations}
                />
                <span
                  style={{
                    color: "red",
                    marginLeft: "3px",
                    position: "relative",
                    display: "initial",
                  }}
                >
                  {formErrors && formErrors.clientIdError
                    ? formErrors.clientIdError
                    : ""}
                </span>
              </>
            )}

            {(clientInfoData.isExistingClient === false ||
              clientInfoData.isExistingOrg === false) && (
              <div className="mt-2">
                <label>Client Name</label>
                <TextInput
                  style={{ marginTop: "0" }}
                  name="client_name"
                  value={clientInfoData.clientName}
                  onBlur={orgInfoValidations}
                  onChange={(e) => {
                    onChangeOrgInfo({ clientName: e.target.value });
                  }}
                />
                <span
                  style={{
                    color: "red",
                    marginLeft: "3px",
                    position: "relative",
                    display: "initial",
                  }}
                >
                  {formErrors && formErrors.clientNameError
                    ? formErrors.clientNameError
                    : ""}
                </span>
                <label>Client Email</label>
                <TextInput
                  style={{ marginTop: "0" }}
                  name="client_email"
                  type="email"
                  value={clientInfoData.clientEmail}
                  onBlur={orgInfoValidations}
                  onChange={(e) => {
                    onChangeOrgInfo({ clientEmail: e.target.value });
                  }}
                />
                <span
                  style={{
                    color: "red",
                    marginLeft: "3px",
                    position: "relative",
                    display: "initial",
                  }}
                >
                  {formErrors && formErrors.clientEmailError
                    ? formErrors.clientEmailError
                    : ""}
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </Box>
  );
};

export default PoliceCheckForm;
