import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSelect from "../Select";
import styles from "./styles.module.scss";
import { ReactComponent as Notification } from "../../../assets/dashboard/bell.svg";
import { ReactComponent as Eye } from "../../../assets/candidates/eye.svg";
import { ReactComponent as Verification } from "../../../assets/new-icons/verification.svg";
import { ReactComponent as Resend } from "../../../assets/new-icons/resend.svg";
import { ReactComponent as Send } from "../../../assets/new-icons/send.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getCandidateFormDetail,
  getCandidateFormDetail2,
  getCandidateFormDetail3,
  getCandidateFormDetail4,
  getCandidateFormSelect,
  reqCandidateFormDetail,
  reqCandidateFormDetail2,
  reqCandidateFormDetail3,
  reqCandidateFormDetail4,
  reqCandidateFormDetail5,
  resendFormCandidate,
  resendFormCandidate2,
  resendFormCandidate3,
  resendFormCandidate4,
  resetSelecCandidate,
  viewForm,
} from "../../../store/actions/applyJobActions";
import { ShowAlert } from "../../../store/actions/alertActions";
import CustomModel from "../../candidates/single-candidate-model";
import ResendModel from "../../candidates/resend-form";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import {
  getAllRecruiterCandidateReff,
  getAllRecruiterCandidateForms,
  getAllRecruiterCandidateForms1,
  getAllRecruiterCandidateForms2,
  getAllRecruiterCandidateForms3,
} from "../../../store/actions/singleCandidateScreenActions";
import * as flatted from "flatted";
import { EDH } from "../../../utils/EDH";
import { downloadFile, uploadFile } from "../../../utils/s3Config";
import CryptoJS from "crypto-js";
import axios from "axios";
import { setPdfImagesArray } from "../../../store/actions/pdfupload.actions";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist/build/pdf";
import { uid } from "../../../utils/helpers";
import { GET_CANDIDATE_FORM_SENT_START_3, GET_CANDIDATE_FORM_SENT_START_4, LOADING_FALSE, LOADING_TRUE } from "../../../store/constants/constants";
import { storeSigningService } from "../../../api/sinner/SinnerApi";

GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js";

const SelectSection = ({
  title,
  des,
  des2,
  results,
  cata,
  uuid,
  id,
  placeholder,
}) => {
  const [open, setOpen] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [send, setSend] = useState(false);
  const [loading, setLoading] = useState(false);

  const applyJobs = useSelector((state) => state?.applyJob);
  const candidate = useSelector((state) => state?.rcandidate);
  const candidateID = JSON.parse(localStorage.getItem("candidateID"));
  const auth = useSelector((state) => state?.auth);
  const [documentImages, setDocumentImages] = useState([]);
  const [heightOfPage, setHeightOfPage] = useState(0);
  const [docInfo, setDocInfo] = useState({
    isPasswordRequired: false,
    title: '',
  });
  const options = applyJobs?.selectForms?.results?.map((e) => {
    return { label: e?.name, value: e?.id, isEDoc: e?.isEDoc, data:e };
  });

  const [values, setValues] = useState({
    form_builder_id: "",
  });
  const [Customerror, setErrors] = useState({});
  const validate = (data) => {
    const newError = {};
    for (let key in data) {
      let value = data[key];
      switch (key) {
        case "form_builder_id":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "fields is Required";
          break;
        default:
      }
    }
    return newError;
  };
  const viewData = () => {
    if (results?.results?.response === "Completed") {
      dispatch(viewForm(results?.results?.submit_data?.uuid)).then(() => {
        navigate(`${routes.FORM_VIEW}/${results?.results?.submit_data?.uuid}`, {
          state: {
            type: "submission",
            uuid: results?.results?.submit_data?.uuid,
            data: results?.results?.form_builder?.form_builder_json,
          },
        });
      });
    }
  };

  const resetForm = () => {
    setValues({
      form_builder_id: ''
    });
  };

  const loadPdf = async ({ pdfUrl = null, signers = null }) => {
    try {
      // ref.current.continuousStart();
      let pdfFile = null;
      if (pdfUrl) {
        const url =  downloadFile(pdfUrl);
        const response = await axios.get(url, {
          responseType: "arraybuffer", // Important for handling binary data
        });

        const blob = new Blob([response.data], { type: "application/pdf" });

        pdfFile = new File([blob], "sample.pdf", {
          lastModified: new Date().getTime(),
        });
        // dispatch(setUploadPdf(file));
      }
      const url =  downloadFile(pdfUrl);
      console.log("url", url);
      const pdf = await getDocument(url).promise;
      const arr = [];
      let totalHeight = 0;
      let totalWidth = 0;
      let pageHeight = 0;

      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const scale = 1.0,
          viewport = page.getViewport({ scale }),
          canvas = document.createElement("canvas"),
          context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        pageHeight = viewport.height;

        totalHeight = totalHeight + canvas.height;
        totalWidth = canvas.width;

        const task = page.render({
          canvasContext: context,
          viewport: viewport,
        });

        await task.promise;
        arr.push({
          imgBase64: canvas.toDataURL(),
          height: canvas.height,
          width: canvas.width,
        });
      }
      console.log(arr);
      setDocumentImages(arr);
      dispatch(setPdfImagesArray([...arr]));
      setHeightOfPage(pageHeight);
      const pdfMetadata = {};
      pdfMetadata.pdfImagesList = arr;
      pdfMetadata.containerSize = {
        width: totalWidth,
        height: totalHeight,
      };
      pdfMetadata.pageHeight = pageHeight;
      pdfMetadata.id = new Date().getTime();
      pdfMetadata.sender = {
        name: `${auth?.results?.first_name} ${auth?.results?.last_name}`,
        email: auth?.results?.email
      };
      pdfMetadata.id = '';
      if(signers.length){
        signers.map((signer, index) => {
          // if(signer.hasOwnProperty('me')){
          //   signer.me = 'me';
          //   signer.name = `${auth?.results?.first_name} ${auth?.results?.last_name}`;
          //   signer.email = auth?.results?.email;
          // }else{
            signer.me = '';
            signer.name = `${candidate?.showCandidate?.results?.first_name} ${candidate?.showCandidate?.results?.last_name}`;
            signer.email = candidate?.showCandidate?.results?.email;
            signer.contact_number = candidate?.showCandidate?.results?.contact_number;
          // }
          signer.color = "#f7f7f7";
          signer.isValidated = true;
          signer.id = new Date().getTime();
          return signer;
        });
      }
      pdfMetadata.signers = signers;
      return {pdfMetadata, pdfFile}
    } catch (error) {
      console.error(error);
    }
  };


  const convertToBase64 = (pdfFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(pdfFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleMetadataSubmit = async (uuid, pdfUrl, data = null) => {
    
    const {pdfMetadata, pdfFile} = await loadPdf({pdfUrl, signers: data?.doc_data});
    setErrors(null);
    let err = null;
    docInfo.title = data?.name ? data?.name : (data?.title ? data?.title : 'Esign Template');
    docInfo.isPasswordRequired = false;

    // store pdf to s3
    const metadata = {};
    metadata.sessionId = uid();
    const senderBlob = {
      ...pdfMetadata,
    };

    const senderMetadata = {
      ...metadata,
      isSender: true,
      isMain: true,
    };

    // encrypt pdf and upload to s3 and then

    try {
      dispatch({
        type: LOADING_TRUE,
      });
      let encKey = docInfo.isPasswordRequired ? docInfo.password : process.env.REACT_APP_DEFAULT_PASSWORD;
      const encFile = await EDH.encryptFileAsync(pdfFile, encKey);
      const pdfLocation = `media/documentSigning/pdf/${auth?.results?.id}/${encFile.name}`;

      const uploadResult = await uploadFile(encFile, pdfLocation);

      const base64 = await convertToBase64(pdfFile).then((data) => {
        return data;
      });


      senderBlob.originalPdf = uploadResult.location;
      senderBlob.title = docInfo.title;
      senderBlob.desc = docInfo.desc;
      senderBlob.pdfOriginalImagesArray = pdfFile;
      senderBlob.arrayBuffer = base64;
      const senderBlobAsString = flatted.stringify(senderBlob);
      senderMetadata.blobHash = EDH.SHA1_hash(senderBlobAsString);
      const cipherText = CryptoJS.AES.encrypt(
        senderBlobAsString,
        encKey
      ).toString();
      senderMetadata.blob = cipherText;
      senderMetadata.fileData = base64;

      const senderMetadataAsJsonFile = EDH.objectToFile(senderMetadata);

      const senderFileResult = await uploadFile(
        senderMetadataAsJsonFile,
        `documentSigning/metadata/${auth?.results?.id}/${senderMetadataAsJsonFile.name}`
      );

      const receiversMetadataArray = await prepareReceiverMetadata(
        pdfMetadata.signers,
        metadata,
        encKey,
        senderBlob
      );

      const totalSigners = pdfMetadata.signers.length;
      const totalSigned = 0;

      const signers = [];
      receiversMetadataArray.forEach((element) => {
        let fieldData = [];
        element.signer.fields.map((elementData, index) => {
          fieldData[index] = elementData;
          delete fieldData[index]['signer'];
        });
        
        signers.push({
          name: element.signer.name,
          email: element.signer.email.toLowerCase(),
          metadataUrl: element.url,
          id: element.signer.id,
          isValidated: element.signer.isValidated,
          color: element.signer.color,
          fields: fieldData,
          contact_number: element.signer.contact_number
        });
      });

      let body = {
        ...docInfo,
        totalSigners,
        totalSigned,
        recruiter_id: auth?.results?.recruiter_id,
        workspace_id: auth?.results?.workspace_id,
        metadataUrl: senderFileResult.location,
        signers: signers,
        userName: auth?.results?.name,
        category: data?.doc_builder_category_id
      };
      body = { ...body, uuid: uuid, isDraft: 0 };
      return body;
    } catch (error) {
      console.error("submit error", error);
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
    }
  };

  const prepareReceiverMetadata = async (
    signers,
    baseMetadata,
    originalEncryptionKey,
    senderBlob
  ) => {
    const list = [];
    for (const signer of signers) {
      const blob = {
        ...senderBlob,
      };

      const isSender =
        signer.email.toLowerCase() === auth?.results?.email.toLowerCase();
      const metadata = {
        ...baseMetadata,
        isSender: isSender,
      };

      blob.signers = [signer];
      console.log(blob.signers);
      const signerBlobAsString = flatted.stringify(blob);

      metadata.blobHash = EDH.SHA1_hash(signerBlobAsString);

      const cipherText = CryptoJS.AES.encrypt(
        signerBlobAsString,
        originalEncryptionKey
      ).toString();
      metadata.blob = cipherText;
      const signerMetadataAsJsonFile = EDH.objectToFile(metadata);
      console.log("insidehandle meme prepareReceiverMetadata signerMetadataAsJsonFile", signerMetadataAsJsonFile);

      const signerFileResult = await uploadFile(
        signerMetadataAsJsonFile,
        `documentSigning/metadata/${auth?.results?.id}/${signerMetadataAsJsonFile?.name}`
      );
      list.push({ signer, url: signerFileResult.location });
    }
    console.log("insidehandle meme prepareReceiverMetadata list", list);
    return list;
  };

  const submitData = async (e) => {
    e.preventDefault();
    const validateSelect = validate(values);
    if (Object?.keys(validateSelect)?.length > 0) {
      setErrors(validateSelect);
      dispatch(ShowAlert("Please Select one option", "error"));
      return;
    }
    const form_builder_id = [];
    const isEDocArray = [];
    const eSignBody = [];
    let isEsignSelectd = false;
    if (Array.isArray(values?.form_builder_id)) {
      console.log("values?.form_builder_id", values?.form_builder_id);
      
      await Promise.all(values?.form_builder_id.map(async (data) => {
        if (data?.isEDoc) {
          if(id === 3 && !isEsignSelectd){
            dispatch({
              type: GET_CANDIDATE_FORM_SENT_START_3,
            });
          }
          if(id === 4 && !isEsignSelectd){
            dispatch({
              type: GET_CANDIDATE_FORM_SENT_START_4,
            });
          }
          isEsignSelectd = true;
          const body = await handleMetadataSubmit(data?.data?.uuid, data?.data?.edoc_url, data?.data);
          body.isFormBuilder = id;
          body.form_name = id === 3 ? "an induction form" : "a miscellaneous form";
          body.candidate_name = `${candidate?.showCandidate?.results?.first_name} ${candidate?.showCandidate?.results?.last_name}`;
          eSignBody[data?.data?.id] = body;
        }else{
          form_builder_id.push(data.value);
          isEDocArray[data.value] = data.isEDoc;
        }
        return true; // This ensures the promise resolves correctly
      }));
      // Proceed with any additional steps after completion, e.g., update the form_builder_id array
    } else {
      if (values?.form_builder_id?.isEDoc) {
        if(id === 3){
          dispatch({
            type: GET_CANDIDATE_FORM_SENT_START_3,
          });
        }
        if(id === 4){
          dispatch({
            type: GET_CANDIDATE_FORM_SENT_START_4,
          });
        }
        isEsignSelectd = true;
        const body = await handleMetadataSubmit(values?.form_builder_id?.data?.uuid, values?.form_builder_id?.data?.edoc_url, values?.form_builder_id?.data);
        body.isFormBuilder = id;
        body.form_name = id === 3 ? "an induction form" : "a miscellaneous Form";
        body.candidate_name = `${candidate?.showCandidate?.results?.first_name} ${candidate?.showCandidate?.results?.last_name}`;
        eSignBody[values?.form_builder_id?.data?.id] = body;
      }else{
        form_builder_id.push(values?.form_builder_id?.value);
        isEDocArray[values?.form_builder_id?.value] = values?.form_builder_id?.isEDoc;
      }

      // form_builder_id.push(values?.form_builder_id?.value);
    }

    const localData = {
      form_builder_id: form_builder_id,
      uuid: uuid,
      recruiter_id: auth?.results?.recruiter_id,
      candidate_id: candidate?.showCandidate?.results?.id || candidateID,
      workspace_id: auth?.results?.workspace_id,
      form_category_name: cata,
      is_edoc_array: isEDocArray,
    };
    
    if (id === 1) {
      dispatch(reqCandidateFormDetail(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Job Offer Letter",
          })
        ).then((e) =>{
          dispatch(
            getAllRecruiterCandidateForms2({
              candidate_id:
                candidate?.showCandidate?.results?.id || candidateID,
              form_builder_category_name: "Job Offer Letter",
            })
          )
          resetForm();
        }
        )
      );
    } else if (id === 2) {
      dispatch(reqCandidateFormDetail2(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail2({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Application Form",
          })
        ).then((e) =>{
          dispatch(
            getAllRecruiterCandidateForms3({
              candidate_id:
                candidate?.showCandidate?.results?.id || candidateID,
              form_builder_category_name: "Application Form",
            })
          )
          resetForm();
        }
        )
      );
    } else if (id === 3) {
      const results = []
      if(isEsignSelectd && eSignBody.length > 0){
        if(form_builder_id.length === 0){
          dispatch({
            type: GET_CANDIDATE_FORM_SENT_START_3,
          });
        }
        await Promise.all(eSignBody.map(async (body) => {
          const result = await storeSigningService(body);
          if (result.status !== 200) {
            results.push(result);
          }
        }));

        if(form_builder_id.length === 0){
          dispatch(
            getCandidateFormDetail3({
              uuid: uuid,
              recruiter_id: auth?.results?.recruiter_id,
              candidate_id: candidate?.showCandidate?.results?.id || candidateID,
              workspace_id: auth?.results?.workspace_id,
              form_category_name: "Induction Form",
            })
          ).then((e) => {
            dispatch(
              getAllRecruiterCandidateForms({
                candidate_id:
                  candidate?.showCandidate?.results?.id || candidateID,
                form_builder_category_name: "Induction Form",
              })
            )
            resetForm();
          }
          )
        }
      }
      if(form_builder_id.length > 0){
        dispatch(reqCandidateFormDetail3(localData)).then((e) =>
          dispatch(
            getCandidateFormDetail3({
              uuid: uuid,
              recruiter_id: auth?.results?.recruiter_id,
              candidate_id: candidate?.showCandidate?.results?.id || candidateID,
              workspace_id: auth?.results?.workspace_id,
              form_category_name: "Induction Form",
            })
          ).then((e) => {
            dispatch(
              getAllRecruiterCandidateForms({
                candidate_id:
                  candidate?.showCandidate?.results?.id || candidateID,
                form_builder_category_name: "Induction Form",
              })
            )
            resetForm();
          }
          )
        );
      }
    } else if (id === 4) {
      if(isEsignSelectd && eSignBody.length > 0){
        if(form_builder_id.length === 0){
          dispatch({
            type: GET_CANDIDATE_FORM_SENT_START_4,
          });
        }
        await Promise.all(eSignBody.map(async (body) => {
          const result = await storeSigningService(body);
          if (result.status !== 200) {
            results.push(result);
          }
        }));
        if(form_builder_id.length === 0){
          dispatch(
            getCandidateFormDetail4({
              uuid: uuid,
              recruiter_id: auth?.results?.recruiter_id,
              candidate_id: candidate?.showCandidate?.results?.id || candidateID,
              workspace_id: auth?.results?.workspace_id,
              form_category_name: "Miscellaneous Form",
            })
          ).then((e) => {
            dispatch(
              getAllRecruiterCandidateForms1({
                candidate_id:
                  candidate?.showCandidate?.results?.id || candidateID,
                form_builder_category_name: "Miscellaneous Form",
              })
            )
            resetForm();
          }
          )
        }
      }
      if(form_builder_id.length > 0){
        dispatch(reqCandidateFormDetail4(localData)).then((e) =>
          dispatch(
            getCandidateFormDetail4({
              uuid: uuid,
              recruiter_id: auth?.results?.recruiter_id,
              candidate_id: candidate?.showCandidate?.results?.id || candidateID,
              workspace_id: auth?.results?.workspace_id,
              form_category_name: "Miscellaneous Form",
            })
          ).then((e) => {
            dispatch(
              getAllRecruiterCandidateForms1({
                candidate_id:
                  candidate?.showCandidate?.results?.id || candidateID,
                form_builder_category_name: "Miscellaneous Form",
              })
            )
            resetForm();
          }
          )
        );
      }
    } else if (id === 5) {
      dispatch(
        reqCandidateFormDetail5({
          ...localData,
          form_category_name: "Reference Form",
          candidate_reference_id: "",
        })
      ).then((e) => {
        dispatch(
          getAllRecruiterCandidateReff({
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
          })
        )
        resetForm();
      }
      );
    } else {
    }
  };

  const resendData = async (e) => {
    e.preventDefault();
    // const validateSelect = validate(values);
    // if (Object?.keys(validateSelect)?.length > 0) {
    //   console.log();
    //   setErrors(validateSelect);
    //   dispatch(ShowAlert("Please Select one option", "error"));
    //   return;
    // }

    const localData = {
      // form_builder_id: values?.form_builder_id?.value,
      uuid: uuid,
      recruiter_id: auth?.results?.recruiter_id,
      candidate_id: candidate?.showCandidate?.results?.id || candidateID,
      workspace_id: auth?.results?.workspace_id,
      form_category_name:
        id === 1
          ? applyJobs?.candidateForm1.results?.form_builder_category?.name
          : id === 2
          ? applyJobs?.candidateForm2.results?.form_builder_category?.name
          : id === 3
          ? applyJobs?.candidateForm3.results?.form_builder_category?.name
          : id === 4
          ? applyJobs?.candidateForm4.results?.form_builder_category?.name
          : id === 5
          ? applyJobs?.candidateForm5.results?.form_builder_category?.name
          : "",
      form_builder_id:
        id === 1
          ? applyJobs?.candidateForm1.results?.form_builder?.id
          : id === 2
          ? applyJobs?.candidateForm2.results?.form_builder?.id
          : id === 3
          ? applyJobs?.candidateForm3.results?.form_builder?.id
          : id === 4
          ? applyJobs?.candidateForm4.results?.form_builder?.id
          : id === 5
          ? applyJobs?.candidateForm5.results?.form_builder?.id
          : "",
    };
    if (id === 1) {
      dispatch(resendFormCandidate(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Job Offer Letter",
          })
        )
      );
    } else if (id === 2) {
      dispatch(resendFormCandidate2(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail2({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Application Form",
          })
        )
      );
    } else if (id === 3) {
      dispatch(resendFormCandidate3(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail3({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Induction Form",
          })
        )
      );
    } else if (id === 4) {
      dispatch(resendFormCandidate4(localData)).then((e) =>
        dispatch(
          getCandidateFormDetail4({
            uuid: uuid,
            recruiter_id: auth?.results?.recruiter_id,
            candidate_id: candidate?.showCandidate?.results?.id || candidateID,
            workspace_id: auth?.results?.workspace_id,
            form_category_name: "Miscellaneous Form",
          })
        )
      );
    } else {
    }
  };

  return (
    <Box className={styles.headers}>
      <CustomModel open={open} setOpen={setOpen}>
        <ResendModel
          open={open}
          setOpen={setOpen}
          uuid={uuid}
          id={id}
          cata={cata}
          results={results}
        />
      </CustomModel>

      <Typography variant="h5">{title || "Job Offer Letter"}</Typography>
      {results === null && applyJobs?.isLoading === true ? (
        <div className={styles.boxLoading}>
          <div className={"loader"}>
            <CircularProgress />
          </div>
        </div>
      ) : (
        <>
          {/* {des && <Typography>{results?.results ? des2 : des}</Typography>} */}
          <Box className={styles.conents}>
            <Box className={styles.boxContainer}>
              <CustomSelect
                options={options}
                name="form_builder_id"
                customClass={styles.select}
                value={values?.form_builder_id}
                placeholder={placeholder}
                onChange={(e) => {
                  setValues({ ...values, form_builder_id: e });
                  // ShowJob(uuid)
                }}
                isInduction={id === 3}
                onFocus={() => {
                  dispatch(
                    getCandidateFormSelect({
                      workspace_id: auth?.results?.workspace_id,
                      form_category_name: cata,
                    })
                  );
                }}
                onFocusOut={() => {
                  dispatch(resetSelecCandidate());
                }}
                //   label="Select Industries"
              />
              <Box>
                <Tooltip title="Send">
                  <Send
                    onClick={(e) => {
                      submitData(e);
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
            {/* {  
                            results?.results ? (                            
                                <p style={{ margin: '0px' }}> {results?.results?.form_builder?.name} Sent</p>
                            ) : (
                                '' 
                            )

                        } */}

            {}
            {/* <div className={styles.spacebox}>
                            {results?.results &&
                                (results?.results?.response === 'pending' ? (
                                    <Box className={`${styles.para} text-warning`}>{results?.results?.response}</Box>
                                ) : (
                                    <Box className={`${styles.para} text-green`}>{results?.results?.response}</Box>
                                ))}
                        </div> */}
            {/* <div className={styles.boxContainer}>
                            {results?.results &&
                                (results?.results?.response === 'pending' ? (
                                    <Button
                                        onClick={(e) =>
                                        results?.results ? setOpen(true) : submitData(e)
                                        }
                                    >
                                        {results?.results ? "Resend" : "Send"}{" "}
                                    </Button>
                                ) : (''
                                    // <Box>
                                    //     <Tooltip title="Send">
                                    //         <Send onClick={(e) => submitData(e)} />
                                    //     </Tooltip>
                                    // </Box>
                                ))}
                        </div> */}

            {/* <Box className={styles.boxContainer}>
                            {results?.results?.response === 'pending' ? (
                                <Box>
                                    <Tooltip title="Resend">
                                        <Resend onClick={(e) => setOpen(true)} />
                                    </Tooltip>
                                </Box>
                                
                            ) : results?.results?.response === 'completed' ? (
                                <Box>
                                    <Tooltip title="View">
                                        <Eye onClick={viewData} />
                                    </Tooltip>
                                    <Button
                                        onClick={(e) =>
                                        results?.results ? setOpen(true) : submitData(e)
                                        }
                                    >
                                        {results?.results ? "Resend" : "Send"}{" "}
                                    </Button>
                                </Box>
                            ) : (
                                ''
                                // <Box>
                                //     <Tooltip title="Send">
                                //         <Send onClick={(e) => submitData(e)} />
                                //     </Tooltip>
                                // </Box>
                            )}{' '}
                            {results?.results?.response === 'pending' && (
                                <Box>
                                    <Tooltip title="Reminder">
                                        <Notification onClick={(e) => resendData(e)} />
                                    </Tooltip>
                                </Box>
                            )}
                        </Box> */}
          </Box>
        </>
      )}
    </Box>
  );
};

export default SelectSection;
