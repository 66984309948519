import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  ApplyJob,
  getSingleJob,
} from "../../../../store/actions/homeJobActions";
import styles from "./styles.module.scss";
import TextInput from "../../../common/TextInput";

export default function FormDialog({ open, setOpen }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state) => state?.auth);
  const hjob = useSelector((state) => state?.hjob);

  const [loading, setLoading] = useState(false);

  var candidate_name =
    auth?.results?.first_name + " " + auth?.results?.last_name;

  const [values, setValues] = useState({
    candidate_name: "" || candidate_name,
    job_title: "" || hjob?.singleJob?.results?.job_title,
    skills: "",
    experience: "",
    qualification: "",
  });

  const handleClose = (_, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  const appplyJob = async (e) => {
    setLoading(true);
    e.preventDefault();

    const local_data = {
      ...values,
      candidate_id: auth?.results?.candidate_id,
      uuid: hjob?.singleJob?.results?.uuid,
    };

    dispatch(ApplyJob(local_data)).then(() => {
      dispatch(getSingleJob(location?.state?.slug));
      setOpen(false);
    });
  };

  const [Customerror, setErrors] = useState({});
  const onChange = (e) => {
    const { name, value } = e?.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (Customerror.hasOwnProperty(name)) {
      delete Customerror[name];
      setErrors(Customerror);
    }
  };

  // useEffect(() => {
  //   if (hjob?.jobApply?.success) {
  //     setOpen(false);
  //   }
  // }, [hjob?.jobApply?.success]);
  return (
    <>
      <Box className={styles.contactUsContent}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextInput
              compolsory={true}
              onChange={onChange}
              placeholder={""}
              type="text"
              name="candidate_name"
              label="Name"
              value={values?.candidate_name}
              customClass={styles.contactusInp}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              compolsory={true}
              onChange={onChange}
              placeholder={""}
              type="text"
              name="job_title"
              label="Job Title"
              value={values?.job_title}
              customClass={styles.contactusInp}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              compolsory={false}
              onChange={onChange}
              placeholder={""}
              type="text"
              name="skills"
              label="Skills"
              customClass={styles.contactusInp}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              compolsory={true}
              onChange={onChange}
              placeholder={""}
              type="text"
              name="experience"
              label="Experience"
              customClass={styles.contactusInp}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              compolsory={true}
              onChange={onChange}
              placeholder={""}
              type="text"
              name="qualification"
              label="Qualification"
              customClass={styles.contactusInp}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className={styles.buttonSection} style={{display: 'flex', gap: '1rem', justifyContent: 'center'}}>
        <Box className={`button-primary ${styles.button1} m-0`} style={{width: '25%'}}>
          <Button className={"secondary-btn"} onClick={handleClose} style={{width: '100%'}}>
            Close
          </Button>
        </Box>

        <Box className={` button-primary ${styles.button} m-0`} style={{width: '25%'}}>
          <Button disabled={auth?.isLoading} onClick={(e) => appplyJob(e)} style={{width: '100%'}}>
            {auth?.isLoading ? (
              <p style={{ color: "white" }}>Loading...</p>
            ) : (
              " Confirm"
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
}
