import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';

import { Box, Button, Grid } from "@mui/material";

import TextInput from "../../common/TextInput";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from 'react-redux';
import { checkoutPayment, subscriptionPayment, updateSubcrib } from '../../../store/actions/pricingActions';
import { LOADING_FALSE, LOADING_TRUE, PAYMENT_START, UPDATE_SUBSCRIPTON_START } from '../../../store/constants/constants';
import { isMobile } from 'react-device-detect';

export default function CheckoutForm({
  updateValues,
  payment,
  radio,
  cart,
  licenseValue,
  sub,
  billing,
  closePaymentPopup,
}){
  const auth = useSelector((state) => state?.auth);
  
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const initialState = {
    name: "",
  };
  const [values, setValues] = useState(initialState);
  const [Customerror, setErrors] = useState({});
  const [paymentId, setPaymentId] = useState('');
  const cardElementOptions = {
    style: {
        base: {
            fontSize: '16px',
            color: '#32325d',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
        },
    },
};

const checkAlpha = (value) => {
  return /^[A-Za-z\s]*$/.test(value);
};


const validate = (data) => {
  const newError = {};
  for (let key in data) {
    let value = data[key];
    switch (key) {
      case "name":
        if (value.length === 0 || value.toString() === "")
          newError[key] = "Field is required";
        break;
      default:
        return;
    }
  }
  return newError;
};

const onChange = (e) => {
  const { name, value } = e?.target;

  if (name === "name") {
    if ((checkAlpha(value) && value?.trim().length > 0) || value === "") {
      setValues({
        ...values,
        [name]: value,
      });
    }
  }

  if (Customerror.hasOwnProperty(name)) {
    delete Customerror[name];
    setErrors(Customerror);
  }
};

const submitData = async (e) => {
  e.preventDefault();

  const validateSelect = validate(values);
  if (Object?.keys(validateSelect)?.length > 0) {
    setErrors(validateSelect);
    return;
  }

  if (!stripe || !elements) {
    return;
  }

  const card = elements.getElement(CardElement);

  if (card == null) {
    return;
  }
    dispatch({
      type: PAYMENT_START,
    });
  // Use your card Element with other Stripe.js APIs
  const {error, paymentMethod} = await stripe.createPaymentMethod({
    type: 'card',
    card,
    billing_details: {
      name: values?.name,
    },
  });

  if (error) {
    console.log('[error] submit data', error);
    return;
  } else {
    setPaymentId(paymentMethod.id);
  }

  const local_data = {
    ...billing,
    name: values?.name,
    plan_name: values?.name,
    coupon_code: payment?.couponCode?.coupon_code || "",
    gst: (10 / 100) * payment?.price,
    billing_address: billing?.address?.length > 1 ? true : false,
    workspace_id: auth?.results?.workspace_id,
    ...radio,
    subscription: radio,
    payment_id: paymentMethod.id,
    plan_data: {
      ...cart,
    },
    industry_certificate: {
      ...licenseValue,
    },
  };

  const local_data2 = {
    payment_id: paymentMethod.id,
    name: values?.name,
    workspace_id: auth?.results?.workspace_id,
    ...cart,
    coupon_code: values?.reff,
  };

  sub
    ? dispatch(subscriptionPayment(local_data2))
    : dispatch(checkoutPayment(local_data));
};


const updateSub = async (e) => {
  e.preventDefault();
  const validateSelect = validate(values);
  console.log("validateSelect", validateSelect);
  if (Object?.keys(validateSelect)?.length > 0) {
    setErrors(validateSelect);
    return;
  }

  if (!stripe || !elements) {
    return;
  }

  const card = elements.getElement(CardElement);

  if (card == null) {
    return;
  }
  dispatch({
    type: UPDATE_SUBSCRIPTON_START,
  });

  dispatch({
    type: LOADING_TRUE,
  });
  // Use your card Element with other Stripe.js APIs
  const {error, paymentMethod} = await stripe.createPaymentMethod({
    type: 'card',
    card,
    billing_details: {
      name: values?.name,
    },
  });
  
  if (error) {
    console.log('[error] update Data', error);
    dispatch({
      type: LOADING_FALSE,
    });
    return;
  } else {
    setPaymentId(paymentMethod.id);
  }

  const local_data = {
    name: values?.name,
    plan_name: values?.name,
    payment_id: paymentMethod.id,
    ...updateValues,
  };
  dispatch({
    type: LOADING_FALSE,
  });
  dispatch(updateSubcrib(local_data));
};

  return (

    <form onSubmit={submitData}>
    <Grid container style={{width: isMobile ? "180%" : "100%"}}>
      <Grid item md={12} xs={12} lg={12}>
        <Box className={styles.parentBox}>
          <TextInput
            compulsory={true}
            customClass={styles.input}
            label={"Name"}
            placeholder={"name"}
            name="name"
            value={values?.name}
            onChange={onChange}
          />
          {Customerror?.name && (
            <p className="error-class position-absolute">
              {Customerror?.name}
            </p>
          )}
        </Box>
      </Grid>
      <Grid item md={12} xs={12} lg={12}>
        <Box className={styles.parentBox} style={{
            marginTop: "2rem",
            background: "#FFFFFF",
            border: "1px solid #afafaf",
            borderRadius: "3px",
            width: "100%",
            position: "relative",
            minHeight: "41px",
            fontFamily: "poppins-regular",
            fontStyle: "normal",
            fontSize: "12px",
            lineHeight: "18px",
            padding: "10px 16px",
            letterSpacing: "0.025em",
          }}>
            <CardElement options={cardElementOptions}/>
        </Box>
      </Grid>
    </Grid>
    <Box className={`button-primary ${styles.button}`} style={{display: "flex", gap: "0.8rem"}}>
      <Button
        onClick={updateValues ? updateSub : submitData}
        disabled={payment?.isLoading}
      >
        {payment?.isLoading ? (
          <p style={{ color: "white" }}>Loading...</p>
        ) : (
          "Send"
        )}
      </Button>
      <Button
        onClick={closePaymentPopup}
        disabled={payment?.isLoading}
      >Cancel</Button>
      
    </Box>
  </form>
  );
};