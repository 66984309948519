import React, { useState } from "react";
// import '../../assets/css/Tooltip.css';

/****
 *  input component in pdf editing page.
 */

function ReceiverInput({ field }) {
  const [error, setError] = useState(false);
  const [isFilled, setFilled] = useState(true);
  const [isRequired, setisRequired] = useState(field.isRequired);

  const handleTextInput = (e) => {
    setFilled(false);

    if (e.target.value.length === 0) {
      setFilled(true);
    }

    let errorFlag = false;
    if (e.target.value.length > 0) {
      field.isFilled = true;
      field.textValue = e.target.value;
      errorFlag = false;
    } else {
      field.isFilled = false;
      field.textValue = e.target.value;
      errorFlag = true;
    }
    setError(errorFlag);
  };

  const changeRequiredStatus = (e) => {
    setisRequired(e.target.checked);
    field.isRequired = e.target.checked;
    field.isFilled = e.target.checked ? false : false;
  }

  return (
    <div
      style={{
        position: "absolute",
        left: field.positionFromParentLeft,
        top: field.positionFromParentTop,
        height: field.height,
        width: field.width,
        // border: "1px solid green",
        fontFamily: field.fontFamily,
      }}>
      {field.errorMessage && isFilled && (
        <span
          style={{
            position: "absolute",
            top: -40,
            background: "red",
            borderRadius: 5,
            margin: 4,
            padding: 5,
            color: "white",
            display: "flex",
            gap: "0.5rem"
          }}>
          <span>Required</span>
          <span className="form-check">
            <input type="checkbox" className="form-check-input" checked={isRequired} onChange={changeRequiredStatus} />
          </span>
          
        </span>
      )}
      <input
        onChange={handleTextInput}
        placeholder={field.placeholder}
        style={{
          width: "100%",
          height: "100%",
          fontSize: field.fontSize,
        }}
        type='text'
      />
    </div>
  );
}

export default ReceiverInput;
