import AWS from "aws-sdk";

const spacesEndpoint = new AWS.Endpoint(process.env.REACT_APP_SPACE_END_POINT);

const config = (dir) => {
  return {
    bucketName: "worecsprod",
    dirName: `https://worecsprod.sgp1.digitaloceanspaces.com/media/${dir}`,
    region: "sgp1",
    accessKeyId: "DO00M9XDN2PJME7YUFF8",
    secretAccessKey: "k4ilu+vuvqziyWL0xLW/iEPOJj/NISRdXvaSXZ9V/+w",
    s3Url: "https://worecsprod.sgp1.cdn.digitaloceanspaces.com",
  };
};
export default config;

const S3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: process.env.REACT_APP_SPACE_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SPACE_SECRETE_KEY,
  sslEnabled: false,  // Disable SSL for local testing
  s3ForcePathStyle: true,  // Use path style URLs
  signatureVersion: 'v4',
});

const testConfig = {
  digitalOceanSpaces: process.env.REACT_APP_SPACE_ADDRESS,
  bucketName: process.env.REACT_APP_SPACE_BUCKET_NAME,
};

export const uploadFile = async (file, location) => {
  const params = {
    Body: file,
    Bucket: `${testConfig.bucketName}`,
    Key: location,
  };

  await S3.putObject(params)
    .on("build", (request) => {
      request.httpRequest.headers.Host = `${testConfig.digitalOceanSpaces}`;
      request.httpRequest.headers["Content-Length"] = file.size;
      request.httpRequest.headers["Content-Type"] = file.type;
      // request.httpRequest.headers["x-amz-acl"] = "public-read";
    })
    .promise();
  return { location: `${testConfig.digitalOceanSpaces}${location}` };
};

export const uploadFormPDF = async (file, location, fileDetails) => {
  const params = {
    Body: file,
    Bucket: `${testConfig.bucketName}`,
    Key: location,
  };

  await S3.putObject(params)
    .on("build", (request) => {
      request.httpRequest.headers.Host = `${testConfig.digitalOceanSpaces}`;
      request.httpRequest.headers["Content-Length"] = fileDetails.size;
      request.httpRequest.headers["Content-Type"] = fileDetails.type;
      // request.httpRequest.headers["x-amz-acl"] = "public-read";
    })
    .promise();
  return { location: `${testConfig.digitalOceanSpaces}${location}` };
};

export const getFile = async (location) => {
  const params = {
    Bucket: `${testConfig.bucketName}`,
    Key: location?.split(".com/")?.[1],
  };
  const response = await S3.getObject(params)
    .on((request) => {
      request.httpRequest.headers.Host = `${testConfig.digitalOceanSpaces}`;
    })
    .promise();
  return JSON.parse(response?.Body?.toString("utf-8"));
};

export const downloadFile = (location) => {
  const params = {
    Bucket: `${testConfig.bucketName}`,

    Key: location?.includes(".com/") ? location?.split(".com/")?.[1] : location,
  };

  const url = S3.getSignedUrl("getObject", params);
  return url;
  // If you want to open the file in a new tab
};