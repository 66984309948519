import { Box, Grid, Typography } from "@mui/material";

import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import {
  resetPricing
} from "../../../store/actions/pricingActions";
import styles from "./styles.module.scss";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { isMobile } from 'react-device-detect';


const Checkout = ({
  cart,
  setCart,
  open,
  setOpen,
  sub,
  licenseValue,
  setLicenseValue,
  setLicense,
  radio,
  setRadio,
  billing,
  setBilling,
  updateValues,
  setUpdateValues,
}) => {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state?.pricing);
  const navigate = useNavigate();

  useEffect(() => {
    if (payment?.paymentDone?.success) {
      setOpen(false);
      navigate(routes?.DASHBOARD);
      dispatch(resetPricing());
      setCart({});
      setLicenseValue({});
      setLicense([]);
      setBilling({ address: "", city: "", state: "", country: "" });
      setRadio({});
    }
  }, [payment?.paymentDone]);

  useEffect(() => {
    if (payment?.updateSubscription?.success) {
      setOpen(false);
      navigate(routes?.DASHBOARD);
      dispatch(resetPricing());
      setUpdateValues({
        label: "",
        value: "",
        interval_count: "",
        product: "",
        price: "00",
      });
    }
  }, [payment?.updateSubscription]);

  const closePaymentPopup = () => {
     setOpen(false);
  }

  return (

<Box className={styles.gridParrent}>
          <Typography component="h2">Card Details</Typography>
          <Grid container sx={[{ alignItems: "center" }, isMobile ? {flexDirection: 'column'} : {}]}>
            <Grid item md={12} lg={12} style={{minWidth: isMobile ? 'fit-content' : '30rem'}}>
            <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)}>
                <CheckoutForm 
                  updateValues={updateValues}
                  payment={payment}
                  billing={billing}
                  radio={radio}
                  cart={cart}
                  licenseValue={licenseValue}
                  sub={sub}
                  closePaymentPopup={closePaymentPopup}
                />
              </Elements>
            </Grid>
          </Grid>
        </Box>

  );
};

export default Checkout;
